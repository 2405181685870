<template>
  <div>
    <div v-if="plan != null">
      <v-dialog
        persistent
        v-model="showPlanEditDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <plan-maintainer :plan="plan" @close-dialog="onClosePlanEditDialog"></plan-maintainer>
        </template>
      </v-dialog>

      <v-dialog
        persistent
        v-model="showServiceEditDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <service-plan-mantainer
            @close-dialog="onCloseServiceEditDialog"
            :service="selectedService"
            :plan="plan"
          ></service-plan-mantainer>
        </template>
      </v-dialog>

      <v-dialog
        persistent
        v-model="showAddServiceDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <service-plan-mantainer
            @close-dialog="onCloseAddServiceDialog"
            :service="selectedService"
            :plan="plan"
          ></service-plan-mantainer>
        </template>
      </v-dialog>

      <v-dialog
        persistent
        v-model="showAddBenefitDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <benefit-plan-mantainer 
            @close-dialog="onCloseAddBenefitDialog"
            :plan="plan"
          ></benefit-plan-mantainer>
        </template>
      </v-dialog>

      <v-dialog
        persistent
        v-model="showAddFeatureDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <feature-plan-mantainer 
            @close-dialog="onCloseAddFeatureDialog"
            :plan="plan"
          ></feature-plan-mantainer>
        </template>
      </v-dialog>

      <v-dialog
        persistent
        v-model="deleteServiceDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <v-card class="pa-6">
            <v-card-title class="text-uppercase">
              <div>Eliminar servicio {{ selectedService != null ? selectedService.service.title : '' }}</div>
            </v-card-title>
            <hr class="my-3" />
            <v-card-text class="my-0 py-0">
              <p>¿Está seguro de que desea eliminar el servicio?</p>
            </v-card-text>
            <hr class="my-3" />
            <v-card-actions class="justify-end">
              <v-btn color="primary" rounded @click="deleteService(plan.id, selectedService.service.id)">
                Sí, eliminar
              </v-btn>
              <v-btn text color="primary" rounded @click="closeDeleteServiceDialog">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-card class="pa-12">
        <v-row class="ma-0 pa-0">
          <div>
            <h1 class="text-h4 font-weight-bold">{{ plan.name }}</h1>
            <span
              v-if="plan.internalDescription != null && plan.internalDescription != ''"
              class="text-body-1"
              >{{ plan.internalDescription }}</span
            >
          </div>

          <v-spacer></v-spacer>
          <v-btn
            dark
            depressed
            color="primary"
            @click="openPlanEditDialog()"
          >
            Editar Plan
          </v-btn>
        </v-row>

        <v-divider class="my-4"></v-divider>

        <h2 class="text-h6 mb-3">
          Servicios
          <v-btn fab dark color="primary" @click="openAddServiceDialog" x-small>
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </h2>
        <v-card outlined>
          <v-data-table :headers="servicesTableHeaders" :items="sortedServices" hide-default-footer>
            <template v-slot:item.service="{ item }">
              <div>{{ item.service.title }}</div>
            </template>

            <template v-slot:item.maxAnnualEvents="{ item }">
              <div v-if="item.maxAnnualEvents != null">
                {{ item.maxAnnualEvents }}
                {{ item.maxAnnualEvents != 1 ? 'eventos' : 'evento' }}
              </div>
              <div v-else>
                Ilimitado
              </div>
            </template>

            <template v-slot:item.maxAmountPerEvent="{ item }">
              <div v-if="item.maxAmountPerEvent != null">
                <span v-if="item.maxAmountPerEventCurrency == 'CLP'">{{
                  item.maxAmountPerEvent | clpCurrency
                }}</span>
                <span v-else-if="item.maxAmountPerEventCurrency == 'UF'"
                  >{{
                    item.maxAmountPerEvent == parseInt(item.maxAmountPerEvent)
                      ? item.maxAmountPerEvent
                      : item.maxAmountPerEvent.toFixed(2)
                  }}
                  {{ item.maxAmountPerEventCurrency }}</span
                >
              </div>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.maxMonthlyAmount="{ item }">
              <div v-if="item.maxMonthlyAmount != null">
                <span v-if="item.maxMonthlyAmountCurrency == 'CLP'">{{
                  item.maxMonthlyAmount | clpCurrency
                }}</span>
                <span v-else-if="item.maxMonthlyAmountCurrency == 'UF'"
                  >{{
                    item.maxMonthlyAmount == parseInt(item.maxMonthlyAmount)
                      ? item.maxMonthlyAmount
                      : item.maxMonthlyAmount.toFixed(2)
                  }}
                  {{ item.maxMonthlyAmountCurrency }}</span
                >
              </div>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.maxAnnualAmount="{ item }">
              <div v-if="item.maxAnnualAmount != null">
                <span v-if="item.maxAnnualAmountCurrency == 'CLP'">
                  {{ item.maxAnnualAmount | clpCurrency }}</span>
                <span v-else-if="item.maxAnnualAmountCurrency == 'UF'">
                  {{ item.maxAnnualAmount == parseInt(item.maxAnnualAmount) ? item.maxAnnualAmount : item.maxAnnualAmount.toFixed(2) }} 
                  {{ item.maxAnnualAmountCurrency }}</span>
              </div>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.coveragePercentagePerEvent="{ item }">
              <div>{{ item.coveragePercentagePerEvent * 100 }}%</div>
            </template>

            <template v-slot:item.supplierGraceDays="{ item }">
              <div v-if="item.supplierGraceDays != null">
                {{ item.supplierGraceDays }}
                {{ item.supplierGraceDays != 1 ? 'días' : 'día' }}
              </div>
              <div v-else>
                --
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="d-flex align-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      depressed
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="blue"
                      @click="openServiceEditDialog(item)"
                    >
                      <v-icon small dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      depressed
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="pink"
                      @click="triggerDeleteServiceModal(item)"
                    >
                      <v-icon small dark>mdi-delete-forever</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card>

        <v-divider class="my-4"></v-divider>

        <h2 class="text-h6 mb-3">
          Beneficios
          <v-btn fab dark color="primary" @click="openAddBenefitDialog" x-small>
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </h2>

        <v-card outlined>
          <v-data-table :headers="benefitsTableHeaders" :items="sortedBenefits" hide-default-footer>
            <template v-slot:item.benefit="{ item }">
              <div>{{ item.value }}</div>
            </template>
          </v-data-table>
        </v-card>

        <v-divider class="my-4"></v-divider>

        <h2 class="text-h6 mb-3">
          Detalles
          <v-btn fab dark color="primary" @click="openAddFeatureDialog" x-small>
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </h2>

        <v-card outlined>
          <v-data-table :headers="featuresTableHeaders" :items=this.features hide-default-footer>
            <template v-slot:item.feature="{ item }">
              <div>{{  item.value }}</div>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </div>
  </div>
</template>

<script>
import PlanMaintainer from '../components/PlanMaintainer';
import ServicePlanMantainer from '../components/ServicePlanMantainer';
import PlansService from '../services/plans.service';
import BenefitPlanMantainer from '../components/BenefitPlanMantainer';
import FeaturePlanMantainer from '../components/FeaturePlanMantainer';

export default {
  components: {
    PlanMaintainer,
    ServicePlanMantainer,
    BenefitPlanMantainer,
    FeaturePlanMantainer
  },
  props: {
    planId: String
  },
  data() {
    return {
      plan: null,
      features: [],
      showPlanEditDialog: false,
      showServiceEditDialog: false,
      showAddServiceDialog: false,
      showAddBenefitDialog: false,
      showAddFeatureDialog: false,
      deleteServiceDialog: false,
      deleteBenefitDialog: false,
      selectedService: null,
      servicesTableHeaders: [
        { text: 'Orden', value: 'order', sortable: false },
        { text: 'Servicio', value: 'service', sortable: false },
        { text: 'Descripción corta', value: 'shortDescription', sortable: false },
        { text: 'Máx. eventos/año', value: 'maxAnnualEvents', sortable: false },
        { text: 'Límite por evento', value: 'maxAmountPerEvent', sortable: false },
        { text: 'Límite mensual', value: 'maxMonthlyAmount', sortable: false },
        { text: 'Límite anual', value: 'maxAnnualAmount', sortable: false },
        { text: '% Cobertura/evento', value: 'coveragePercentagePerEvent', sortable: false },
        { text: 'Carencia', value: 'supplierGraceDays', sortable: false },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      benefitsTableHeaders: [
        { text: 'Orden', value: 'order', sortable: false },
        { text: 'Beneficio', value: 'benefit', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ],
      featuresTableHeaders: [
        { text: 'Key', value: 'key', sortable: false},
        { text: 'Detalle', value: 'feature', sortable: false }
      ]
    };
  },
  computed: {
    sortedServices() {
      let services = [];
      if (this.plan && this.plan.servicePlanDetails) {
        services = this.plan.servicePlanDetails.slice();
        services.sort((a, b) => {
          return a.order - b.order;
        });
      }
      return services;
    },
    sortedBenefits() {
      let benefits = [];
      if (this.plan && this.plan.benefits) {
        benefits = this.plan.benefits.slice();
        benefits.sort((a, b) => {
          return a.order - b.order;
        });
      }
      return benefits;
    }
  },
  mounted() {
    if (this.planId) {
      this.getPlanDetails(this.planId);
      this.getFeaturesPlan(this.planId);
    }
  },

  methods: {
    async getPlanDetails(planId) {
      const res = await PlansService.getPlanDetails(planId);
      if (res.status === 'ok') {
        this.plan = res.data;
        console.log('plan:', this.plan);
      }
    },
    async deleteService(planId, serviceId) {
      try {
        const res = await PlansService.removeServiceFromPlan(planId, serviceId);
        if (res.status === 'ok') {
          this.getPlanDetails(this.planId);
          this.closeDeleteServiceDialog();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getFeaturesPlan(planId) {
      const res = await PlansService.getPlanFeatures(planId);
      if (res.status === 'ok') {
        this.features = res.data;
        console.log('features', this.features);
      }
    },
    triggerDeleteServiceModal(service) {
      this.deleteServiceDialog = true;
      this.selectedService = service;
    },
    closeDeleteServiceDialog() {
      this.deleteServiceDialog = false;
      this.selectedService = null;
      this.getPlanDetails(this.planId);
    },
    openPlanEditDialog() {
      this.showPlanEditDialog = true;
    },
    onClosePlanEditDialog() {
      this.showPlanEditDialog = false;
      this.getPlanDetails(this.planId);
    },
    openServiceEditDialog(service) {
      console.log(service);
      this.selectedService = service;
      this.showServiceEditDialog = true;
    },
    onCloseServiceEditDialog() {
      this.showServiceEditDialog = false;
      this.selectedService = null;
      this.getPlanDetails(this.planId);
    },
    openAddServiceDialog() {
      this.showAddServiceDialog = true;
    },
    onCloseAddServiceDialog() {
      this.showAddServiceDialog = false;
      this.getPlanDetails(this.planId);
    },
    openAddBenefitDialog() {
      this.showAddBenefitDialog = true;
      this.getPlanDetails(this.planId);
    },
    onCloseAddBenefitDialog() {
      this.showAddBenefitDialog = false;
      this.getPlanDetails(this.planId);
    },
    openAddFeatureDialog() {
      this.showAddFeatureDialog = true;
    },
    onCloseAddFeatureDialog() {
      this.showAddFeatureDialog = false;
      this.getPlanDetails(this.planId);
      this.getFeaturesPlan(this.planId);
    }
  }
};
</script>