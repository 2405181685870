import axios from 'axios';

//const adminUrl = process.env.VUE_APP_ADMIN_URL;
const backendURL = process.env.VUE_APP_BACKEND_URL_V2;
export default class CompaniesService {
    static async getCompanies(page, limit) {
        try{
            const accessToken = JSON.parse(localStorage.getItem('accessToken'));
            const response = await axios.get(`${backendURL}/companies/all/get?page=${page}&limit=${limit}`, {
                headers: { Authorization: `Bearer ${accessToken}` }
              });
              if (response.data.status === 'ok') {
                return { status: 'ok', data: response.data.data };
              } else {
                return { status: 'error' };
              }
        } catch (error) {
            return { status: 'error' };
        }
    }


}