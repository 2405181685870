<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Mascotas</h1>
      <v-dialog
        persistent
        v-model="showEditDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <pet-maintainer
            :pet="selectedPet"
            @update-list="this.getPets(this.page, this.limit, this.search)"
            @close-dialog="closeEditDialog"
          ></pet-maintainer>
        </template>
      </v-dialog>
      <v-dialog
        persistent
        @keydown.esc="showRecordsDialog = false"
        @click:outside="showEditDialog = false"
        v-model="showRecordsDialog"
        min-width="600"
        max-width="900"
        min-height="600"
        max-height="900"
      >
        <template>
          <records-list :pet="selectedPet" @close-dialog="closeRecordsDialog"></records-list>
        </template>
      </v-dialog>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="pets"
      :loading="loadingData"
      :options.sync="options"
      :items-per-page="limit"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:top>
        <v-row class="ma-0" style="justify-content: space-between; align-items: center">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="stringToSearch"
              label="Buscar"
              placeholder="Nombre, ID..."
              dense
              hide-details
              outlined
              append-icon="mdi-magnify"
              @click:append="searchString"
              @keyup="cleanSearch"
              @keyup.enter="searchString"
            ></v-text-field>
          </v-col>
          <v-btn class="ma-4" v-if="uniqueUser" color="primary" rounded @click="seeAllPets()"
            >Ver todas</v-btn
          >
        </v-row>
      </template>
      <template v-slot:item.species="{ item }">
        <v-chip
          v-if="item.species === 'cat'"
          small
          color="green"
          class="white--text font-weight-bold"
          ><v-icon left> mdi-cat </v-icon> Gato</v-chip
        >
        <v-chip
          v-if="item.species === 'dog'"
          small
          color="orange"
          class="white--text font-weight-bold"
          ><v-icon left> mdi-dog </v-icon> Perro</v-chip
        >
      </template>
      <template v-slot:item.sex="{ item }">
        <v-chip v-if="item.sex === 'female'" small outlined
          ><v-icon left> mdi-gender-female </v-icon> Hembra</v-chip
        >
        <v-chip v-if="item.sex === 'male'" small outlined
          ><v-icon left> mdi-gender-male </v-icon> Macho</v-chip
        >
      </template>
      <template v-slot:item.age="{ item }">
        {{ getAge(item.birthdate) }}
      </template>
      <template v-slot:item.breed.name="{ item }">
        <span v-if="item.breed === null">Mestizo</span>
        <span v-else>{{item.breed.name}}</span>        
      </template>
      <template v-slot:item.owner="{ item }">
        <span>{{ `${item.user.name} ${item.user.surname}` }} ({{ item.user.rut | rut }})</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              depressed
              x-small
              v-bind="attrs"
              v-on="on"
              color="blue"
              @click="openEditDialog(item)"
            >
              <v-icon small dark>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Revisar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              depressed
              x-small
              v-bind="attrs"
              v-on="on"
              color="orange"
              @click="openRecordsDialog(item)"
            >
              <v-icon small dark>mdi-format-list-text</v-icon>
            </v-btn>
          </template>
          <span>Registros</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PetMaintainer from '../components/PetMaintainer';
import RecordsList from '../components/RecordsList';
import PetsService from '../services/pets.service';
import DateUtils from '../utils/date.utils';

export default {
  components: {
    PetMaintainer,
    RecordsList
  },
  data() {
    return {
      showEditDialog: false,
      showRecordsDialog: false,
      selectedPet: null,
      loadingData: false,
      options: {},
      pets: [],
      limit: 10,
      page: 1,
      totalItems: 0,
      stringToSearch: null,
      userId: null,
      uniqueUser: false,
      headers: [
        {
          text: 'Nombre',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Especie',
          sortable: false,
          value: 'species'
        },
        {
          text: 'Sexo',
          sortable: false,
          value: 'sex'
        },
        {
          text: 'Edad',
          sortable: false,
          value: 'age'
        },
        {
          text: 'Raza',
          sortable: false,
          value: 'breed.name'
        },
        {
          text: 'Propietario',
          sortable: false,
          value: 'owner'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ]
    };
  },
  beforeMount() {
    if (this.$route.params.userId) {
      this.userId = this.$route.params.userId;
      this.uniqueUser = true;
    }
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.getPets(this.page, this.limit, this.search);
      },
      deep: true
    }
  },
  methods: {
    async getPets(page, limit) {
      this.loadingData = true;
      const res = await PetsService.getPets(
        page,
        limit,
        this.userId,
        this.stringToSearch && this.stringToSearch.length >= 3 ? this.stringToSearch : null
      );
      if (res.status === 'ok') {
        this.pets = res.data.pets;
        this.totalItems = res.data.totalItems;
      }
      this.loadingData = false;
    },
    openEditDialog(pet) {
      this.selectedPet = { ...pet };
      this.showEditDialog = true;
    },
    closeEditDialog() {
      this.getPets(this.page, this.limit, this.search);
      this.showEditDialog = false;
      this.selectedPet = null;
    },
    openRecordsDialog(pet) {
      this.selectedPet = { ...pet };
      this.selectedPet.membership = pet.memberships && pet.memberships.length > 0 ? pet.memberships[0] : null;
      this.showRecordsDialog = true;
    },
    closeRecordsDialog() {
      this.showRecordsDialog = false;
      this.selectedPet = null;
    },
    searchString() {
      if (
        (this.stringToSearch && this.stringToSearch.length >= 3) ||
        !this.stringToSearch ||
        this.stringToSearch.length == 0
      ) {
        this.getPets(this.page, this.limit, this.search);
      }
    },
    cleanSearch() {
      if (!this.stringToSearch || this.stringToSearch.length == 0) {
        this.getPets(this.page, this.limit, this.search);
      }
    },
    getAge(birthdate) {
      return DateUtils.getAge(birthdate);
    },
    seeAllPets() {
      this.$router.replace({ path: '/pets' });
      this.userId = null;
      this.uniqueUser = false;
      this.getPets(this.page, this.limit, this.search);
    }
  }
};
</script>
