var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.suppliersPlans,"options":_vm.options,"loading":_vm.loadingSuppliersPlans,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"disable-sort":true,"footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.graceDays",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.graceDays)+" día"+_vm._s(item.graceDays == 1 ? '' : 's'))])]}},{key:"item.price",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.price)+" "+_vm._s(item.currency || ''))])]}},{key:"item.available",fn:function(ref){
    var item = ref.item;
return [(item.available)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }