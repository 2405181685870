<template>
  <div>
    <overlay :value="loading"></overlay>
    <v-card class="pa-6">
      <v-card-title class="text-uppercase">
        <v-row class="my-1 justify-space-between align-center">
          <div>Agregar beneficio</div>
        </v-row>
      </v-card-title>

      <hr class="my-3" />

      <v-card-text class="my-0 py-0">
        <v-form ref="newBenefitForm" v-model="formValid">
          <v-container class="ma-0 pa-0">
            <v-row class="ma-0">
              <v-col cols="6">
                <v-select
                  label="Seleccione beneficio"
                  v-model="newBenefit.benefitId"
                  :items="benefits"
                  item-text="value"
                  item-value="id"
                  required
                  outlined
                  hide-details="auto"
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <hr class="my-3" />

      <v-card-actions class="justify-end">
        <v-btn 
          color="primary" 
          rounded 
          @click="validate"
        >Guardar
        </v-btn>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="showSnackbar" :timeout="5000">
      {{ snackbarContent }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="showSnackbar = false"> Entendido </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import PlansService from '../services/plans.service';
import ServicesService from '../services/services.service';
import Overlay from './Overlay.vue';

export default {
  components: { Overlay },
  name: 'BenefitPlanMaintainer',
  props: ['plan'],
  data() {
    return {
      loading: false,
      showSnackbar: false,
      snackbarContent: 'Ocurrió un error guardando el beneficio, intenta más tarde',
      formValid: false,
      newBenefit: {},
      benefits: [],
      benefitId: null
    };
  },
  mounted() {
    this.fetchBenefits();
    this.$refs.newBenefitForm.resetValidation();
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    async fetchBenefits() {
      this.loading = true;
      try {
        const response = await ServicesService.getAllBenefits();
        if (response.status === 'ok') {
          this.benefits = response.data;
        }
      } catch (error) {
        this.loading = false;
        this.noDataMsg = 'Algo salió mal, intentalo más tarde';
        console.log(error);
      }
      this.loading = false;
    },
    validate() {
      if (this.$refs.newBenefitForm.validate()) {
        this.createBenefitPlan();
      }
    },
    async createBenefitPlan() {
      this.newBenefit.planId = this.plan.id;
      const res = await PlansService.addBenefitToPlan(this.newBenefit);
      if (res.status === 'ok') {
        this.$refs.newBenefitForm.resetValidation();
        this.closeDialog();
      }
    }
  }
};
</script>