<template>
  <v-card class="pa-6" v-if="content.plan">
    <v-card-title class="text-uppercase">
      <v-row class="my-1 justify-space-between align-center">
        <div>{{ content.plan.name || '' }}</div>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </v-row>
    </v-card-title>

    <hr class="my-3" />

    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>{{ content.plan.type.toUpperCase() || '' }}</v-list-item-subtitle>
        <v-list-item-subtitle>{{
          content.paymentInterval === 1 ? 'Anual' : 'Mensual'
        }}</v-list-item-subtitle>

        <v-list-item-subtitle>
          {{ content.paymentInterval === 1 ? content.plan.annualPrice : content.plan.monthlyPrice }}
          {{ content.plan.currency || '' }} (sin descuento)
        </v-list-item-subtitle>

        <v-list-item-subtitle v-if="!content.plan.allowJunior && !content.plan.allowSenior">
          Solo mascotas entre 6 meses y 10 años
        </v-list-item-subtitle>

        <v-list-item-subtitle v-else>
          {{
            content.plan.allowJunior ? 'Admite menores de 6 meses' : 'No admite menores de 6 meses'
          }}
          y
          {{
            content.plan.allowSenior ? 'admite mayores de 10 años' : 'no admite mayores de 10 años'
          }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item two-line>
      <v-list-item-content v-if="servicePlanDetails">
        <v-data-table
          :headers="headers"
          :items="servicePlanDetails"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:item.service="{ item }">
            <span v-if="item" style="color: black;">{{ item.service.title || '' }}</span>
          </template>

          <template v-slot:item.coveragePercentagePerEvent="{ item }">
            <span v-if="item" style="color: black;">
              <td>{{ item.coveragePercentagePerEvent * 100 }}%</td>
            </span>
          </template>

          <template v-slot:item.maxAmountPerEvent="{ item }">
            <span v-if="item" style="color: black;">
              <td v-if="item.maxAmountPerEvent">{{ item.maxAmountPerEvent }} {{ item.maxAmountPerEventCurrency || '' }}</td>
            </span>
          </template>

          <template v-slot:item.maxMonthlyAmount="{ item }">
            <span v-if="item" style="color: black;">
              <td v-if ="item.maxMonthlyAmount">{{ item.maxMonthlyAmount || '' }} {{ item.maxMonthlyAmountCurrency || '' }}</td>
            </span>
          </template>

          <template v-slot:item.maxAnnualAmount="{ item }">
            <span v-if="item" style="color: black;">
              <td v-if="item.maxAnnualAmount">{{ item.maxAnnualAmount || '' }} {{ item.maxAnnualAmountCurrency || '' }}</td>
            </span>
          </template>
        </v-data-table>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="features && features.some(feature => feature.key === 'details')">
      <v-list-item-content>
        <v-list-item-title class="pb-2">
          Extras:
        </v-list-item-title>
        <span v-for="(feature, index) in features" :key="index">
          <v-list-item-subtitle
            v-if="feature.key === 'details'"
            v-html="boldMessage(feature.value)"
          ></v-list-item-subtitle>
        </span>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: 'PlanDetailsMaintainer',
  props: ['content'],

  data() {
    return {
      headers: [
        {
          text: 'Servicio',
          value: 'service',
          sortable: false
        },
        {
          text: 'Cobertura',
          value: 'coveragePercentagePerEvent',
          sortable: false
        },
        {
          text: 'Monto/evento',
          value: 'maxAmountPerEvent',
          sortable: false
        },
        {
          text: 'Monto/mes',
          value: 'maxMonthlyAmount',
          sortable: false
        },
        {
          text: 'Eventos/año',
          value: 'maxAnnualEvents',
          sortable: false
        },
        {
          text: 'Monto/año',
          value: 'maxMonthlyAmount',
          sortable: false
        }
      ],
      servicePlanDetails: [],
      features: []
    };
  },
  mounted() {
    console.log(this.content.plan);
    this.servicePlanDetails = this.content.plan.servicePlanDetails;
    this.features = this.content.plan.features?.filter(f => f.key === 'details');
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    percentageVal(val) {
      if (val) {
        return `${val * 100}%`;
      }
      return val;
    },
    boldMessage(msg) {
      if (msg) {
        return msg.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      }
    }
  }
};
</script>
