var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0 my-4 align-center justify-space-between"},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Cupones")]),_c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"1200","min-height":"600","max-height":"900"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-md-block",attrs:{"rounded":"","color":"primary","depressed":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Agregar Cupón")])])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[[_c('coupon-maintainer',{attrs:{"coupon":_vm.selectedCoupon,"countries":_vm.countries},on:{"update-list":function($event){return _vm.getCoupons(this.page, this.limit)},"close-dialog":_vm.closeDialog}})]],2)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.coupons,"options":_vm.options,"loading":_vm.loadingData,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"px-5 py-5",staticStyle:{"justify-content":"space-between","align-items":"center"}},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","disabled":_vm.loadingData,"placeholder":"Nombre","dense":"","hide-details":"","outlined":"","append-icon":"mdi-magnify"},on:{"click:append":_vm.searchString,"keyup":[_vm.cleanSearch,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchString.apply(null, arguments)}]},model:{value:(_vm.stringToSearch),callback:function ($$v) {_vm.stringToSearch=$$v},expression:"stringToSearch"}})],1),_c('v-col',{staticClass:"my-0 py-0 ",attrs:{"cols":"6","md":"6"}},[_c('v-container',{staticClass:"ma-0 pa-0 mt-3 mt-md-0 d-flex flex-column align-center justify-end"},[_c('span',{staticClass:"align-self-end text-caption"},[_vm._v("Info cupones (todos)")]),_c('v-btn',{staticClass:"mb-3 align-self-end",attrs:{"color":"primary","depressed":""},on:{"click":_vm.downloadCsv}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-file-download-outline ")]),_c('span',[_vm._v("CSV")])],1)],1)],1),(_vm.errorCsv)?_c('v-col',{staticClass:"mr-3 ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end"},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v("Ocurrió un error")])],1):_vm._e()],1)]},proxy:true},{key:"item.monthlyFlowId",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getMonthlyDiscount(item)))])]}},{key:"item.annualFlowId",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getAnnualDiscount(item)))])]}},{key:"item.paws",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getPaws(item)))])]}},{key:"item.maxRedemptions",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getUses(item)))])]}},{key:"item.redemptions",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(item.redemptions.length))])])]}},{key:"item.period",fn:function(ref){
    var item = ref.item;
return [(item.startDate != null && item.endDate != null)?_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(item.startDate))+" - "+_vm._s(_vm._f("date")(item.endDate))+" ")]):_vm._e(),(item.startDate != null && item.endDate == null)?_c('span',[_vm._v(" Desde "+_vm._s(_vm._f("date")(item.startDate))+" ")]):_vm._e(),(item.startDate == null && item.endDate != null)?_c('span',[_vm._v(" Hasta "+_vm._s(_vm._f("date")(item.endDate))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [(item.status == 1)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","depressed":"","x-small":"","color":"blue"},on:{"click":function($event){return _vm.openDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Revisar")])])]}}])}),_c('overlay',{attrs:{"value":_vm.loadingOverlay}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }