var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content.plan)?_c('v-card',{staticClass:"pa-6"},[_c('v-card-title',{staticClass:"text-uppercase"},[_c('v-row',{staticClass:"my-1 justify-space-between align-center"},[_c('div',[_vm._v(_vm._s(_vm.content.plan.name || ''))]),_c('v-btn',{attrs:{"text":"","color":"primary","rounded":""},on:{"click":_vm.closeDialog}},[_vm._v("Cerrar")])],1)],1),_c('hr',{staticClass:"my-3"}),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.content.plan.type.toUpperCase() || ''))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.content.paymentInterval === 1 ? 'Anual' : 'Mensual'))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.content.paymentInterval === 1 ? _vm.content.plan.annualPrice : _vm.content.plan.monthlyPrice)+" "+_vm._s(_vm.content.plan.currency || '')+" (sin descuento) ")]),(!_vm.content.plan.allowJunior && !_vm.content.plan.allowSenior)?_c('v-list-item-subtitle',[_vm._v(" Solo mascotas entre 6 meses y 10 años ")]):_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.content.plan.allowJunior ? 'Admite menores de 6 meses' : 'No admite menores de 6 meses')+" y "+_vm._s(_vm.content.plan.allowSenior ? 'admite mayores de 10 años' : 'no admite mayores de 10 años')+" ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[(_vm.servicePlanDetails)?_c('v-list-item-content',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.servicePlanDetails,"items-per-page":10},scopedSlots:_vm._u([{key:"item.service",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(item.service.title || ''))]):_vm._e()]}},{key:"item.coveragePercentagePerEvent",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticStyle:{"color":"black"}},[_c('td',[_vm._v(_vm._s(item.coveragePercentagePerEvent * 100)+"%")])]):_vm._e()]}},{key:"item.maxAmountPerEvent",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticStyle:{"color":"black"}},[(item.maxAmountPerEvent)?_c('td',[_vm._v(_vm._s(item.maxAmountPerEvent)+" "+_vm._s(item.maxAmountPerEventCurrency || ''))]):_vm._e()]):_vm._e()]}},{key:"item.maxMonthlyAmount",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticStyle:{"color":"black"}},[(item.maxMonthlyAmount)?_c('td',[_vm._v(_vm._s(item.maxMonthlyAmount || '')+" "+_vm._s(item.maxMonthlyAmountCurrency || ''))]):_vm._e()]):_vm._e()]}},{key:"item.maxAnnualAmount",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticStyle:{"color":"black"}},[(item.maxAnnualAmount)?_c('td',[_vm._v(_vm._s(item.maxAnnualAmount || '')+" "+_vm._s(item.maxAnnualAmountCurrency || ''))]):_vm._e()]):_vm._e()]}}],null,false,2905489289)})],1):_vm._e()],1),(_vm.features && _vm.features.some(function (feature) { return feature.key === 'details'; }))?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"pb-2"},[_vm._v(" Extras: ")]),_vm._l((_vm.features),function(feature,index){return _c('span',{key:index},[(feature.key === 'details')?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.boldMessage(feature.value))}}):_vm._e()],1)})],2)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }