var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0 my-4 align-center justify-space-between"},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Solicitudes de Reembolso")]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}},[_c('v-container',{staticClass:"ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end"},[_c('v-btn',{attrs:{"color":"green","depressed":""},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")]),_c('span',{staticClass:"white--text font-weight-semibold"},[_vm._v("Actualizar")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.refundsRequests,"loading":_vm.loadingData,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"disable-sort":_vm.loadingData,"options":_vm.options,"multi-sort":"","footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50, 500, 999]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user ? item.user.name : '')+" "+_vm._s(item.user ? item.user.surname : ''))])]}},{key:"item.pet",fn:function(ref){
    var item = ref.item;
return [_c('v-col',{staticClass:"ma-0 pa-0 mt-1"},[_c('div',[_c('span',[_vm._v(_vm._s(item.pet ? item.pet.name : '-')+" ")])])])]}},{key:"item.membership",fn:function(ref){
    var item = ref.item;
return [_c('v-col',{staticClass:"ma-0 pa-0 mt-1",staticStyle:{"max-width":"200px"}},[(item.petId !=null)?_c('span',[_vm._v(_vm._s(item.pet.membership.ref ? item.pet.membership.ref : '-')+" ")]):_c('span',[_vm._v("-")])])]}},{key:"item.service",fn:function(ref){
    var item = ref.item;
return [_c('v-col',{staticClass:"ma-0 pa-0 mt-1",staticStyle:{"max-width":"200px"}},[_c('span',[_vm._v(_vm._s(item.service ? item.service.title : '-')+" ")])])]}},{key:"item.updated",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('span',{staticClass:"black--text font-weight-semibold"},[_vm._v(" "+_vm._s(new Date(item.updatedAt).toLocaleDateString())+" ")])])]}},{key:"item.attachments",fn:function(ref){
    var item = ref.item;
return [(item.attachments == null || item.attachments.length > 0)?[_c('div',{staticClass:"d-flex flex-wrap justify-center"},_vm._l((item.attachments),function(attachment){return _c('v-tooltip',{key:attachment.id,attrs:{"top":""},scopedSlots:_vm._u([(attachment.fileUrl != null)?{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(attachment.fileUrl != null)?_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"depressed":"","x-small":"","fab":"","color":"#81C784","href":attachment.fileUrl,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-eye-outline")])],1)],1):_c('div',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-file-document-alert-outline")])],1)]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(attachment.attachmentType ? attachment.attachmentType.name : ''))])])}),1)]:[_c('span',{staticClass:"red--text font-weight-semibold"},[_vm._v("Sin adjuntos")])]]}},{key:"item.changelogs",fn:function(ref){
    var item = ref.item;
return [(item.changelogs !== null || item.changelogs.length > 0)?_c('div',[_c('v-chip',{attrs:{"small":"","outlined":"","color":item.changelogs[0].status.highlightColor}},[_vm._v(_vm._s(item.changelogs[0].status.name))])],1):_c('div',[_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"x-small":"","outlined":"","color":"red"}},[_vm._v("Sin información")])],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(item.changelogs !== null || item.changelogs.length > 0)?_c('div',[_c('refund-mainteiner',{attrs:{"data":item,"refreshParent":_vm.refresh}})],1):_c('div',[_vm._v(" Sin data ")])]}}])}),_c('overlay',{attrs:{"value":_vm.loadingOverlay}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }