import axios from 'axios';

const adminUrl = process.env.VUE_APP_ADMIN_URL;

export default class MRRService {
  static async getMRRDataService(iso, limit, page) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrl}/mrr/full-b2c-mrr?iso=${iso}&page=${page}&limit=${limit}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        console.log(response.data);
        return { status: 'ok', data: response.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getMRRResumeByCountry(iso) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrl}/mrr/mrr-info-country?iso=${iso}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }
}
