import axios from 'axios';

const backendUrl = process.env.VUE_APP_ADMIN_URL;

export default class CodesService {
  static async getAllCodes() {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${backendUrl}/codes?limit=999999`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data.codes };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }
}
