<template>
  <v-container style="background-color: #104B94;" class="fill-height" fluid>
    <overlay :value="loading"></overlay>
    <v-container class="d-flex flex-column justify-center align-center fill-height">
      <v-card class="ma-0 ma-md-6 pa-8" width="100%" max-width="400px">
        <v-img class="mb-4" src="@/assets/new_Pawer_Logo.png" height="50" contain></v-img>
        <v-card-text class="ma-0 pa-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Correo electrónico"
                required
                extends
                @keypress.enter="validate"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Contraseña"
                required
                type="password"
                @keypress.enter="validate"
              ></v-text-field>

              <v-alert v-if="error" dense type="error">Datos erróneos</v-alert>

              <div class="d-flex justify-center">
                <v-btn color="primary" class="mt-4" rounded @click="validate">Entrar</v-btn>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import Overlay from '../components/Overlay.vue';
import AuthService from '../services/auth.service';

export default {
  components: { Overlay },
  data: () => ({
    valid: true,
    loading: false,
    error: false,
    password: '',
    passwordRules: [v => !!v || 'La contraseña es requerida'],
    email: '',
    emailRules: [
      v => !!v || 'El correo electrónico es requerido',
      v => /.+@.+\..+/.test(v) || 'El correo debe ser valido'
    ]
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.login();
      }
    },
    async login() {
      this.loading = true;
      this.error = false;
      const response = await AuthService.login(this.email, this.password);
      if (response.status === 'ok') {
        this.$router.push({ path: '/users' });
      } else {
        this.error = true;
      }
      this.loading = false;
    }
  }
};
</script>
