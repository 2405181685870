import axios from 'axios';

const adminUrl = process.env.VUE_APP_ADMIN_URL;
const backendUrlv3 = process.env.VUE_APP_BACKEND_URL_V3;

export default class CouponsService {
  static async getActiveCoupons(iso) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${backendUrlv3}/code?iso=${iso}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      return response.data;
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getCoupons(page, limit, search) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));

      let params = {
        page,
        limit,
        types: 'promotional'
      };
      if (search != null && search.length != 0) {
        params = { ...params, search };
      }
      const response = await axios.get(`${adminUrl}/codes`, {
        params,
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async createCoupon(newCoupon) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.post(`${adminUrl}/codes`, newCoupon, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updateCoupon(couponId, newCoupon) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.put(`${adminUrl}/codes/${couponId}`, newCoupon, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getExportedCsv(page, limit) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrl}/codes/export/redemptions?page=${page}&limit=${limit}&types=promotional`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
        responseType: 'blob'
      });
      console.log(response);

      if (response.status == 200) {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getActiveCodes(iso) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${backendUrlv3}/codes`, {
        params: {
          iso: iso
        },
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }
}
