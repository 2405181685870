import moment from 'moment';

export default class DateUtils {
  static format(date) {
    try {
      return moment.utc(date).format('DD/MM/YYYY');
    } catch (error) {
      return '';
    }
  }

  static getAge(birthdate) {
    const today = new Date();
    const parsedDate = new Date(birthdate);
    let age = today.getFullYear() - parsedDate.getFullYear();
    const months = today.getMonth() - parsedDate.getMonth();
    age = age * 12 + months;
    if (age > 11) {
      const years = Math.floor(age / 12);
      return `${years} año${years === 1 ? '' : 's'}`;
    } else {
      return `${age} mes${age === 1 ? '' : 'es'}`;
    }
  }

  static getRemainingTrialDays(membership) {
    if (membership.finishTrialDate == null) {
      return null;
    }
    const finishTrial = new Date(membership.finishTrialDate);
    const now = new Date();
    const remainingDays = Math.round((finishTrial - now) / (1000 * 60 * 60 * 24)) + 1;

    if (remainingDays >= 0) {
      return remainingDays;
    } else {
      return 0;
    }
  }

  static getRemainingTrialDaysString(membership) {
    const remainingTrialDays = this.getRemainingTrialDays(membership);
    if (remainingTrialDays != null) {
      return `${remainingTrialDays} día${remainingTrialDays == 1 ? '' : 's'}`;
    }
    return '';
  }
}
