var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"900","min-height":"600","max-height":"900"},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},[_c('membership-maintainer',{attrs:{"membership":_vm.selectedMembership},on:{"close-dialog":_vm.closeEditDialog}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.memberships,"options":_vm.options,"loading":_vm.loadingMemberships,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"sort-desc":_vm.sort,"disable-sort":_vm.loadingMemberships,"multi-sort":"","footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50]
    }},on:{"update:options":function($event){_vm.options=$event},"update:sortDesc":function($event){_vm.sort=$event},"update:sort-desc":function($event){_vm.sort=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"px-5 py-5",staticStyle:{"justify-content":"space-between","align-items":"center"}},[_c('v-col',{staticClass:"my-0 py-2",attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"label":"Proveedor","loading":_vm.loadingSuppliers,"items":_vm.suppliers,"disabled":_vm.loadingSuppliers || _vm.loadingMemberships,"item-text":"name","required":"","outlined":"","dense":"","return-object":"","hide-details":"auto"},model:{value:(_vm.selectedSupplier),callback:function ($$v) {_vm.selectedSupplier=$$v},expression:"selectedSupplier"}})],1),_c('v-col',{staticClass:"my-0 py-2",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","disabled":_vm.loadingSuppliers || _vm.loadingMemberships,"placeholder":"Referencia, mascota, plan...","dense":"","hide-details":"","outlined":"","append-icon":"mdi-magnify"},on:{"click:append":_vm.searchString,"keyup":[_vm.cleanSearch,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchString.apply(null, arguments)}]},model:{value:(_vm.stringToSearch),callback:function ($$v) {_vm.stringToSearch=$$v},expression:"stringToSearch"}})],1)],1),(_vm.selectedSupplier)?_c('v-row',{staticClass:"mt-2 mb-1 mx-5",staticStyle:{"justify-content":"space-between","align-items":"center"}},[_c('v-subheader',{staticClass:"px-0",staticStyle:{"flex-wrap":"wrap"}},[(_vm.selectedSupplier.phoneNumber)?_c('span',[_vm._v("Número de asistencia "+_vm._s(_vm.selectedSupplier.name)+" ")]):_vm._e(),(_vm.selectedSupplier.phoneNumber)?_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.selectedSupplier.phoneNumber))]):_vm._e()]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","depressed":"","loading":_vm.downloadingExcel},on:{"click":function($event){return _vm.downloadExcel(false)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-google-spreadsheet ")]),_c('span',[_vm._v("Excel homologaciones")])],1)]}}],null,false,1924627711)},[_c('span',[_vm._v("Membresías no homologadas")])])],1):_vm._e(),(_vm.selectedSupplier)?_c('v-row',{staticClass:"mt-1 mb-2 mx-2",staticStyle:{"justify-content":"right","align-items":"center"}},[_c('v-col',{attrs:{"cols":"0","sm":"2"}}),_c('v-col',{attrs:{"cols":"6","sm":"5","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Excel Mensual","prepend-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,1372684274),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","type":"month"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{staticClass:"mx-0",attrs:{"cols":"6","sm":"5","md":"3","lg":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","depressed":"","block":"","loading":_vm.downloadingMonthlyExcel},on:{"click":function($event){return _vm.downloadExcel(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-google-spreadsheet ")]),_c('span',[_vm._v("Excel mensual")])],1)]}}],null,false,3812810238)},[_c('span',[_vm._v("Pagos mensuales")])])],1)],1):_vm._e(),(_vm.selectedSupplier)?_c('v-row',{staticClass:"mt-1 mb-2 mx-2",staticStyle:{"justify-content":"right","align-items":"center"}},[_c('v-col',{attrs:{"cols":"0","sm":"2"}}),_c('v-col',{attrs:{"cols":"6","sm":"5","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Excel desde","prepend-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.computedSelectedDateFormatted),callback:function ($$v) {_vm.computedSelectedDateFormatted=$$v},expression:"computedSelectedDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,2993164037),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"show-current":true,"no-title":"","type":"date","max":_vm.computedFullDate},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1),_c('v-col',{staticClass:"mx-0",attrs:{"cols":"6","sm":"5","md":"3","lg":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","depressed":"","block":"","disabled":_vm.selectedSupplier.name != 'Southbridge',"loading":_vm.downloadingWeeklyExcel},on:{"click":_vm.downloadWeeklyExcel}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-google-spreadsheet ")]),_c('span',[_vm._v("Excel Custom")])],1)]}}],null,false,77204529)},[_c('span',[_vm._v("Altas y bajas desde "+_vm._s(_vm.selectedDateFormated)+" a hoy")])])],1)],1):_vm._e()]},proxy:true},{key:"item.user",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user.name || '')+" "+_vm._s(item.user.surname || ''))])]}},{key:"item.plan.name",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.plan.name))])]}},{key:"item.finishSupplierGraceDays",fn:function(ref){
    var item = ref.item;
return [(item.finishSupplierGraceDays)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.finishSupplierGraceDays)))]):_c('span',[_vm._v("—")])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('membership-status-chip',{attrs:{"status":item.status}})]}},{key:"item.homologated",fn:function(ref){
    var item = ref.item;
return [_c('v-chip-group',[(item.homologated === true)?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","outlined":"","color":"green"}},[_vm._v("Homologada")]):_vm._e(),(item.homologated === false)?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","outlined":"","color":"red"}},[_vm._v("No homologada")]):_vm._e()],1)]}},{key:"item.homologatedAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateText(item.homologatedAt)))])]}},{key:"item.finishTrialDate",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getRemainingTrialDaysString(item)))])]}},{key:"item.actions",fn:function(props){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"depressed":"","x-small":"","fab":"","color":"green"},on:{"click":function($event){return _vm.editMembership(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Revisar")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }