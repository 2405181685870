import axios from 'axios';

const URL_V2 = process.env.VUE_APP_BACKEND_URL_V2;
const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;

export default class RefundsServices {
  static async getAllRefunds(page, limit, query, sort, userId, search, serviceId, petId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${URL_V2}/refunds/all/refundrequests?page=${page}&limit=${limit}`;
      if (userId != null && userId.length != 0) {
        url = url + `&userId=${userId}`;
      }
      if (query != null && query.length != 0) {
        url = url + `&status=${query}`;
      }
      if (sort != null && sort.length != 0) {
        url = url + `&sort=${sort.join(',')}`;
      }
      if (search != null && search.length != 0) {
        url = url + `&search=${search}`;
      }
      if (serviceId != null && serviceId.length != 0) {
        url = url + `&serviceId=${serviceId}`;
      }
      if (petId != null && petId.length != 0) {
        url = url + `&serviceId=${petId}`;
      }
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        const refunds = response.data.data;
        return { status: 'ok', data: refunds };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getChangelogStatuses() {
    try {
      let url = `${URL_V2}/refunds/changelog/statuses`;
      const response = await axios.get(url);
      if (response.data.status === 'ok') {
        const statuses = response.data.data;
        return { status: 'ok', data: statuses };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updateChangelogStatus(data) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    try {
      let url = `${URL_V2}/refunds/create/changelog`;
      const response = await axios.post(url, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.status == 200) {
        return { status: 'ok', data: response.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async createRefund(data){
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    try {
      let url = `${ADMIN_URL}/refunds/create-refund`;
      const response = await axios.post(url, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.status == 200) {
        return { status: 'ok', data: response.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

}
