import axios from 'axios';

const backendUrl = process.env.VUE_APP_BACKEND_URL;

export default class AuthService {

  static async login(email, password) {
    try {
      const response = await axios.post(`${backendUrl}/users/login`, { email, password });
      if (response.data.status === 'ok' && response.data.data.role === 'admin') {
        localStorage.setItem('accessToken', JSON.stringify(response.data.data.accessToken));
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async logout() {
    try {
      localStorage.removeItem('accessToken');
      return { status: 'ok' };
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getUserData() {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      if (accessToken) {
        const response = await axios.get(`${backendUrl}/users/me`, { headers: { 'Authorization': `Bearer ${accessToken}` } });
        if (response.data.status === 'ok' && response.data.data.role === 'admin') {
          return { status: 'ok', data: response.data.data };
        } else {
          return { status: 'error' };
        }
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

}
