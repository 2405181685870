var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-6"},[_c('v-card-title',{staticClass:"text-uppercase"},[_c('div',[_vm._v("Mascotas de "+_vm._s(_vm.user != null ? ((_vm.user.name) + " " + (_vm.user.surname)) : ''))])]),_c('hr',{staticClass:"my-3"}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.pets,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50]
    }},scopedSlots:_vm._u([{key:"item.species",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.species == 'cat' ? 'Gato' : 'Perro')+" ")]}},{key:"item.age",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAge(item.birthdate))+" ")]}},{key:"item.sex",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.sex == 'female' ? 'Hembra' : 'Macho')+" ")]}},{key:"item.visible",fn:function(ref){
    var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.visible),callback:function ($$v) {_vm.$set(item, "visible", $$v)},expression:"item.visible"}})]}}])}),_c('hr',{staticClass:"my-3"}),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.updatePets}},[_vm._v(" Guardar ")]),_c('v-btn',{attrs:{"text":"","color":"primary","rounded":""},on:{"click":_vm.closeDialog}},[_vm._v("Cerrar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }