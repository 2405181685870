<template>
  <v-chip-group>
    <v-chip v-if="status === 0" x-small color="orange" class="white--text font-weight-bold"
      >Pendiente de pago</v-chip
    >
    <v-chip v-if="status === 1" color="blue" x-small class="white--text font-weight-bold"
      >Pendiente de activación</v-chip
    >
    <v-chip v-if="status === 2" color="green" x-small class="white--text font-weight-bold"
      >Activo</v-chip
    >
    <v-chip v-if="status === 3" color="red" x-small class="white--text font-weight-bold"
      >Pago fallido</v-chip
    >
    <v-chip v-if="status === 4" color="grey" outlined x-small class="font-weight-bold"
      >Desactivado</v-chip
    >
    <v-chip
      v-if="status === 5"
      color="primary"
      outlined
      x-small
      class="white--text font-weight-bold"
      >Pendiente de enrolamiento</v-chip
    >
  </v-chip-group>
</template>

<script>
export default {
  name: 'MembershipStatusChip',
  props: ['status']
};
</script>
