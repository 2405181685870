import axios from 'axios';

//const backendUrl = process.env.VUE_APP_BACKEND_URL;
const backendUrlv2 = process.env.VUE_APP_BACKEND_URL_V2;
const backendUrlA = process.env.VUE_APP_ADMIN_URL;

export default class ServicesService {
  static async getAllServices() {
    const response = await axios.get(`${backendUrlA}/plan-services/services?active=true`, {});

    if (response.data.status === 'ok') {
      return { status: 'ok', data: response.data.data };
    } else {
      throw new Error(response.data.message);
    }
  }
  
  // /isolatedservices/all/available
  static async getAllIsolatedServices() {
    const response = await axios.get(`${backendUrlv2}/isolatedservices/all/available`, {});

    if (response.data.status === 'ok') {
      return { status: 'ok', data: response.data.data };
    } else {
      throw new Error(response.data.message);
    }
  }

  static async getAllBenefits() {
    const response = await axios.get(`${backendUrlA}/plan-services/benefits`, {});

    if (response.data.status === 'ok') {
      return { status: 'ok', data: response.data.data };
    } else {
      throw new Error(response.data.message);
    }
  }

}
