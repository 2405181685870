<template>
  <v-card class="pa-6">
    <v-card-title class="text-uppercase">
      <div>Agregar usuario y membresia</div>
    </v-card-title>
    <hr class="my-3" />
    <v-card-text class="my-0 py-0">
      <p>Esta acción creará un usuario y membresia con estado <b>pagado.</b></p>
      <p>Recuerde verificar que el usuario ya realizó el pago.</p>
    </v-card-text>
    <hr class="my-3" />
    <v-card-actions class="justify-end">
      <v-btn color="primary" rounded @click="createUser">
        Aceptaqr
      </v-btn>
      <v-btn text color="primary" rounded @click="closeDialog">Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CreateUserMembershipModal',
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    createUser() {
      this.$emit('create-b2c-user');
    }
  }
};
</script>
