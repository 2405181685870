<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Cupones</h1>
      <v-dialog
        persistent
        v-model="showDialog"
        min-width="600"
        max-width="1200"
        min-height="600"
        max-height="900"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-none d-md-block"
            rounded
            color="primary"
            depressed
            v-bind="attrs"
            v-on="on"
          >
            <span class="d-none d-md-block">Agregar Cupón</span>
          </v-btn>
        </template>

        <template>
          <coupon-maintainer
            :coupon="selectedCoupon"
            :countries="countries"
            @update-list="getCoupons(this.page, this.limit)"
            @close-dialog="closeDialog"
          ></coupon-maintainer>
        </template>
      </v-dialog>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="coupons"
      :options.sync="options"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:top>
        <v-row class="px-5 py-5" style="justify-content: space-between; align-items: center">
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-text-field
              v-model="stringToSearch"
              label="Buscar"
              :disabled="loadingData"
              placeholder="Nombre"
              dense
              hide-details
              outlined
              append-icon="mdi-magnify"
              @click:append="searchString"
              @keyup="cleanSearch"
              @keyup.enter="searchString"
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="6" class="my-0 py-0 ">
            <v-container class="ma-0 pa-0 mt-3 mt-md-0 d-flex flex-column align-center justify-end">
              <span class="align-self-end text-caption">Info cupones (todos)</span>

              <v-btn class="mb-3 align-self-end" color="primary" depressed @click="downloadCsv">
                <v-icon dark> mdi-file-download-outline </v-icon>
                <span>CSV</span>
              </v-btn>
            </v-container>
          </v-col>
          <v-col v-if="errorCsv" class="mr-3 ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end">
            <v-alert dense outlined type="error">Ocurrió un error</v-alert>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.monthlyFlowId="{ item }">
        <span>{{ getMonthlyDiscount(item) }}</span>
      </template>
      <template v-slot:item.annualFlowId="{ item }">
        <span>{{ getAnnualDiscount(item) }}</span>
      </template>
      <template v-slot:item.paws="{ item }">
        <span>{{ getPaws(item) }}</span>
      </template>
      <template v-slot:item.maxRedemptions="{ item }">
        <span>{{ getUses(item) }}</span>
      </template>
      <template v-slot:item.redemptions="{ item }">
        <div>
          <span>{{ item.redemptions.length }}</span>
        </div>
      </template>
      <template v-slot:item.period="{ item }">
        <!-- <span>{{ getPeriod(item) }}</span> -->
        <span v-if="item.startDate != null && item.endDate != null">
          {{ item.startDate | date }} - {{ item.endDate | date }}
        </span>
        <span v-if="item.startDate != null && item.endDate == null">
          Desde {{ item.startDate | date }}
        </span>
        <span v-if="item.startDate == null && item.endDate != null">
          Hasta {{ item.endDate | date }}
        </span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-icon v-if="item.status == 1" color="green">mdi-check-circle</v-icon>
        <v-icon v-else color="red">mdi-close-circle</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              depressed
              x-small
              v-bind="attrs"
              v-on="on"
              color="blue"
              @click="openDialog(item)"
            >
              <v-icon small dark>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Revisar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <overlay :value="loadingOverlay"></overlay>
  </div>
</template>

<script>
import CouponMaintainer from '../components/CouponMaintainer';
import Overlay from '../components/Overlay.vue';
import CouponsService from '../services/coupons.service';
import CountryService from '../services/countries.service';

export default {
  components: { Overlay, CouponMaintainer },
  data() {
    return {
      errorCsv: false,
      showDialog: false,
      showActivateDialog: false,
      loadingData: true,
      loadingOverlay: false,
      options: {},
      dateMenu: false,
      coupons: [],
      selectedCoupon: null,
      limit: 10,
      page: 1,
      totalItems: 0,
      stringToSearch: null,
      countries: [],
      headers: [
        {
          text: 'Nombre',
          value: 'name',
          sortable: false
        },
        {
          text: 'Desc. mensual',
          value: 'monthlyFlowId',
          sortable: false
        },
        {
          text: 'Desc.anual',
          value: 'annualFlowId',
          sortable: false
        },
        {
          text: 'Patitas',
          value: 'paws',
          sortable: false
        },
        {
          text: 'Límite',
          value: 'maxRedemptions',
          sortable: false
        },
        {
          text: 'Usos',
          value: 'redemptions',
          sortable: false
        },
        {
          text: 'Periodo',
          value: 'period',
          sortable: false
        },
        {
          text: 'Activo',
          value: 'status',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ]
    };
  },
  mounted() {
    this.getCountries();
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.getCoupons();
      },
      deep: true
    }
  },
  methods: {
    getMonthlyDiscount(item) {
      if (item.monthlyAmount != null) {
        if (item.monthlyTimes > 1) {
          return (
            (item.monthlyCurrency == 'CLP' ? '$' : '') +
            item.monthlyAmount +
            ' ' +
            item.monthlyCurrency +
            ' por ' +
            item.monthlyTimes +
            ' meses'
          );
        } else {
          return (
            (item.monthlyCurrency == 'CLP' ? '$' : '') +
            item.monthlyAmount +
            ' ' +
            item.monthlyCurrency +
            ' por 1 mes'
          );
        }
      } else if (item.monthlyPercentOff != null) {
        if (item.monthlyTimes > 1) {
          return item.monthlyPercentOff + '% por ' + item.monthlyTimes + ' meses';
        } else {
          return item.monthlyPercentOff + '% por 1 mes';
        }
      } else {
        return '--';
      }
    },
    getAnnualDiscount(item) {
      if (item.annualAmount != null) {
        if (item.annualTimes > 1) {
          return (
            (item.annualCurrency == 'CLP' ? '$' : '') +
            item.annualAmount +
            ' ' +
            item.annualCurrency +
            ' por ' +
            item.annualTimes +
            ' años'
          );
        } else {
          return (
            (item.annualCurrency == 'CLP' ? '$' : '') +
            item.annualAmount +
            ' ' +
            item.annualCurrency +
            ' por 1 año'
          );
        }
      } else if (item.annualPercentOff != null) {
        if (item.annualTimes > 1) {
          return item.annualPercentOff + '% por ' + item.annualTimes + ' años';
        } else {
          return item.annualPercentOff + '% por 1 año';
        }
      } else {
        return '--';
      }
    },
    getPaws(item) {
      if (
        item.monthlyPawsReward != null &&
        item.monthlyPawsReward > 0 &&
        item.annualPawsReward != null &&
        item.annualPawsReward > 0
      ) {
        return item.monthlyPawsReward + '/mes y ' + item.annualPawsReward + '/año';
      } else if (item.monthlyPawsReward != null && item.monthlyPawsReward > 0) {
        return item.monthlyPawsReward + ' /mes';
      } else if (item.annualPawsReward != null && item.annualPawsReward > 0) {
        return item.annualPawsReward + ' /año';
      } else {
        return '--';
      }
    },
    getUses(item) {
      if (
        item.maxRedemptions != null &&
        item.maxRedemptions > 0 &&
        item.userMaxRedemptions != null &&
        item.userMaxRedemptions > 0
      ) {
        return item.maxRedemptions + ' totales y ' + item.userMaxRedemptions + ' por usuario';
      } else if (item.maxRedemptions != null && item.maxRedemptions > 0) {
        return item.maxRedemptions + ' totales';
      } else if (item.userMaxRedemptions != null && item.userMaxRedemptions > 0) {
        return item.userMaxRedemptions + ' por usuario';
      } else {
        return 'Ilimitado';
      }
    },
    getPeriod(item) {
      if (item.startDate != null && item.endDate != null) {
        return item.startDate + ' - ' + item.endDate;
      } else if (item.startDate != null) {
        return 'Desde ' + item.startDate;
      } else if (item.endDate != null) {
        return 'Hasta ' + item.endDate;
      } else {
        return '--';
      }
    },
    openDialog(coupon) {
      this.showDialog = true;
      this.selectedCoupon = coupon;
    },
    closeDialog() {
      this.showDialog = false;
      this.selectedCoupon = null;
      this.getCoupons();
    },
    async getCoupons() {
      this.loadingOverlay = true;
      this.loadingData = true;
      try {
        const res = await CouponsService.getCoupons(
          this.page,
          this.limit,
          this.stringToSearch && this.stringToSearch.length >= 3 ? this.stringToSearch : null
        );
        if (res.status === 'ok') {
          this.coupons = res.data.codes;
          this.totalItems = res.data.totalItems;
          console.log('this.coupons', this.coupons);
        }
        this.loadingOverlay = false;
        this.loadingData = false;
        this.errorCsv = false;
      } catch (error) {
        this.errorCsv = true;
        this.loadingOverlay = false;
        this.loadingData = false;
      }
    },
    async getCountries() {
      try {
        const res = await CountryService.getAllCountries(1, 10, { visible: true });
        if (res) {
          this.countries = res;
          console.log('this.countries', this.countries);
        }
      } catch (e) {
        console.log(e);
      }
    },
    cleanSearch() {
      if (!this.stringToSearch || this.stringToSearch.length == 0) {
        this.getCoupons();
      }
    },
    searchString() {
      if (
        (this.stringToSearch && this.stringToSearch.length >= 3) ||
        !this.stringToSearch ||
        this.stringToSearch.length == 0
      ) {
        this.getCoupons();
      }
    },
    async downloadCsv() {
      try {
        this.loadingOverlay = true;
        const res = await CouponsService.getExportedCsv(this.page, this.limit);
        if (res.status === 'ok') {
          const blob = res.data.data;
          const fileName = `${Date.now().toString()}_codes.xlsx`;
          const url = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.download = fileName;
          a.href = url;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          a = null;
          URL.revokeObjectURL(url);
        } else {
          throw Error();
        }
        this.loadingOverlay = false;
        this.errorCsv = false;
      } catch (err) {
        console.error(err);
        this.errorCsv = true;
        this.loadingOverlay = false;
      }
    }
  }
};
</script>
