<template>
  <div>
    <overlay :value="loading"></overlay>
    <v-card class="pa-6">
      <v-card-title class="text-uppercase">
        <v-row class="my-1 justify-space-between align-center">
          <div>{{ this.service === null ? 'Agregar nuevo' : 'Detalle del' }} servicio</div>
          <v-switch
            v-if="service !== null"
            v-model="editing"
            label="Editar"
            dense
            hide-details="auto"
          ></v-switch>
        </v-row>
      </v-card-title>
      <hr class="my-3" />
      <v-card-text class="my-0 py-0">
        <v-form ref="newServiceForm" v-model="formValid">
          <v-container class="ma-0 pa-0">
            <v-row class="ma-0">
              <v-col cols="4">
                <v-select
                  label="Seleccione servicio"
                  v-model="newService.selectedService"
                  :items="services"
                  item-text="title"
                  item-value="id"
                  :disabled="formDisabled"
                  required
                  outlined
                  hide-details="auto"
                  dense
                  @change="showServiceDetails"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Días de carencia"
                  v-model="newService.supplierGraceDays"
                  :disabled="formDisabled"
                  required
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  label="Moneda"
                  v-model="newService.maxAnnualAmountCurrency"
                  :disabled="formDisabled"
                  :items="currencies"
                  required
                  outlined
                  hide-details="auto"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="4">
                <v-text-field
                  label="Valor máximo anual"
                  v-model="newService.maxAnnualAmount"
                  :disabled="formDisabled"
                  required
                  outlined
                  dense
                  clearable
                  min="0"
                  type="number"
                  hide-details="auto"
                  :suffix="newService.maxAnnualAmountCurrency"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Máximos eventos al año"
                  v-model="newService.maxAnnualEvents"
                  :disabled="formDisabled"
                  required
                  outlined
                  clearable
                  min="1"
                  dense
                  type="number"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!--<v-col cols="4">
                <v-select
                  label="Proveedor"
                  v-model="newService.supplier"
                  :disabled="formDisabled"
                  :items="['Pawer']"
                  required
                  outlined
                  hide-details="auto"
                  dense
                ></v-select>
              </v-col>-->
            </v-row>
            <v-row class="ma-0">
              <v-col cols="12">
                <v-textarea
                  label="Descripción corta"
                  v-model="newService.shortDescription"
                  :disabled="formDisabled"
                  required
                  outlined
                  auto-grow
                  rows="3"
                  dense
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="12">
                <v-textarea
                  label="De qué trata el servicio"
                  v-model="newService.whatIs"
                  :disabled="formDisabled"
                  required
                  outlined
                  auto-grow
                  rows="3"
                  dense
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <hr class="my-3" />
      <v-card-actions class="justify-end">
        <v-btn
          v-if="service === null || editing"
          :disabled="!formValid"
          color="primary"
          rounded
          @click="validate"
        >
          Guardar
        </v-btn>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="showSnackbar" :timeout="5000">
      {{ snackbarContent }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="showSnackbar = false"> Entendido </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Overlay from '../components/Overlay.vue';
import PlansService from '../services/plans.service';
import ServicesService from '../services/services.service';

export default {
  components: { Overlay },
  name: 'ServicePlanMaintainer',
  props: ['service', 'plan'],
  data() {
    return {
      editing: false,
      loading: false,
      showSnackbar: false,
      snackbarContent: 'Ocurrió un error guardando el servicio, intenta más tarde',
      newService: {},
      currencies: ['UF', 'CLP'],
      services: [],
      selectedService: null,
      formValid: false
    };
  },
  mounted() {
    this.fetchServices();
    if (this.service !== null) {
      this.parseService(true);
    } else {
      this.newService = {};
    }
    this.$refs.newServiceForm.resetValidation();
  },
  computed: {
    formDisabled() {
      if (this.service === null) {
        return false;
      } else {
        if (this.editing) {
          return false;
        }
        return true;
      }
    }
  },
  watch: {
    service(val) {
      this.newService = {};
      this.$refs.newServiceForm.resetValidation();
      this.parseService(val !== null);
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
      this.editing = false;
    },
    parseService(exist) {
      if (exist) {
        this.newService.planId = this.planId;
        this.newService.serviceId = this.serviceId;
        this.newService.supplierGraceDays = this.service.supplierGraceDays || 0;
        this.newService.maxAmountPerEventCurrency = this.service.maxAmountPerEventCurrency;
        this.newService.maxAnnualAmount = this.service.maxAnnualAmount || 0;
        this.newService.maxAnnualEvents = this.service.maxAnnualEvents || 0;
        this.newService.shortDescription = this.service.shortDescription;
        this.newService.whatIs = this.service.whatIs;
        this.newService.order = this.service.order;
        this.newService.coveragePercentagePerEvent = this.service.coveragePercentagePerEvent;
        this.newService.maxMonthlyAmountCurrency = this.service.maxMonthlyAmountCurrency;
        this.newService.maxAnnualAmountCurrency = this.service.maxAnnualAmountCurrency;
        this.newService.maxAmountPerEvent = this.service.maxAmountPerEvent;
        this.newService.supplierPlanId = this.service.supplierPlanId;
        this.newService.howToUse = this.service.howToUse;
        this.newService.restrictions = this.service.restrictions;
        this.newService.maxMonthlyAmount = this.service.maxMonthlyAmount;
      } else {
        this.newService = {
          maxAnnualAmount: 0,
          maxAnnualAmountCurrency: '',
          coveragePercentagePerEvent: 0,
          maxMonthlyAmount: 0,
          maxMonthlyAmountCurrency: '',
          maxAnnualEvents: 0,
          maxAmountPerEvent: 0,
          maxAmountPerEventCurrency: '',
          order: 0,
          planId: this.plan.id,
          serviceId: '',
          supplierPlanId: '',
          supplierGraceDays: 0,
          shortDescription: '',
          whatIs: '',
          howToUse: '',
          restrictions: ''
        };
      }
    },
    async fetchServices() {
      this.loading = true;
      try {
        const response = await ServicesService.getAllServices();
        if (response.status === 'ok') {
          this.services = response.data;
        }
      } catch (error) {
        this.loading = false;
        this.noDataMsg = 'Algo salió mal, intentalo más tarde';
        console.log(error);
      }
      this.loading = false;
    },
    showServiceDetails(serviceId) {
      const selectedService = this.services.find(service => service.id === serviceId);
      if (selectedService && selectedService['service-plan-default']) {
        if (!this.editing) {
          this.newService = {
            ...this.newService,
            maxAnnualAmount: selectedService['service-plan-default'].maxAnnualAmount,
            maxAnnualEvents: selectedService['service-plan-default'].maxAnnualEvents,
            supplierGraceDays: selectedService['service-plan-default'].supplierGraceDays,
            shortDescription: selectedService['service-plan-default'].shortDescription,
            whatIs: selectedService['service-plan-default'].whatIs,
            howToUse: selectedService['service-plan-default'].howToUse,
            serviceId: selectedService['id']
          };
        }
      }
    },
    validate() {
      if (this.$refs.newServiceForm.validate()) {
        if (this.service === null) {
          this.createServicePlan();
        } else if (this.editing) {
          this.updateServicePlan();
        }
      }
    },
    async createServicePlan() {
      this.newService.planId = this.plan.id;
      const res = await PlansService.addServiceToPlan(this.newService);
      if (res.status === 'ok') {
        this.$refs.newServiceForm.resetValidation();
        this.closeDialog();
      }
    },
    async updateServicePlan() {
      this.loading = true;
      const res = await PlansService.updateServicePlan(this.newService);
      this.loading = false;
      if (res.status === 'ok') {
        this.$refs.newServiceForm.resetValidation();
        this.closeDialog();
      }
    },
    async deleteService(serviceId) {
      try {
        const res = await PlansService.removeServiceFromPlan(this.plan.id, serviceId);
        if (res.status === 'ok') {
          this.planServices = this.planServices.filter(service => service.id !== serviceId);
        }
      } catch (error) {
        console.error('Error removing service from plan:', error);
      }
    }
  }
};
</script>
