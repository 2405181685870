<template>
  <div>
    <!-- Edit dialog -->
    <v-dialog
      persistent
      v-model="showEditDialog"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <membership-maintainer
        @close-dialog="closeEditDialog"
        :membership="selectedMembership"
      ></membership-maintainer>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="memberships"
      :options.sync="options"
      :loading="loadingMemberships"
      :items-per-page="limit"
      :server-items-length="totalItems"
      :sort-desc.sync="sort"
      :disable-sort="loadingMemberships"
      multi-sort
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:top>
        <v-row class="px-5 py-5" style="justify-content: space-between; align-items: center">
          <v-col cols="12" md="6" class="my-0 py-2">
            <v-select
              label="Proveedor"
              v-model="selectedSupplier"
              :loading="loadingSuppliers"
              :items="suppliers"
              :disabled="loadingSuppliers || loadingMemberships"
              item-text="name"
              required
              outlined
              dense
              return-object
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="my-0 py-2">
            <v-text-field
              v-model="stringToSearch"
              label="Buscar"
              :disabled="loadingSuppliers || loadingMemberships"
              placeholder="Referencia, mascota, plan..."
              dense
              hide-details
              outlined
              append-icon="mdi-magnify"
              @click:append="searchString"
              @keyup="cleanSearch"
              @keyup.enter="searchString"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-if="selectedSupplier"
          class="mt-2 mb-1 mx-5"
          style="justify-content: space-between; align-items: center"
        >
          <v-subheader class="px-0" style="flex-wrap: wrap">
            <span v-if="selectedSupplier.phoneNumber"
              >Número de asistencia {{ selectedSupplier.name }}&ensp;</span
            >
            <span v-if="selectedSupplier.phoneNumber" style="font-weight: bold">{{
              selectedSupplier.phoneNumber
            }}</span>
          </v-subheader>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                v-bind="attrs"
                v-on="on"
                :loading="downloadingExcel"
                @click="downloadExcel(false)"
              >
                <v-icon dark> mdi-google-spreadsheet </v-icon>
                <span>Excel homologaciones</span>
              </v-btn>
            </template>
            <span>Membresías no homologadas</span>
          </v-tooltip>
        </v-row>
        <v-row
          v-if="selectedSupplier"
          class="mt-1 mb-2 mx-2"
          style="justify-content: right; align-items: center"
        >
          <v-col cols="0" sm="2"></v-col>
          <v-col cols="6" sm="5" md="3">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="Fecha Excel Mensual"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  hide-details
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                type="month"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" sm="5" md="3" lg="2" class="mx-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  depressed
                  block
                  v-bind="attrs"
                  v-on="on"
                  :loading="downloadingMonthlyExcel"
                  @click="downloadExcel(true)"
                >
                  <v-icon dark> mdi-google-spreadsheet </v-icon>
                  <span>Excel mensual</span>
                </v-btn>
              </template>
              <span>Pagos mensuales</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- NEW EXCEL SINCE ("Weekly excel") -->
        <v-row
          v-if="selectedSupplier"
          class="mt-1 mb-2 mx-2"
          style="justify-content: right; align-items: center"
        >
          <v-col cols="0" sm="2"></v-col>

          <v-col cols="6" sm="5" md="3">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedSelectedDateFormatted"
                  label="Fecha Excel desde"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  hide-details
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedDate"
                :show-current="true"
                no-title
                type="date"
                :max="computedFullDate"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" sm="5" md="3" lg="2" class="mx-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  depressed
                  block
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selectedSupplier.name != 'Southbridge'"
                  :loading="downloadingWeeklyExcel"
                  @click="downloadWeeklyExcel"
                >
                  <v-icon dark> mdi-google-spreadsheet </v-icon>
                  <span>Excel Custom</span>
                </v-btn>
              </template>
              <span>Altas y bajas desde {{ selectedDateFormated }} a hoy</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.user="{ item }">
        <span>{{ item.user.name || '' }} {{ item.user.surname || '' }}</span>
      </template>
      <template v-slot:item.plan.name="{ item }">
        <span>{{ item.plan.name }}</span>
      </template>
      <template v-slot:item.finishSupplierGraceDays="{ item }">
        <span v-if="item.finishSupplierGraceDays">{{ item.finishSupplierGraceDays | date }}</span>
        <span v-else>—</span>
      </template>
      <template v-slot:item.status="{ item }">
        <membership-status-chip :status="item.status"></membership-status-chip>
      </template>
      <template v-slot:item.homologated="{ item }">
        <v-chip-group>
          <v-chip
            v-if="item.homologated === true"
            small
            outlined
            color="green"
            class="white--text font-weight-bold"
            >Homologada</v-chip
          >
          <v-chip
            v-if="item.homologated === false"
            small
            outlined
            color="red"
            class="white--text font-weight-bold"
            >No homologada</v-chip
          >
        </v-chip-group>
      </template>
      <template v-slot:item.homologatedAt="{ item }">
        <span>{{ dateText(item.homologatedAt) }}</span>
      </template>
      <template v-slot:item.finishTrialDate="{ item }">
        <span>{{ getRemainingTrialDaysString(item) }}</span>
      </template>
      <template v-slot:item.actions="props">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              depressed
              x-small
              fab
              color="green"
              v-bind="attrs"
              v-on="on"
              @click="editMembership(props.item)"
            >
              <v-icon small color="white">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Revisar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import MembershipMaintainer from '../components/MembershipMaintainer';
import MembershipStatusChip from '../components/MembershipStatusChip';
import MembershipsService from '../services/memberships.service';
import PlansService from '../services/plans.service';
import DateUtils from '../utils/date.utils';

export default {
  name: 'MembershipHomologation',
  components: { MembershipMaintainer, MembershipStatusChip },
  data() {
    return {
      loading: false,
      selectedSupplier: null,
      suppliers: [],
      loadingSuppliers: false,
      memberships: [],
      loadingMemberships: false,
      totalItems: 0,
      options: {},
      sort: [],
      limit: 10,
      page: 1,
      stringToSearch: null,
      selectedMembership: null,
      showEditDialog: false,
      headers: [
        {
          text: 'Cliente',
          value: 'user',
          sortable: false
        },
        {
          text: 'Mascota',
          value: 'pet.name',
          sortable: false
        },
        {
          text: 'Referencia',
          value: 'ref',
          sortable: true
        },
        {
          text: 'Plan',
          value: 'plan.name',
          sortable: false
        },
        {
          text: 'Fin carencia',
          value: 'finishSupplierGraceDays',
          sortable: false
        },
        {
          text: 'Días gratis restantes',
          value: 'finishTrialDate',
          sortable: true
        },
        {
          text: 'Estado membresía',
          value: 'status',
          sortable: true
        },
        {
          text: 'Homologada',
          value: 'homologated',
          sortable: true
        },
        {
          text: 'F. Homologación',
          value: 'homologatedAt',
          sortable: false
        },
        {
          text: 'Acciones',
          value: 'actions',
          sortable: false
        }
      ],
      downloadingExcel: false,
      downloadingMonthlyExcel: false,
      downloadingWeeklyExcel: false,

      date: '',
      dateFormatted: '',
      fullDate: '',
      fullDateFormatted: '',
      selectedDate: '',
      selectedDateFormated: ''
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedFullDate() {
      return this.fullDate;
    },
    computedSelectedDate() {
      return this.selectedDate;
    },
    computedSelectedDateFormatted() {
      return this.selectedDateFormated;
    }
  },
  mounted() {
    this.loadSuppliers();
    let today = new Date();
    today.setMonth(today.getMonth() - 1);
    this.date = today.toISOString().substr(0, 7);
    this.dateFormatted = this.formatDate(today.toISOString().substr(0, 7));

    let todayMonth =
      (new Date().getMonth() + 1).toString().length == 1
        ? `0${new Date().getMonth() + 1}`
        : `${new Date().getMonth() + 1}`;

    let todayDay =
      new Date().getDate().toString().length == 1
        ? `0${new Date().getDate()}`
        : `${new Date().getDate()}`;

    this.fullDate = `${new Date().getFullYear()}-${todayMonth}-${todayDay}`;
    this.fullDateFormatted = `${todayDay}-${todayMonth}-${new Date().getFullYear()}`;

    let weekAgoNotFormatted = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

    let weekAgoMonth =
      (weekAgoNotFormatted.getMonth() + 1).toString().length == 1
        ? `0${weekAgoNotFormatted.getMonth() + 1}`
        : `${weekAgoNotFormatted.getMonth() + 1}`;

    let weekAgoDay =
      weekAgoNotFormatted.getDate().toString().length == 1
        ? `0${weekAgoNotFormatted.getDate()}`
        : `${weekAgoNotFormatted.getDate()}`;

    let weekAgo = `${weekAgoNotFormatted.getFullYear()}-${weekAgoMonth}-${weekAgoDay}`;

    let weekAgoFormatted = `${weekAgoDay}-${weekAgoMonth}-${weekAgoNotFormatted.getFullYear()}`;

    this.selectedDate = weekAgo;
    this.selectedDateFormated = weekAgoFormatted;
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.menu = false;
    },
    selectedDate() {
      this.selectedDateFormated = this.formatSelectedDate(this.selectedDate);
      this.menu = false;
    },
    selectedSupplier() {
      this.page = 1;
      this.limit = 10;
      let sort = [];
      if (this.options.sortBy.length !== 0) {
        sort = this.options.sortBy.map((s) => {
          const index = this.options.sortBy.indexOf(s);
          return this.options.sortDesc[index] == true ? `-${s}` : s;
        });
      }
      this.loadMemberships(sort);
    },
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        let sort = [];
        if (this.options.sortBy.length !== 0) {
          sort = this.options.sortBy.map((s) => {
            const index = this.options.sortBy.indexOf(s);
            return this.options.sortDesc[index] == true ? `-${s}` : s;
          });
        }
        this.loadMemberships(sort);
      },

      deep: true
    },
    search: {
      handler() {
        this.page = 1;
        this.limit = 10;
        this.sort = [];
        this.loadMemberships(this.sort);
      },
      deep: true
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month] = date.split('-');
      return `${month}-${year}`;
    },
    formatSelectedDate(selectedDate) {
      if (!selectedDate) return null;
      const [year, month, day] = selectedDate.split('-');
      return `${day}-${month}-${year}`;
    },
    async loadSuppliers() {
      this.loadingSuppliers = true;
      const res = await PlansService.getSuppliers();
      this.suppliers = res.data.suppliers;
      if (this.suppliers.length > 0) {
        this.selectedSupplier = this.suppliers[0];
      }
      this.loadingSuppliers = false;
    },
    async loadMemberships(sort) {
      if (this.selectedSupplier) {
        const supplierId = this.selectedSupplier.id;
        this.loadingMemberships = true;
        const res = await MembershipsService.getMemberships(
          this.page,
          this.limit,
          [2, 4],
          sort,
          null,
          this.stringToSearch && this.stringToSearch.length >= 3 ? this.stringToSearch : null,
          supplierId
        );
        if (res.status === 'ok') {
          this.memberships = res.data.memberships;
          this.totalItems = res.data.totalItems;
        }
        this.loadingMemberships = false;
      }
    },
    getRemainingTrialDaysString(membership) {
      return DateUtils.getRemainingTrialDaysString(membership);
    },
    editMembership(membership) {
      this.showEditDialog = true;
      this.selectedMembership = membership;
    },
    closeEditDialog() {
      this.showEditDialog = false;
      this.activeMembership = null;
      this.loadMemberships(this.sort);
    },
    searchString() {
      if (
        (this.stringToSearch && this.stringToSearch.length >= 3) ||
        !this.stringToSearch ||
        this.stringToSearch.length == 0
      ) {
        this.sort = [];
        this.loadMemberships(this.sort);
      }
    },
    cleanSearch() {
      if (!this.stringToSearch || this.stringToSearch.length == 0) {
        this.sort = [];
        this.loadMemberships(this.sort);
      }
    },
    async downloadExcel(dated) {
      if (this.selectedSupplier) {
        if (dated) {
          this.downloadingMonthlyExcel = true;
        } else {
          this.downloadingExcel = true;
        }
        const res = await MembershipsService.getSupplierExcel(
          this.selectedSupplier.id,
          dated ? this.date : null
        );
        if (res.status === 'ok') {
          const blob = res.data.data;
          const now = Date.now();
          const fileName = `${now.toString()}_${this.selectedSupplier.name}_memberships.xlsx`;
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = (e) => {
            let a = document.createElement('a');
            a.download = fileName;
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          };
        }
        this.downloadingExcel = false;
        this.downloadingMonthlyExcel = false;
      }
    },
    async downloadWeeklyExcel() {
      // Agregar una funcionalidad a esto
      if (this.selectedSupplier) {
        this.downloadingWeeklyExcel = true;

        const res = await MembershipsService.getWeeklyExcel(
          this.selectedSupplier.id,
          this.selectedDateFormated,
          this.fullDateFormatted
        );
        if (res.status === 'ok') {
          const blob = res.data.data;
          const now = Date.now();
          const fileName = `${now.toString()}_${this.selectedSupplier.name}_memberships.xlsx`;
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = (e) => {
            let a = document.createElement('a');
            a.download = fileName;
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          };
        }
        console.log(res);
        this.downloadingWeeklyExcel = false;
      }
    },
    dateText(date) {
      return date ? moment.utc(date).format('DD/MM/YYYY') : null;
    }
  }
};
</script>
