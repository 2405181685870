import axios from 'axios';

const backendUrl = process.env.VUE_APP_BACKEND_URL;

export default class PaymentsService {
  static async getPayments(page, limit, query) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));

      let params = {
        page,
        limit
      };

      if (query) {
        if (query.type) {
          params = { ...params, type: query.type };
        }
        if (query.from) {
          params = { ...params, from: `${query.from}T00:00:00Z` };
          if (query.to) {
            params = { ...params, to: `${query.to}T23:59:59Z` };
          } else {
            params = { ...params, to: `${query.from}T23:59:59Z` };
          }
        }
      }

      const response = await axios.get(
        `${backendUrl}/payments`,

        {
          params,
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }
}
