<template>
  <div>
    <v-card>
      <v-card-title class="text-uppercase">
        <v-row class="pa-6 align-center">
          <div>Registros de la mascota</div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!create"
            class="mr-2"
            rounded
            color="primary"
            depressed
            @click="addNewRecord()"
          >
            <span>Agregar</span>
          </v-btn>
          <v-btn icon color="grey" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-0">
        <v-expansion-panels flat accordion v-if="!loading" v-model="panel">
          <v-expansion-panel :class="create == true ? 'd-block' : 'd-none'">
            <v-expansion-panel-header>
              <v-container class="d-flex flex-row align-center ma-0 px-2 py-0">
                <p class="text-h6 text--primary ma-0 pa-0">Nuevo registro</p>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <record-maintainer
                :key="0"
                :record="newRecord"
                :create="create"
                :categories="categories"
                :services="services"
                :plan="pet && pet.membership ? pet.membership.plan : null"
                :membership="pet && pet.membership ? pet.membership : null"
                @reload-records="getData()"
                @close-create="closeCreate()"
              />
            </v-expansion-panel-content>
            <v-divider></v-divider>
          </v-expansion-panel>

          <v-expansion-panel v-for="record in records" :key="record.id">
            <v-expansion-panel-header>
              <v-container class="d-flex flex-row align-center ma-0 px-2 py-0">
                <div>
                  <v-chip color="primary" small class="mb-1">
                    {{ categories.filter((c) => c.id == record.categoryId)[0].name }}
                  </v-chip>
                  <p class="text-h6 text--primary ma-0 pa-0">{{ record.title }}</p>
                </div>

                <v-spacer></v-spacer>

                <div>
                  <p class="ma-0 pa-0 pb-1 text-right">
                    <span>{{ record.date | moment('DD/MM/YYYY') }}</span>
                    <span v-if="record.withTime">{{ record.date | moment(' HH:mm') }}</span>
                  </p>
                  <p class="ma-0 pa-0 text-right">
                    <span>Gastos: </span>
                    <span v-if="record.expensesCurrency == 'CLP'">{{ record.expenses | clp }}</span>
                    <span v-if="record.expensesCurrency != 'CLP'">{{ record.expenses }} UF</span>
                  </p>
                </div>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <record-maintainer
                :record="record"
                :create="false"
                :categories="categories"
                :services="services"
                :plan="pet && pet.membership ? pet.membership.plan : null"
                :membership="pet && pet.membership ? pet.membership : null"
                @reload-records="getData()"
                @close-create="closeCreate()"
              />
            </v-expansion-panel-content>
            <v-divider></v-divider>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="text-center pa-6" style="min-width: 300px" v-if="loading">
          <v-progress-circular indeterminate :size="50" color="primary"></v-progress-circular>
        </div>

        <v-container v-if="!loading && records.length == 0 && !create" class="pa-6 text-center">
          <span>Todavía no hay registros para esta mascota</span>
        </v-container>

        <div v-if="pages > 0" class="text-center pa-3">
          <v-pagination :disabled="loading" v-model="page" :length="pages" circle></v-pagination>
        </div>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="error" :timeout="5000">
      Ocurrió un error al cargar los registros
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false"> Entendido </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
  margin: 0;
}
</style>

<script>
import PetsService from '../services/pets.service';
import PlansService from '../services/plans.service';
import RecordMaintainer from './RecordMaintainer.vue';

export default {
  components: { RecordMaintainer },
  name: 'RecordsList',
  props: ['pet'],
  data() {
    return {
      editing: false,
      loading: false,
      error: false,
      newRecord: null,
      limit: 5,
      page: 1,
      pages: 0,
      records: [],
      categories: [],
      create: false,
      panel: null,
      services: []
    };
  },
  mounted() {
    if (this.pet) {
      this.getData();
    }
  },
  watch: {
    pet() {
      // Load records if pet != null
      if (this.pet) {
        this.getData();
      }
    },
    page() {
      this.getData();
    }
  },
  computed: {},
  methods: {
    async getData() {
      this.loading = true;
      await this.getRecords();
      await this.getCategories();
      if (this.pet.membership) {
        await this.getServices(this.pet.membership.plan.id);
      }
      this.loading = false;
    },
    async getRecords() {
      const res = await PetsService.getRecordsByPet(this.pet.id, this.page, this.limit);
      this.records = res.data.records;
      this.pages = Math.ceil(res.data.totalItems / this.limit);
    },
    async getCategories() {
      const response = await PetsService.getRecordCategories();
      if (response.status === 'ok') {
        this.categories = response.data;
        this.categories.push({
          name: 'Otra',
          id: null
        });
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
      this.closeCreate();
      this.editing = false;
    },
    addNewRecord() {
      this.create = true;
      this.newRecord = {
        petId: this.pet.id,
        date: new Date()
      };
      this.panel = 0;
    },
    closeCreate() {
      this.create = false;
      this.newRecord = null;
      this.panel = null;
    },
    async getServices(planId) {
      const response = await PlansService.getServicesByPlan(planId);
      this.services = response.data;
    }
  }
};
</script>
