<template>
  <div>
    <v-card class="pa-6">
      <v-card-title class="text-uppercase">
        <v-row class="my-1 justify-space-between align-center">
          <div>Detalles</div>
        </v-row>
      </v-card-title>
  
      <hr class="my-3" />
  
      <v-card-text class="my-0 py-0">
        <v-form ref="newFeatureForm" v-model="formValid">
          <v-container class="ma-0 pa-0">
            <v-row class="ma-0">
              <v-col cols="6">
                <v-select
                  label="Key"
                  v-model="newFeature.key"
                  :items="['details', 'labelColor', 'mainColor']"
                  required
                  outlined
                  hide-details="auto"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="6">
                <v-textarea
                  label="Ingrese valor"
                  v-model="newFeature.value"
                  required
                  outlined
                  out-grow
                  rows="1"
                  hide-details="auto"
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
  
      <hr class="my-3" />
  
      <v-card-actions class="justify-end">
        <v-btn 
          color="primary" 
          rounded 
          @click="validate"
        >Guardar</v-btn>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="showSnackbar" :timeout="5000">
      {{ snackbarContent }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="showSnackbar = false"> Entendido </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
  
<script>
import PlansService from '../services/plans.service';

  export default {
    name: 'FeaturePlanMantainer',
    props: ['plan'],
    data() {
      return {
        showSnackbar: false,
        snackbarContent: 'Ocurrió un error guardando el detalle, intenta más tarde',
        formValid: false,
        newFeature: {}
      };
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      },
      validate() {
        if (this.$refs.newFeatureForm.validate()) {
          this.createFeaturePlan();
        } 
      },
      async createFeaturePlan() {
        this.newFeature.visible = true;
        this.newFeature.planId = this.plan.id;
        const res = await PlansService.addFeatureToPlan(this.newFeature);
        if (res.status === 'ok') {
          this.$refs.newFeatureForm.resetValidation();
          this.closeDialog();
        }
      }
    }
  };
  </script>