<template>
  <div>
    <v-dialog
      persistent
      v-model="showActivateDialog"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <reward-activator
        @close-dialog="closeActivateDialog"
        :redemption="selectedRedemption"
      ></reward-activator>
    </v-dialog>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Canjes y referidos</h1>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="redemptions"
      :options.sync="options"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:top>
        <v-row class="px-5 pt-5" style="justify-content: space-between; align-items: center">
          <v-col cols="12" md="6" class="my-0 py-0"> </v-col>
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-container class="ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end">
              <v-btn
                color="primary"
                depressed
                @click="downloadExcel"
                :disabled="querySearch == null || querySearch == []"
              >
                <v-icon dark> mdi-google-spreadsheet </v-icon>
                <span>Descargar Excel</span>
              </v-btn>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="mx-2 pt-6">
          <v-col cols="12" md="6" lg="5" class="my-0 py-0">
            <v-autocomplete
              v-model="querySearch"
              chips
              clearable
              deletable-chips
              multiple
              small-chips
              label="Filtrar por código"
              outlined
              item-value="name"
              item-text="name"
              dense
              :loading="loadingCodes"
              :items="codes"
              :search-input.sync="codesSearch"
              @change="onCodesChange"
              cache-items
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" class="my-0 py-0">
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Rango de fecha"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="12" lg="4" class="my-0 py-0">
            <v-combobox
              v-model="selectedTypes"
              :items="types"
              label="Filtro"
              multiple
              outlined
              item-value="value"
              item-text="text"
              dense
              small-chips
            ></v-combobox>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.code="{ item }">
        <v-chip small color="primary" v-if="item.code != null">{{ item.code.name }}</v-chip>
      </template>

      <template v-slot:item.referrer="{ item }">
        <div class="d-flex align-center" v-if="item.referrer != null">
          <v-avatar size="30" class="mr-2">
            <span v-if="item.referrer.imageUrl == null" class="white--text text-h5"
              >{{ item.referrer.name[0] || '' }}{{ item.referrer.surname[0] || '' }}</span
            >

            <img v-if="item.referrer.imageUrl" :src="item.referrer.imageUrl" />
          </v-avatar>
          <div>
            <p class="ma-0 pa-0">
              <span>{{ item.referrer.name || '' }} {{ item.referrer.surname || '' }}</span>
            </p>
            <p class="ma-0 pa-0" v-if="item.referrer.rut">
              <span>{{ item.referrer.rut | rut }}</span>
            </p>
          </div>
        </div>
      </template>

      <template v-slot:item.referred="{ item }">
        <div class="d-flex align-center" v-if="item.user != null">
          <v-avatar size="30" class="mr-2" color="primary">
            <span v-if="item.user.imageUrl == null" class="white--text text-subtitle1"
              >{{ item.user.name[0] || '' }}{{ item.user.surname[0] || '' }}</span
            >

            <img v-if="item.user.imageUrl" :src="item.user.imageUrl" />
          </v-avatar>
          <div>
            <p class="ma-0 pa-0">
              <span>{{ item.user.name || '' }} {{ item.user.surname || '' }}</span>
            </p>
            <p class="ma-0 pa-0" v-if="item.user.rut">
              <span>{{ item.user.rut | rut }}</span>
            </p>
          </div>
        </div>
      </template>

      <template v-slot:item.membership="{ item }">
        <div class="d-flex flex-column py-2" v-if="item.membership != null">
          <div class="d-flex align-center" v-if="item.membership.pet != null">
            <v-avatar size="20" class="mr-1" color="primary">
              <v-icon dark x-small v-if="!item.membership.pet.imageUrl">
                mdi-paw
              </v-icon>

              <img v-if="item.membership.pet.imageUrl" :src="item.membership.pet.imageUrl" />
            </v-avatar>

            <span class="ma-0 pa-0">
              {{ item.membership.pet.name || '' }}
            </span>
          </div>
          <div>
            <v-chip-group class="ma-0 pa-0">
              <v-chip
                v-if="item.membership.status === 0"
                x-small
                color="orange"
                class="white--text font-weight-bold ma-0"
                >Pendiente de pago</v-chip
              >
              <v-chip
                v-if="item.membership.status === 1"
                color="blue"
                x-small
                class="white--text font-weight-bold ma-0"
                >Pendiente de activación</v-chip
              >
              <v-chip
                v-if="item.membership.status === 2"
                color="green"
                x-small
                class="white--text font-weight-bold ma-0"
                >Activo</v-chip
              >
              <v-chip
                v-if="item.membership.status === 3"
                color="red"
                x-small
                class="white--text font-weight-bold ma-0"
                >Pago fallido</v-chip
              >
              <v-chip
                v-if="item.membership.status === 4"
                color="grey"
                outlined
                x-small
                class="font-weight-bold ma-0"
                >Desactivado</v-chip
              >
              <v-chip
                v-if="item.membership.status === 5"
                color="primary"
                outlined
                x-small
                class="white--text font-weight-bold ma-0"
                >Pendiente de enrolamiento</v-chip
              >
            </v-chip-group>
          </div>
          <div v-if="item.membership.plan">
            <span class="ma-0 pa-0">
              {{ item.membership.plan.name }}
            </span>
          </div>
        </div>
      </template>

      <template v-slot:item.date="{ item }">
        <span>{{ item.date | moment('DD/MM/YYYY HH:mm') }}</span>
      </template>

      <template v-slot:item.rewarded="{ item }">
        <v-chip-group v-if="item.code.rewardsToId != null && hasValidPayment(item)">
          <v-chip
            v-if="item.rewarded === true"
            small
            color="green"
            class="white--text font-weight-bold"
            ><v-avatar left> <v-icon>mdi-check</v-icon> </v-avatar>Recompensado</v-chip
          >
          <v-chip
            v-if="item.rewarded === false"
            color="red"
            small
            class="white--text font-weight-bold"
            ><v-avatar left> <v-icon>mdi-close</v-icon> </v-avatar>No recompensado</v-chip
          >
        </v-chip-group>
        <div v-if="item.code.rewardsToId != null && !hasValidPayment(item)">
          Aún no realiza su primer pago
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="item.code.rewardsToId != null">
          <v-tooltip top v-if="!item.rewarded">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                x-small
                fab
                depressed
                color="green"
                v-bind="attrs"
                v-on="on"
                @click="activateReward(item)"
              >
                <v-icon small color="white">mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Marcar recompensa</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <overlay :value="loadingOverlay"></overlay>
    <v-snackbar v-model="emptyReport" :timeout="5000">
      No hay registros que coincidan con los filtros aplicados. Recuerda que solo aparecen en el
      reporte aquellos registros con pagos exitosos.
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false"> Entendido </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from 'moment';
import Overlay from '../components/Overlay.vue';
import RewardActivator from '../components/RewardActivator.vue';
import CodesService from '../services/codes.service';
import RedemptionsService from '../services/redemptions.service';

export default {
  components: {
    RewardActivator,
    Overlay
  },
  data() {
    return {
      showDialog: false,
      showActivateDialog: false,
      loadingCodes: false,
      loadingOverlay: false,
      codes: [],
      codesSearch: null,
      loadingData: true,
      emptyReport: false,
      options: {},
      users: [],
      dates: [],
      types: [
        {
          text: 'Recompensado',
          value: 'isRewarded'
        },
        {
          text: 'No recompensado',
          value: 'isNotRewarded'
        },
        {
          text: 'Promocional',
          value: 'promotional'
        }
      ],
      selectedTypes: [
        {
          text: 'Recompensado',
          value: 'isRewarded'
        },
        {
          text: 'No recompensado',
          value: 'isNotRewarded'
        },
        {
          text: 'Promocional',
          value: 'promotional'
        }
      ],
      querySearch: null,
      dateMenu: false,
      redemptions: [],
      selectedRedemption: null,
      limit: 10,
      page: 1,
      totalItems: 0,
      headers: [
        {
          text: 'Código',
          value: 'code',
          sortable: false
        },
        {
          text: 'Referidor',
          value: 'referrer',
          sortable: false
        },
        {
          text: 'Referido',
          value: 'referred',
          sortable: false
        },
        {
          text: 'Membresía',
          value: 'membership',
          sortable: false
        },
        {
          text: 'Fecha',
          value: 'date',
          sortable: false
        },
        {
          text: 'Recompensa',
          value: 'rewarded',
          sortable: false
        },
        {
          text: 'Acciones',
          value: 'actions',
          sortable: false
        }
      ]
    };
  },
  mounted() {
    // this.getMemberships(this.page, this.limit);
    // this.dates is an array with two dates, start at fisrt day of actual months and end at today
    this.dates = [
      moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    ];
    this.getCodes();
  },

  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;

        this.getRedemptions();
      },
      deep: true
    },
    selectedTypes: {
      handler() {
        this.getRedemptions();
      },
      deep: true
    },
    dates: {
      handler() {
        this.getRedemptions();
      },
      deep: true
    }
  },
  computed: {
    dateRangeText() {
      return this.sortedDates.map(date => moment(date).format('DD/MM/YYYY')).join(' ~ ');
    },
    sortedDates() {
      return this.dates.slice().sort((a, b) => {
        return moment(a).isBefore(b) ? -1 : 1;
      });
    },
    queryTypes() {
      console.log(this.selectedTypes);
      return this.selectedTypes.length > 0
        ? this.selectedTypes.map(type => type.value).join(',')
        : null;
    }
  },
  methods: {
    openDialog(user) {
      this.showDialog = true;
      this.selectedUser = user;
    },
    closeDialog() {
      this.showDialog = false;
      this.selectedUser = null;
      this.getRedemptions();
    },
    closeActivateDialog() {
      this.showActivateDialog = false;
      this.activatedUser = null;
      this.getRedemptions();
    },
    onCodesChange() {
      this.codesSearch = '';
      this.getRedemptions();
    },
    async getCodes() {
      this.loadingCodes = true;

      const res = await CodesService.getAllCodes();
      if (res.status === 'ok') {
        console.log(res.data);
        this.codes = res.data;
      }
      this.loadingCodes = false;
    },
    async getRedemptions() {
      this.loadingData = true;

      const query = {
        from: this.sortedDates[0],
        to: this.sortedDates[1]
      };

      if (this.queryTypes) {
        query.types = this.queryTypes;
      }

      if (this.querySearch) {
        query.codes = this.querySearch;
      }

      const res = await RedemptionsService.getRedemptions(this.page, this.limit, query);
      if (res.status === 'ok') {
        this.redemptions = res.data.redemptions;
        this.totalItems = res.data.totalItems;
      }
      this.loadingData = false;
    },
    async downloadExcel() {
      this.loadingOverlay = true;
      const query = {
        from: this.sortedDates[0],
        to: this.sortedDates[1]
      };

      if (this.queryTypes) {
        query.types = this.queryTypes;
      }

      if (this.querySearch) {
        query.codes = this.querySearch;
      }

      const res = await RedemptionsService.getRedemptionReport(query);
      console.log('res', res);
      if (res.status === 'ok') {
        const blob = res.data;

        const fileName = `report_${this.querySearch.join('_')}_${moment(this.sortedDates[0]).format(
          'DD-MM-YYYY'
        )}_${moment(this.sortedDates[1]).format('DD-MM-YYYY')}.xlsx`;
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = e => {
          let a = document.createElement('a');

          a.download = fileName;
          a.href = e.target.result;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };
      } else {
        this.emptyReport = true;
      }
      this.loadingOverlay = false;
    },
    hasValidPayment(redemption) {
      return (
        redemption.membership.payments &&
        redemption.membership.payments.filter(payment => payment.status == 2).length > 0
      );
    },
    activateReward(redemption) {
      this.showActivateDialog = true;
      this.selectedRedemption = redemption;
    }
  }
};
</script>
