export default class FileUtils {
  static getBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split('base64,')[1]);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  }
}
