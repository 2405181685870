<template>
  <div>
    <overlay :value="loading"></overlay>
    <v-card class="pa-6">
      <v-card-title class="text-uppercase">
        <v-row class="my-1 justify-space-between align-center">
          <div>{{ this.plan === null ? 'Crear nuevo' : 'Detalle' }} plan</div>
          <v-switch
            v-if="plan !== null"
            v-model="editing"
            label="Editar"
            dense
            hide-details="auto"
          ></v-switch>
        </v-row>
      </v-card-title>
      <hr class="my-3" />
      <v-card-text class="my-0 py-0">
        <v-form ref="newPlanForm" v-model="formValid">
          <v-container class="ma-0 pa-0">
            <v-col class="ma-0 pa-0">
              <v-row class="my-1">
                <v-col cols="6">
                  <v-text-field
                    label="Nombre"
                    v-model="newPlan.name"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    :rules="nameRules"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Orden"
                    v-model="newPlan.order"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" style="display: flex; align-items: center">
                  <v-switch
                    class="mt-0 pt-0"
                    label="Visible"
                    v-model="newPlan.visible"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row class="my-1">
                <v-col cols="3" style="display: flex; align-items: center">
                  <v-switch
                    class="mt-0 pt-0"
                    label="Permite junior"
                    v-model="newPlan.allowsJunior"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-switch>
                </v-col>
                <v-col cols="3" style="display: flex; align-items: center">
                  <v-switch
                    class="mt-0 pt-0"
                    label="Permite senior"
                    v-model="newPlan.allowsSenior"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row class="my-1">
                <v-col cols="12">
                  <v-textarea
                    label="Descripción interna"
                    v-model="newPlan.internalDescription"
                    :disabled="disabled()"
                    required
                    outlined
                    auto-grow
                    rows="1"
                    dense
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="my-1">
                <v-col cols="4">
                  <v-select
                    label="Tipo de plan"
                    v-model="newPlan.type"
                    :items="['b2b', 'b2b2c', 'b2c']"
                    :disabled="disabled()"
                    item-text="name"
                    required
                    outlined
                    dense
                    return-object
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <!--<v-col cols="4">
                  <v-select
                    label="Asegurador"
                    v-model="newPlan.insurer"
                    :items="['Pawer']"
                    :disabled="disabled()"
                    item-text="name"
                    required
                    outlined
                    dense
                    return-object
                    hide-details="auto"
                  ></v-select>
                </v-col>-->
                <v-col cols="4">
                  <v-select
                    label="País"
                    v-model="newPlan.country"
                    :items="['Chile', 'Perú']"
                    :disabled="disabled()"
                    item-text="name"
                    required
                    outlined
                    dense
                    return-object
                    hide-details="auto"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row class="my-1">
                <v-col cols="6">
                  <v-text-field
                    label="Valor Mensual"
                    v-model="newPlan.monthlyPrice"
                    :disabled="disabled()"
                    :rules="priceRules"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Valor Anual"
                    v-model="newPlan.annualPrice"
                    :disabled="disabled()"
                    :rules="priceRules"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              
              <v-row class="my-1">
                <v-col cols="12">
                  <v-text-field
                    label="URL imagen"
                    v-model="newPlan.imageURL"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-1">
                <v-col cols="12">
                  <v-text-field
                    label="URL Template contrato"
                    v-model="newPlan.contractURL"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card-text>
      <hr class="my-3" />
      <v-card-actions class="justify-end">
        <v-btn
          v-if="plan === null || editing"
          :disabled="!formValid"
          color="primary"
          rounded
          @click="validate"
        >
          Guardar
        </v-btn>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="error" :timeout="5000">
      Ocurrió un error creando el plan, intenta más tarde

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false"> Entendido </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Overlay from '../components/Overlay.vue';
import PlansService from '../services/plans.service';

export default {
  components: { Overlay },
  name: 'PlanMaintainer',
  props: ['plan'],
  data() {
    return {
      editing: false,
      loading: false,
      // loadingSuppliersPlans: false,
      error: false,
      newPlan: {},
      formValid: false,
      nameRules: [v => !!v || 'El campo es requerido'],
      priceRules: [
        v => !!v || 'El campo es requerido',
        v => v > 0 || 'El valor debe ser mayor que 0'
      ],
      moneyRules: [
        v => !!v || 'El campo es requerido',
        v =>
          (this.newPlan.currency == 'CLP' && v >= 350) ||
          (this.newPlan.currency == 'UF' && v >= 0.02) ||
          'El mínimo en CLP es de 350 y en UF es 0.02'
      ],
      // suppliersPlans: []
    };
  },
  mounted() {
    this.newPlan = {};
    this.parsePlan(this.plan !== null);
    this.$refs.newPlanForm.resetValidation();
  },
  watch: {
    plan(val) {
      this.newPlan = {};
      this.$refs.newPlanForm.resetValidation();
      this.parsePlan(val !== null);
    }
  },
  methods: {
    disabled() {
      if (this.plan === null) {
        return false;
      } else {
        if (this.editing) {
          return false;
        }
        return true;
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
      this.editing = false;
    },
    parsePlan(exist) {
      // this.loadSuppliersPlans();
      this.newPlan.id = exist ? this.plan.id : null;
      this.newPlan.name = exist ? this.plan.name : '';
      this.newPlan.order = exist ? this.plan.order || 0 : 0;
      this.newPlan.visible = exist ? this.plan.visible : true;
      this.newPlan.allowsJunior = exist ? this.plan.allowsJunior : true;
      this.newPlan.allowsSenior = exist ? this.plan.allowsSenior : true;
      this.newPlan.internalDescription = exist ? this.plan.internalDescription : '';
      this.newPlan.type = exist ? this.plan.type : '';
      this.newPlan.country = exist ? this.plan.country : '';
      this.newPlan.imageURL = exist ? this.plan.imageURL : '';
      this.newPlan.contractURL = exist ? this.plan.contractURL : '';
      this.newPlan.monthlyPrice = exist ? this.plan.monthlyPrice || 0 : 0;
      this.newPlan.annualPrice = exist ? this.plan.annualPrice || 0 : 0;
    },
    async loadSuppliersPlans() {
      this.loadingSuppliersPlans = true;
      const res = await PlansService.getSuppliersPlans(1, 100);
      this.suppliersPlans = res.data.suppliersPlans;
      this.loadingSuppliersPlans = false;
    },
    priceValidation() {
      if (this.newPlan.type === 'b2b' || this.newPlan.type === 'b2b2c') {
        return this.newPlan.monthlyPrice > 0 && this.newPlan.annualPrice > 0;
      }
      return true;
    },
    validate() {
      if (this.$refs.newPlanForm.validate() && this.priceValidation()) {
        if (this.newPlan.supplierPlan) {
          this.newPlan.supplierPlanId = this.newPlan.supplierPlan.id;
        }
        if (this.plan === null) {
          this.createPlan();
        } else if (this.editing) {
          this.updatePlan();
        }
      }
    },
    async createPlan() {
      this.newPlan.supplierPlanId = "11207760-d87f-11ed-8ec7-c3f6aba9573f";
      this.newPlan.countryId = "d1749b17-4f47-4501-af6b-54cfc99507ff";
      this.newPlan.flow = false;
      this.newPlan.description = "";
      this.newPlan.currency = "CLP";
      this.newPlan.monthlyChargesRetries = 0;
      this.newPlan.annualChargesRetries = 0;
      this.newPlan.monthlyWarningDays = 0;
      this.newPlan.annualWarningDays = 0;
      this.newPlan.monthlyTrialDays = 0;
      this.newPlan.annualTrialDays = 0;
      this.newPlan.monthlyGraceDays = 0;
      this.newPlan.annualGraceDays = 0;
      this.newPlan.pawsMonthlyReward = 0;
      this.newPlan.pawsAnnualReward = 0;
      this.newPlan.deletedBy = "";
      console.log(this.newPlan);
      this.loading = true;
      try {
        const res = await PlansService.createPlan(this.newPlan);
        if (res.status === 'ok') {
          this.$refs.newPlanForm.resetValidation();
          this.closeDialog();
        }
      } catch (error) {
        console.log(error);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async updatePlan() {
      const res = await PlansService.updatePlan(this.newPlan.id, this.newPlan);
      if (res.status === 'ok') {
        this.$refs.newPlanForm.resetValidation();
        this.closeDialog();
      }
    }
  }
};
</script>
