import axios from 'axios';

const adminUrl = process.env.VUE_APP_ADMIN_URL;

export default class FileService {
  static async downloadCSVMRRB2C(iso) {
    try {
      console.log(iso);
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrl}/file/mrr-b2c-actual?iso=${iso}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
        responseType: 'blob'
      });
      if (response.status == 200) {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error', error: error };
    }
  }
}
