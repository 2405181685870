<template>
  <v-card class="pa-6">
    <overlay :value="loading"></overlay>
    <v-card-title class="text-uppercase">
      <v-row class="my-1 justify-space-between align-center">
        <div>
          Registrar recompensa
        </div>
      </v-row>
    </v-card-title>
    
    <v-card-text class="my-0 py-0" v-if="redemption && redemption.referrer">
      <v-col class="ma-0 pa-0 mb-4">
        <div style="font-weight: bold; font-size: 1.05rem">Referidor: {{ redemption.referrer.name || '' }} {{ redemption.referrer.surname || '' }}, RUT {{redemption.referrer.rut | rut}}</div>
        <div>
          Referido: {{ redemption.user.name }} {{ redemption.user.surname }}, RUT
          {{ redemption.user.rut | rut }}
        </div>
      </v-col>
    </v-card-text>
    <v-card-text class="my-0 py-0">
    Al presionar "aceptar" se marcará la recompensa de este referido como realizada. Hazlo sólo si ya se completó la recompensa para el referidor. 
    </v-card-text>
    <v-card-actions class="justify-end">
      <div>
        <v-btn :disabled="loading" color="primary" rounded @click="updateRedemption">
          <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
          <span v-else>Aceptar</span>
        </v-btn>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import RedemptionsService from '../services/redemptions.service';
import Overlay from './Overlay';

export default {
  name: 'RewardActivator',
  components: { Overlay },
  props: ['redemption'],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    closeDialog() {
    //   this.policyMessages = [];
    //   this.contractMessages = [];
      this.$emit('close-dialog');
    },
    async updateRedemption() {
      this.loading = true;
      const res = await RedemptionsService.updateRedemption(
        this.redemption.id,
        {
            rewarded: true
        }
      );
      if (res.status == 'ok') {
        this.closeDialog();
        // this.newActivation = {};
        // this.$refs.newActivationForm.resetValidation();
        // this.$router.go();
      }
      this.loading = false;
    }
  }
};
</script>