<template>
  <div>
    <overlay :value="loading"></overlay>
    <v-card class="pa-6">
      <v-card-title class="text-uppercase">
        <v-row class="my-1 justify-space-between align-center">
          <div>{{ this.coupon === null ? 'Crear nuevo' : 'Detalle' }} cupón</div>
          <v-switch
            v-if="coupon !== null"
            v-model="editing"
            label="Editar"
            dense
            hide-details="auto"
          ></v-switch>
        </v-row>
      </v-card-title>
      <hr class="my-3" />
      <v-card-text class="my-0 py-0">
        <v-form ref="newCouponForm" v-model="formValid">
          <v-container class="ma-0 pa-0">
            <v-col class="ma-0 pa-0">
              <h3 class="text-uppercase">Detalle</h3>
              <v-row class="my-1">
                <v-col cols="6">
                  <v-text-field
                    label="Nombre"
                    v-model="newCoupon.name"
                    :disabled="coupon != null"
                    @keyup="autocapitalize"
                    required
                    outlined
                    dense
                    :rules="nameRules"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <div v-if="countries !== null">
                    <v-select
                      label="Pais"
                      v-model="selectedCountry"
                      :items="countries"
                      :disabled="coupon !== null"
                      item-text="name"
                      required
                      outlined
                      dense
                      return-object
                      hide-details="auto"
                    ></v-select>
                  </div>
                </v-col>

                <v-col cols="3" style="display: flex; align-items: center">
                  <v-switch
                    class="mt-0 pt-0"
                    label="Activo"
                    v-model="newCoupon.status"
                    :disabled="formDisabled"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row class="my-1">
                <v-col cols="6">
                  <v-select
                    label="Descuento mensual"
                    v-model="newCoupon.monthlyDiscountType"
                    :disabled="coupon != null"
                    :items="couponTypeOptions"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Descuento anual"
                    v-model="newCoupon.annualDiscountType"
                    :disabled="coupon != null"
                    :items="couponTypeOptions"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row class="my-1" v-if="newCoupon.monthlyDiscountType != null">
                <v-col cols="5" v-if="newCoupon.monthlyDiscountType == 'percentage'">
                  <v-text-field
                    label="Porcentaje mensual"
                    v-model="newCoupon.monthlyPercentOff"
                    :disabled="coupon != null"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" v-if="newCoupon.monthlyDiscountType == 'amount'">
                  <v-text-field
                    label="Monto mensual"
                    v-model="newCoupon.monthlyAmount"
                    :disabled="coupon != null"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" v-if="newCoupon.monthlyDiscountType == 'amount'">
                  <v-select
                    label="Moneda"
                    v-model="newCoupon.monthlyCurrency"
                    :disabled="coupon != null"
                    :items="currencyOptions"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="1" v-if="newCoupon.monthlyDiscountType != null">
                  <v-text-field
                    label="meses"
                    v-model="newCoupon.monthlyTimes"
                    :disabled="coupon != null"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="5" v-if="newCoupon.annualDiscountType == 'percentage'">
                  <v-text-field
                    label="Porcentaje anual"
                    v-model="newCoupon.annualPercentOff"
                    :disabled="coupon != null"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" v-if="newCoupon.annualDiscountType == 'amount'">
                  <v-text-field
                    label="Monto anual"
                    v-model="newCoupon.annualAmount"
                    :disabled="coupon != null"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" v-if="newCoupon.annualDiscountType == 'amount'">
                  <v-select
                    label="Moneda"
                    v-model="newCoupon.annualCurrency"
                    :disabled="coupon != null"
                    :items="currencyOptions"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="1" v-if="newCoupon.annualDiscountType != null">
                  <v-text-field
                    label="años"
                    v-model="newCoupon.annualTimes"
                    :disabled="coupon != null"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="my-1">
                <v-col cols="6">
                  <v-text-field
                    label="Patitas mensuales"
                    v-model="newCoupon.monthlyPawsReward"
                    :disabled="formDisabled"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Patitas anuales"
                    v-model="newCoupon.annualPawsReward"
                    :disabled="formDisabled"
                    required
                    outlined
                    dense
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-1">
                <v-col cols="6">
                  <v-text-field
                    label="FlowID mensual"
                    v-model="newCoupon.monthlyFlowId"
                    disabled
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="FlowID anual"
                    v-model="newCoupon.annualFlowId"
                    disabled
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <hr class="mb-3" />
              <h3 class="text-uppercase">Restricciones</h3>
              <h5 class="text-uppercase">Ninguno de estos datos son obligatorios</h5>
              <v-row class="my-1">
                <v-col cols="6">
                  <v-text-field
                    label="Canjes máximos histórico"
                    v-model="newCoupon.maxRedemptions"
                    :disabled="formDisabled"
                    required
                    outlined
                    dense
                    clearable
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Canjes máximos por usuario"
                    v-model="newCoupon.userMaxRedemptions"
                    :disabled="formDisabled"
                    required
                    outlined
                    dense
                    clearable
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-1">
                <v-col class="my-0 py-0" cols="6">
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDateText"
                        label="Fecha de inicio"
                        :disabled="formDisabled"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" @input="onStartDateChange"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="my-0 py-0" cols="6">
                  <v-menu
                    ref="expireDateMenu"
                    v-model="expireDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="expireDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="expireDateText"
                        label="Fecha de expiración"
                        :disabled="formDisabled"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="expireDate" @input="onExpireDateChange">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card-text>

      <!-- ! new -->
      <v-card-text>
        <div v-if="coupon !== null">
          <div v-if="coupon.redemptions.length > 0">
            <hr class="mb-3" />
            <h3 class="text-uppercase">Usos</h3>
            <h5 class="text-uppercase">Usado por las siguientes membresias:</h5>

            <v-data-table
              :headers="usesHeaders"
              :items="coupon.redemptions"
              class="elevation-1"
              :hide-default-footer="true"
              :items-per-page="-1"
            >
              <template v-slot:item.pet="{ item }">
                <span v-if="item.membership.pet">{{ item.membership.pet.name }}</span>
              </template>
              <template v-slot:item.plan="{ item }">
                <span
                  >{{ item.membership.plan.name }}
                  {{ item.membership.paymentInterval === 1 ? '(Anual)' : '(Mensual)' }}</span
                >
              </template>
              <template v-slot:item.price="{ item }">
                <span v-if="item.membership.paymentInterval === 1">
                  {{ item.membership.plan.annualPrice | clpCurrency }}
                </span>
                <span v-else>
                  {{ item.membership.plan.monthlyPrice | clpCurrency }}
                </span>
              </template>
              <template v-slot:item.status="{ item }">
                <span v-if="item.membership.status === 2">
                  Activo
                </span>
                <span v-if="item.membership.status === 3">
                  Pago rechazado
                </span>
                <span v-if="item.membership.status === 4">
                  Desactivada
                </span>
                <span
                  v-if="
                    item.membership.status === 0 ||
                      item.membership.status === 1 ||
                      item.membership.status === 5
                  "
                >
                  Pendiente
                </span>
              </template>
              <template v-slot:item.createdAt="{ item }">
                <span>{{ item.createdAt | date }}</span>
              </template>
              <template v-slot:item.user="{ item }">
                <span v-if="item.user != null">
                  <span>{{ item.user.name }} {{ item.user.surname }}</span>
                </span>
                <span v-else>-</span>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card-text>
      <!-- ! new -->
      <hr class="my-3" />
      <v-card-actions class="justify-end">
        <v-btn
          v-if="coupon === null || editing"
          :disabled="!formValid"
          color="primary"
          rounded
          @click="validate"
        >
          Guardar
        </v-btn>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="error" :timeout="5000">
      Ocurrió un error creando el coupon, intenta más tarde

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false"> Entendido </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from 'moment';
import Overlay from '../components/Overlay.vue';
import CouponsService from '../services/coupons.service';

export default {
  components: { Overlay },
  name: 'CouponMaintainer',
  props: ['coupon', 'countries'],
  data() {
    return {
      editing: false,
      loading: false,
      startDate: null,
      startDateMenu: false,
      expireDate: null,
      expireDateMenu: false,
      showDiscount: false,
      error: false,
      newCoupon: {
        name: null
      },
      formValid: false,

      currencyOptions: ['CLP', 'UF'],
      couponTypeOptions: [
        { id: 'percentage', name: 'Porcentaje' },
        { id: 'amount', name: 'Monto' }
      ],
      nameRules: [v => !!v || 'El campo es requerido'],
      moneyRules: [
        v => !!v || 'El campo es requerido',
        v =>
          (this.newCoupon.currency == 'CLP' && v >= 350) ||
          (this.newCoupon.currency == 'UF' && v >= 0.02) ||
          'El mínimo en CLP es de 350 y en UF es 0.02'
      ],
      suppliersCoupons: [],
      selectedCountry: this.countries !== null ? this.countries[0] : null,
      usesHeaders: [
        {
          text: 'Mascota',
          value: 'pet',
          sortable: false
        },
        {
          text: 'Plan',
          value: 'plan',
          sortable: false
        },
        {
          text: 'Precio plan',
          value: 'price',
          sortable: false
        },
        {
          text: 'Estado de la membresía',
          value: 'status',
          sortable: false
        },
        {
          text: 'Usado:',
          value: 'createdAt',
          sortable: false
        },
        {
          text: 'Dueño',
          value: 'user',
          sortable: false
        }
      ]
    };
  },
  computed: {
    startDateText() {
      if (this.newCoupon.startDate == null && this.startDate == null) {
        return null;
      }
      return moment(this.newCoupon.startDate || this.startDate).format('DD/MM/YYYY');
    },
    expireDateText() {
      if (this.newCoupon.expireDate == null && this.expireDate == null) {
        return null;
      }
      return moment(this.newCoupon.expireDate || this.expireDate).format('DD/MM/YYYY');
    },
    formDisabled() {
      if (this.coupon === null) {
        return false;
      } else {
        if (this.editing) {
          return false;
        }
        return true;
      }
    }
  },
  mounted() {
    this.parseCoupon(this.coupon);
    this.$refs.newCouponForm.resetValidation();
  },
  watch: {
    coupon(val) {
      this.parseCoupon(val);
      this.$refs.newCouponForm.resetValidation();
    }
  },
  methods: {
    autocapitalize(event) {
      this.newCoupon.name = event.target.value.toUpperCase();
    },
    onStartDateChange() {
      this.startDateMenu = false;
      this.newCoupon.startDate = moment(this.startDate).toISOString();
    },
    onExpireDateChange() {
      this.expireDateMenu = false;
      this.newCoupon.expireDate = moment(this.expireDate).toISOString();
    },

    closeDialog() {
      this.$emit('close-dialog');
      this.editing = false;
    },
    parseCoupon(coupon) {
      this.newCoupon = {};

      if (coupon) {
        this.newCoupon = { ...coupon };
        this.newCoupon.monthlyDiscountType =
          this.newCoupon.monthlyPercentOff != null
            ? 'percentage'
            : this.coupon.monthlyAmount != null
            ? 'amount'
            : null;
        this.newCoupon.annualDiscountType =
          this.coupon.annualPercentOff != null
            ? 'percentage'
            : this.coupon.annualAmount != null
            ? 'amount'
            : null;

        if (this.countries !== null) {
          this.selectedCountry = this.countries.find(c => c.id === this.newCoupon.countryId);
        }
      } else {
        this.newCoupon.status = true;
        this.newCoupon.annualTimes = 1;
        this.newCoupon.monthlyTimes = 1;
        this.newCoupon.monthlyPawsReward = 0;
        this.newCoupon.annualPawsReward = 0;
      }
    },
    validate() {
      if (this.$refs.newCouponForm.validate()) {
        if (this.coupon === null) {
          this.createCoupon();
        } else if (this.editing) {
          this.updateCoupon();
        }
      }
    },
    async createCoupon() {
      this.loading = true;
      try {
        const couponData = {
          name: this.newCoupon.name,
          monthlyPawsReward: this.newCoupon.monthlyPawsReward,
          annualPawsReward: this.newCoupon.annualPawsReward,
          maxRedemptions: this.newCoupon.maxRedemptions,
          userMaxRedemptions: this.newCoupon.userMaxRedemptions,
          startDate: this.newCoupon.startDate,
          expireDate: this.newCoupon.expireDate,
          status: this.newCoupon.status == true ? 1 : 2,
          countryId: this.selectedCountry.id
        };
        if (this.newCoupon.monthlyDiscountType != null) {
          couponData.monthlyTimes = this.newCoupon.monthlyTimes;
          if (this.newCoupon.monthlyDiscountType == 'amount') {
            couponData.monthlyCurrency = this.newCoupon.monthlyCurrency;
            couponData.monthlyAmount = this.newCoupon.monthlyAmount;
          } else {
            couponData.monthlyPercentOff = this.newCoupon.monthlyPercentOff;
          }
        }
        if (this.newCoupon.annualDiscountType != null) {
          couponData.annualTimes = this.newCoupon.annualTimes;
          if (this.newCoupon.annualDiscountType == 'amount') {
            couponData.annualCurrency = this.newCoupon.annualCurrency;
            couponData.annualAmount = this.newCoupon.annualAmount;
          } else {
            couponData.annualPercentOff = this.newCoupon.annualPercentOff;
          }
        }
        // console.log(couponData);
        const res = await CouponsService.createCoupon(couponData);
        if (res.status === 'ok') {
          this.$refs.newCouponForm.resetValidation();
          this.closeDialog();
        }
      } catch (error) {
        console.log(error);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async updateCoupon() {
      this.loading = true;
      try {
        const couponData = {
          monthlyPawsReward: this.newCoupon.monthlyPawsReward,
          annualPawsReward: this.newCoupon.annualPawsReward,
          maxRedemptions: this.newCoupon.maxRedemptions,
          userMaxRedemptions: this.newCoupon.userMaxRedemptions,
          startDate: this.newCoupon.startDate,
          expireDate: this.newCoupon.expireDate,
          status: this.newCoupon.status == true ? 1 : 2
        };
        const res = await CouponsService.updateCoupon(this.newCoupon.id, couponData);
        if (res.status === 'ok') {
          this.$refs.newCouponForm.resetValidation();
          this.closeDialog();
        }
      } catch (error) {
        console.log(error);
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
