<template>
  <div>
    <overlay :value="loading"></overlay>
    <v-card class="pa-6">
      <v-card-title class="text-uppercase">
        <v-row class="my-1 justify-space-between align-center">
          <div>Detalle de mascota</div>
          <v-switch
            class="mt-0"
            v-if="pet !== null"
            v-model="editing"
            label="Editar"
            dense
            hide-details="auto"
          ></v-switch>
        </v-row>
      </v-card-title>
      <hr class="my-3" />
      <v-card-text class="my-0 py-0">
        <v-form ref="petForm" v-model="formValid">
          <v-container class="ma-0 pa-0">
            <v-col class="ma-0 pa-0">
              <v-row class="my-1">
                <v-col cols="6">
                  <v-text-field
                    label="Nombre"
                    v-model="petData.name"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    :rules="nameRules"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                    label="Especie"
                    v-model="petData.species"
                    :items="speciesOptions"
                    :disabled="disabled()"
                    item-text="label"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    label="Sexo"
                    v-model="petData.sex"
                    :items="sexOptions"
                    :disabled="disabled()"
                    item-text="label"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="my-1">
                <v-col cols="4">
                  <v-menu
                    ref="birthdateMenu"
                    v-model="birthdateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="petData.birthdate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="petBirthdateText"
                        label="Fecha de nacimiento"
                        :disabled="disabled()"
                        readonly
                        outlined
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="petData.birthdate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="birthdateMenu = false"> Cancelar </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.birthdateMenu.save(petData.birthdate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    label="Raza"
                    v-model="petData.breed"
                    :items="breeds"
                    value="id"
                    :disabled="disabled()"
                    item-text="name"
                    required
                    outlined
                    dense
                    return-object
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="N° microchip"
                    v-model="petData.microchip"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-1">
                <v-col cols="3">
                  <v-checkbox
                    label="Tiene microchip"
                    v-model="petData.hasMicrochip"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    label="Es de raza"
                    v-model="petData.isPurebred"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    label="Visible"
                    v-model="petData.visible"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card-text>
      <hr class="my-3" />
      <v-card-actions class="justify-end">
        <v-btn
          v-if="pet === null || editing"
          :disabled="!formValid"
          color="primary"
          rounded
          @click="validate"
        >
          Guardar
        </v-btn>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="error" :timeout="5000">
      Ocurrió un error al guardar la mascota
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false"> Entendido </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Overlay from '../components/Overlay.vue';
import PetsService from '../services/pets.service';
import moment from 'moment';

export default {
  components: { Overlay },
  name: 'PetMaintainer',
  props: ['pet'],
  data() {
    return {
      editing: false,
      loading: false,
      loadingBreeds: false,
      error: false,
      petData: {},
      formValid: false,
      birthdateMenu: false,
      breeds: [],
      sexOptions: [
        { label: 'Hembra', value: 'female' },
        { label: 'Macho', value: 'male' }
      ],
      speciesOptions: [
        { label: 'Gato', value: 'cat' },
        { label: 'Perro', value: 'dog' }
      ],
      nameRules: [(v) => !!v || 'El campo es requerido']
    };
  },
  beforeMount() {
    this.loadPetData();
  },
  mounted() {
    this.$refs.petForm.resetValidation();
  },
  watch: {
    pet() {
      this.loadPetData();
      this.$refs.petForm.resetValidation();
    },
    species() {
      this.getBreeds(this.petData.species);
    },
    breed() {
      this.petData.breedId = this.petData.breed ? this.petData.breed.id : null;
    }
  },
  computed: {
    petBirthdateText() {
      return moment(this.petData.birthdate).format('DD/MM/YYYY');
    },
    species() {
      return this.petData.species;
    },
    breed() {
      return this.petData.breed;
    }
  },
  methods: {
    loadPetData() {
      this.petData = this.pet ?? this.petData;
      this.petData.birthdate = moment
        .utc(this.petData.birthdate ?? new Date())
        .format('YYYY-MM-DD');
    },
    disabled() {
      if (this.pet === null) {
        return false;
      } else {
        if (this.editing) {
          return false;
        }
        return true;
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
      this.editing = false;
    },
    validate() {
      if (this.$refs.petForm.validate()) {
        if (this.editing) {
          this.updatePet();
        }
      }
    },
    async updatePet() {
      try {
        const res = await PetsService.updatePet(this.petData.id, this.petData);
        if (res.status === 'ok') {
          this.$refs.petForm.resetValidation();
          this.closeDialog();
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
      }
    },
    async getBreeds() {
      this.loadingBreeds = true;
      const breeds = await PetsService.getBreeds(this.petData.species);
      this.breeds = breeds.data;
      this.loadingBreeds = false;
    }
  }
};
</script>
