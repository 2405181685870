<template>
  <div>
    <v-row class="my-4 align-center justify-space-between px-4 mx-2">
      <h1 class="text-h5 font-weight-bold">Registro de Reembolso</h1>
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-container class="ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end">
          <v-btn color="green" depressed @click="refresh()">
            <v-icon color="white"> mdi-refresh </v-icon>
            <span class="white--text font-weight-semibold">Actualizar</span>
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
    <v-container class="mx-2">
      <v-col cols="12" md="6" class="px-4">
        <v-row>
          <v-text-field
            v-model="search"
            label="Buscar"
            placeholder="Correo o RUT..."
            dense
            hide-details
            outlined
            append-icon="mdi-magnify"
            @click:append="searchUser"
            @keyup.enter="searchUser"
          ></v-text-field>
        </v-row>
        <v-row class="py-2">
          <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
        </v-row>
      </v-col>
    </v-container>
    <v-container v-if="user" class="mx-4 px-4">
      <v-row class="my-2 px-4 py-2 justify-space-between white rounded">
        <v-col cols="6" md="6" class="px-0">
          <h4>Datos de usuario</h4>
          <div>
            <p class="my-1 font-weight-medium">
              Nombre: <span class="font-weight-regular">{{ user.name }}</span>
            </p>
            <p class="my-1 font-weight-medium">
              Rut: <span class="font-weight-regular">{{ user.rut }}</span>
            </p>
            <p class="my-1 font-weight-medium">
              Email: <span class="font-weight-regular">{{ user.email }}</span>
            </p>
            <p class="my-1 font-weight-medium">
              Tel: <span class="font-weight-regular">{{ user.phone }}</span>
            </p>
          </div>
        </v-col>
        <v-col cols="6" md="6" class="px-0">
          <h4>Datos Bancarios</h4>
          <div v-if="user.dataBank != null">
            <p class="my-1">Banco: {{ user.dataBank.bank.name }}</p>
            <p class="my-1">Tipo Cuenta: {{ user.dataBank.accountType }}</p>
            <p class="my-1">Número cuenta: {{ user.dataBank.accountNumber }}</p>
          </div>
          <v-btn class="my-2" v-else color="green" depressed @click="refresh()">
            <v-icon color="white"> mdi-bank </v-icon>
            <span class="white--text font-weight-semibold">Agregar datos</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="user" class="my-4 white rounded px-4 py-4">
        <v-col cols="12" md="12" class="px-0">
          <h4>Membresías</h4>
          <div v-if="user.memberships.length > 0" class="my-4 px-2">
            <v-radio-group v-model="membershipSelected">
              <div v-for="membership in user.memberships" :key="membership.id" class="my-4">
                <v-row cols="12" md="12" class="">
                  <v-radio :label="membership.name" :value="membership" class="mx-2 my-0"></v-radio>
                  <v-icon left class="teal--text">
                    {{ membership.species === 'cat' ? 'mdi-cat' : 'mdi-dog' }}</v-icon
                  >
                  <membership-status-chip :status="membership.status" />
                  <plan-status-chip :planName="membership.planName" />
                </v-row>
              </div>
            </v-radio-group>
          </div>
          <div v-else class="">
            <p>No hay membresias para mostrar</p>
          </div>
        </v-col>
      </v-row>
      <div v-if="membershipSelected.name != ''">
        <v-row class="my-4 white rounded px-4 py-4">
          <v-col cols="12" md="3" class="px-0">
            <h4>Servicios de {{ membershipSelected.name }} para reembolsar</h4>
            <div v-if="membershipSelected.servicesToRefund.length > 0" class="my-4">
              <v-radio-group v-model="selectedService">
                <div
                  v-for="services in membershipSelected.servicesToRefund"
                  :key="services.id"
                  class="my-2"
                >
                  <v-radio :label="services.servicesName" :value="services"></v-radio>
                </div>
              </v-radio-group>
            </div>
            <div v-else class="">
              <p>No hay servicios para mostrar</p>
            </div>
          </v-col>
        </v-row>
        <v-row class="my-4 white rounded px-4 py-4">
          <v-col cols="12" md="3" class="px-0">
            <h4>Fecha de solicitud de reembolso</h4>
            <v-date-picker
              class="py-2 my-2"
              v-model="selectedDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
            </v-date-picker>
          </v-col>
        </v-row>
        <v-row v-if="selectedService" class="my-4 white rounded px-4 py-4">
          <v-col cols="12" md="12" class="px-0">
            <h4 class="my-4">Adjuntos</h4>
            <v-col col="3" md="4" class="px-0 my-0 py-0">
              <v-file-input
                show-size
                outlined
                label="Detalle de prestación"
                v-model="benefitDetail"
              ></v-file-input>
            </v-col>
            <v-col col="3" md="4" class="px-0 my-0 py-0">
              <v-file-input
                show-size
                outlined
                label="Comprobante de pago"
                v-model="proofOfPayment"
              ></v-file-input>
            </v-col>
            <v-col
              v-if="selectedService.servicesName == 'Vacunas Obligatorias'"
              col="3"
              md="4"
              class="px-0 my-0 py-0"
            >
              <v-file-input
                show-size
                outlined
                label="Calendario de vacunación"
                v-model="vaccinationScheduleFile"
              ></v-file-input>
            </v-col>
            <v-col
              v-if="selectedService.servicesName == 'Seguro contra accidentes'"
              col="3"
              md="4"
              class="px-0 my-0 py-0"
            >
              <v-file-input
                show-size
                outlined
                label="Declaración jurada"
                v-model="swornDeclarationFile"
              ></v-file-input>
            </v-col>
            <v-col
              v-if="selectedService.servicesName == 'Seguro contra accidentes'"
              col="3"
              md="4"
              class="px-0 my-0 py-0"
            >
              <v-file-input
                show-size
                outlined
                label="Diagnóstico/Pre-diagnóstico"
                v-model="diagnosisFile"
              ></v-file-input>
            </v-col>
          </v-col>
        </v-row>
        <v-row v-if="selectedService != ''" class="my-4 white rounded px-4 py-4">
          <v-col cols="12" md="12" class="px-0">
            <h4>Datos de reembolso</h4>
            <v-radio-group v-model="refundStatus" inline>
              <v-radio label="Registrado" value="0"></v-radio>
              <v-radio label="Rechazado" value="300"></v-radio>
              <v-radio label="Aprobado" value="400"></v-radio>
            </v-radio-group>
            <v-col v-if="refundStatus == '400'" cols="4" class="px-0">
              <v-text-field
                label="Ingrese monto de reembolso"
                model-value="10.000"
                prefix="$"
                outlined
                class="px-2"
                v-model="approvedAmount"
              ></v-text-field>
            </v-col>
            <v-col v-if="refundStatus == '400'" cols="4" class="px-0">
              <v-text-field
                label="Ingrese monto de reembolso solicitado"
                model-value="10.000"
                prefix="$"
                outlined
                class="px-2"
                v-model="requestedAmmount"
              ></v-text-field>
            </v-col>
            <v-col v-if="refundStatus == '300'" cols="4" class="px-0">
              <v-textarea
                label="Comentarios"
                variant="outlined"
                outlined
                class="px-2"
                v-model="comments"
              ></v-textarea>
            </v-col>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-container class="mx-2 px-4" v-if="selectedService">
      <v-btn class="my-2" color="green" depressed @click="uploadRefund()">
        <v-icon color="white"> mdi-upload </v-icon>
        <span class="white--text font-weight-semibold">Agregar reembolso</span>
      </v-btn>
    </v-container>
    <v-snackbar v-model="snackbar" multi-line :timeout="2000" :color="snackbarColor">
      {{ snackbarMessage }}

      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import UsersService from '../services/users.service';
import MembershipStatusChip from '../components/MembershipStatusChip';
import PlanStatusChip from '../components/PlanStatusChip';
import RefundsServices from '../services/refunds.service';
import FileUtils from '../utils/file.utils';

export default {
  components: {
    MembershipStatusChip,
    PlanStatusChip
  },
  data() {
    return {
      search: null,
      loading: false,
      memberships: [],
      selectedService: null,
      refundStatus: '',
      foundedUsers: [],
      membershipSelected: { name: '' },
      user: null,
      approvedAmount: 0,
      requestedAmmount: 0,
      attachments: [],
      vaccinationScheduleFile: null,
      swornDeclarationFile: null,
      diagnosisFile: null,
      benefitDetail: null,
      proofOfPayment: null,
      comments: '',
      selectedDate: null,
      snackbarMessage: '',
      snackbar: false,
      snackbarColor: ''
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.getRefunds(this.page, this.limit);
      },
      deep: true
    },
    search: {
      handler() {
        this.page = 1;
        this.limit = 10;
        this.sort = [];
      },
      deep: true
    }
  },
  methods: {
    refresh() {},
    async uploadRefund() {
      try {
        //this attachmentTypes are static because is not the best solution find them using a name, so i prefer use the real value
        //solve in other version
        let files = [];
        if (this.vaccinationScheduleFile) {
          const content = {
            id: '7fb8c68b-4a0c-4590-a71c-e0ab256b8363',
            base64Image: await FileUtils.getBase64(this.vaccinationScheduleFile),
            fileName: this.vaccinationScheduleFile.name
          };
          files.push(content);
        }
        if (this.swornDeclarationFile) {
          const content = {
            id: 'de338fc2-f1a4-4d8e-b935-869c418d3e2f',
            base64Image: await FileUtils.getBase64(this.swornDeclarationFile),
            fileName: this.swornDeclarationFile.name
          };
          files.push(content);
        }
        if (this.diagnosisFile) {
          const content = {
            id: '37f6b77b-cb35-42b0-97c4-a6aa401c43f4',
            base64Image: await FileUtils.getBase64(this.diagnosisFile),
            fileName: this.diagnosisFile.name
          };
          files.push(content);
        }
        if (this.proofOfPayment) {
          const content = {
            id: 'adb115b4-93d7-4eee-80da-162dd7204450',
            base64Image: await FileUtils.getBase64(this.proofOfPayment),
            fileName: this.proofOfPayment.name
          };
          files.push(content);
        }
        if (this.benefitDetail) {
          const content = {
            id: '0418038b-724c-42b2-9363-bc3769ba30fd',
            base64Image: await FileUtils.getBase64(this.benefitDetail),
            fileName: this.benefitDetail.name
          };
          files.push(content);
        }
        console.log(this.membershipSelected);
        console.log(this.selectedService);
        const refund = {
          userId: this.user.id,
          petId: this.membershipSelected.petId,
          serviceId: this.selectedService.id,
          //userBankDetailsId: this.user.dataBank.id,
          approvedAmount: this.approvedAmount,
          requestedAmmount: this.requestedAmmount,
          comments: this.comments,
          draft: false,
          attachments: [], //files,
          createdAt: this.selectedDate,
          updatedAt: this.selectedDate,
          status: parseInt(this.refundStatus)
        };
        console.log(refund);
        const res = await RefundsServices.createRefund(refund);
        if (res.status === 'ok') {
          this.snackbar = true;
          this.snackbarColor = 'green';
          this.snackbarMessage = 'El reembolso fue creado con éxito';
        } else {
          this.snackbar = true;
          this.snackbarColor = 'red';
          this.snackbarMessage = 'El reembolso no pudo ser creado';
        }
      } catch (err) {
        this.snackbar = true;
        this.snackbarColor = 'red';
        this.snackbarMessage = 'El reembolso no pudo ser creado';
      }
    },
    async getUsers(page, limit) {
      this.loadingData = true;
      const res = await UsersService.getUsers(
        page,
        limit,
        this.stringToSearch && this.stringToSearch.length >= 3 ? this.stringToSearch : null
      );
      if (res.status === 'ok') {
        this.users = res.data.users;
        this.totalItems = res.data.totalItems;
      }
      this.loadingData = false;
    },
    async searchUser() {
      try {
        //this.clearAll();
        this.loading = true;
        this.noDataMsg = null;
        const res = await UsersService.getUsersBySearchToRefund(this.search);
        this.loading = false;
        this.user = res.data.user;
        console.log(res.data.user);
      } catch (error) {
        this.loading = false;
        this.noDataMsg = 'Algo salió mal, inténtalo más tarde';
        console.log(error);
      }
    }
  }
};
</script>
