import axios from 'axios';

const backendUrlv2 = process.env.VUE_APP_BACKEND_URL_V2;

export default class ServicesRecords {
  static async getAll(query) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    let page = query.page ?? 1;
    let limit = query.limit ?? 10;

    let url = `${backendUrlv2}/servicesrecords?page=${page}&limit=${limit}`;

    if (query.userId != null && query.userId.length != 0) {
      url = url + `&userId=${query.userId}`;
    }
    if (query.membershipId != null && query.membershipId.length != 0) {
      url = url + `&membershipId=${query.membershipId}`;
    }
    if (query.servicePlanId != null && query.servicePlanId.length != 0) {
      url = url + `&servicePlanId=${query.servicePlanId}`;
    }
    if (query.isolatedServiceId != null && query.isolatedServiceId.length != 0) {
      url = url + `&isolatedServiceId=${query.isolatedServiceId}`;
    }
    if (query.refundable != null && query.refundable.length != 0) {
      url = url + `&refundable=${query.refundable}`;
    }
    if (query.userSpends != null && query.userSpends.length != 0) {
      url = url + `&userSpends=${query.userSpends}`;
      if (query.userSpends === true) {
        if (query.spendsFrom != null && query.spendsFrom.length != 0) {
          url = url + `&spendsFrom=${query.spendsFrom}`;
        }
        if (query.spendsTo != null && query.spendsTo.length != 0) {
          url = url + `&spendsTo=${query.spendsTo}`;
        }
      }
    }

    if (query.search != null && query.search.length != 0) {
      url = url + `&search=${query.search}`;
    }
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });

    if (response.data.status === 'ok') {
      return { status: 'ok', data: response.data.data };
    } else {
      throw new Error(response.data.message);
    }
  }

  static async create(serviceRecord) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    let url = `${backendUrlv2}/servicesrecords`;

    const response = await axios.post(url, serviceRecord, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (response.data.status === 'ok') {
      return { status: 'ok', data: response.data.data };
    } else {
      throw new Error(response.data.message);
    }
  }
  static async delete(serviceRecordId) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    let url = `${backendUrlv2}/servicesrecords/${serviceRecordId}`;

    const response = await axios.delete(url, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (response.data.status === 'ok') {
      return { status: 'ok', data: response.data.data };
    } else {
      throw new Error(response.data.message);
    }
  }
}
