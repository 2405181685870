<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Solicitudes de Reembolso</h1>

      <v-col cols="12" md="6" class="my-0 py-0">
        <v-container class="ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end">
          <v-btn color="green" depressed @click="refresh()">
            <v-icon color="white"> mdi-refresh </v-icon>
            <span class="white--text font-weight-semibold">Actualizar</span>
          </v-btn>
        </v-container>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="refundsRequests"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      :disable-sort="loadingData"
      :options.sync="options"
      multi-sort
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50, 500, 999]
      }"
    >
      <template v-slot:item.user="{ item }">
        <span>{{ item.user ? item.user.name : '' }} {{ item.user ? item.user.surname : '' }}</span>
      </template>

      <template v-slot:item.pet="{ item }">
        <v-col class="ma-0 pa-0 mt-1">
          <div>
            <span>{{ item.pet ? item.pet.name : '-' }} </span>
          </div>
        </v-col>
      </template>

      <template v-slot:item.membership="{ item }">
        <v-col class="ma-0 pa-0 mt-1" style="max-width: 200px;">
          <span v-if="item.petId !=null">{{ item.pet.membership.ref ? item.pet.membership.ref  : '-' }} </span>
          <span v-else>-</span>
        </v-col>
      </template>

      <template v-slot:item.service="{ item }">
        <v-col class="ma-0 pa-0 mt-1" style="max-width: 200px;">
          <span>{{ item.service ? item.service.title : '-' }} </span>
        </v-col>
      </template>



      <!-- <template v-slot:item.userBankDetails="{ item }">
        <bank-modal v-if="item.userBankDetails != null" :data="item"></bank-modal>

        <div v-else>
          <span class="red--text font-weight-semibold">Sin datos</span>
        </div>
      </template> -->
      <template v-slot:item.updated="{ item }">
        <div >
          <span class="black--text font-weight-semibold">
            {{ new Date(item.updatedAt).toLocaleDateString() }}
          </span>
        </div>
      </template>

      <template v-slot:item.attachments="{ item }">
        <template v-if="item.attachments == null || item.attachments.length > 0">
          <div class="d-flex flex-wrap justify-center">
            <v-tooltip v-for="attachment in item.attachments" :key="attachment.id" top>
              <template v-if="attachment.fileUrl != null" v-slot:activator="{ on, attrs }">
                <div v-if="attachment.fileUrl != null">
                  <v-btn
                    class="mx-1"
                    depressed
                    x-small
                    fab
                    color="#81C784"
                    v-bind="attrs"
                    v-on="on"
                    :href="attachment.fileUrl"
                    target="_blank"
                  >
                    <v-icon small color="white">mdi-eye-outline</v-icon>
                  </v-btn>
                </div>
                <div v-else>
                  <v-icon small color="red">mdi-file-document-alert-outline</v-icon>
                </div>
              </template>

              <span>{{ attachment.attachmentType ? attachment.attachmentType.name : '' }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-else>
          <span class="red--text font-weight-semibold">Sin adjuntos</span>
        </template>
      </template>

      <template v-slot:item.changelogs="{ item }">
        <div v-if="item.changelogs !== null || item.changelogs.length > 0">
          <v-chip small outlined :color="item.changelogs[0].status.highlightColor">{{
            item.changelogs[0].status.name
          }}</v-chip>
        </div>
        <div v-else>
          <v-chip x-small outlined color="red" class="white--text font-weight-bold"
            >Sin información</v-chip
          >
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div v-if="item.changelogs !== null || item.changelogs.length > 0">
          <!-- <v-tooltip v-for="status in statuses" :key="status.value" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                depressed
                x-small
                fab
                :color="status.color"
                v-bind="attrs"
                v-on="on"
                @click="updateChangelog(status, item)"
              >
                <v-icon small color="white">{{ status.icon }}</v-icon>
              </v-btn>
            </template>
            <span :color="status.color">{{ status.text }}</span>
          </v-tooltip> -->

          <refund-mainteiner :data="item" :refreshParent="refresh"></refund-mainteiner>
        </div>
        <div v-else>
          Sin data
        </div>
      </template>
    </v-data-table>
    <overlay :value="loadingOverlay"></overlay>
  </div>
</template>

<script>
import RefundServices from '../services/refunds.service';
// import BankModal from '../components/BankModal';
import RefundMainteiner from '../components/RefundMainteiner';

import Overlay from '../components/Overlay.vue';

export default {
  components: {
    // BankModal,
    RefundMainteiner,
    Overlay
  },
  data() {
    return {
      updateChangelogError: false,
      updateChangelogErrorMsg: '',
      updateChangelogOk: false,
      updateChangelogOkMsg: '',
      loadingData: true,
      loadingOverlay: false,
      confirmDialog: false,
      selectedItem: null,
      limit: 10,
      page: 1,
      totalItems: 0,
      sort: [],
      options: {},
      statuses: [
        {
          text: 'En revisión',
          value: 100,
          color: '#CC7A00',
          icon: 'mdi-clock-time-nine-outline'
        },
        {
          text: 'Corregir',
          value: 200,
          color: '#5E35B1',
          icon: 'mdi-account-edit'
        },
        {
          text: 'Rechazar',
          value: 300,
          color: 'red',
          icon: 'mdi-close-thick'
        },
        {
          text: 'Aprobar',
          value: 400,
          color: 'green',
          icon: 'mdi-check-bold'
        }
      ],

      headers: [
        {
          text: 'Usuario',
          align: 'start',
          sortable: false,
          value: 'user'
        },
        { text: 'Mascota', sortable: false, value: 'pet' },
        { text: 'Ref', sortable: false, value: 'membership' },

        { text: 'Servicio', align: 'center', sortable: false, value: 'service' },
        { text: 'Actualizada: ', align: 'center', sortable: false, value: 'updated' },

        // {
        //   text: 'Banco',
        //   align: 'center',
        //   justify: 'center',
        //   sortable: false,
        //   value: 'userBankDetails'
        // },
        { text: 'Comprobantes', align: 'center', sortable: false, value: 'attachments' },
        { text: 'Estado', align: 'center', sortable: false, value: 'changelogs' },
        { text: 'Acciones', align: 'center', sortable: false, value: 'actions' }
      ],
      refundsRequests: [],
      userId: null,
      petId: null,
      serviceId: null
    };
  },
  mounted() {},
  created() {
    this.getRefunds(this.page, this.limit, this.search, this.sort);
    this.getChangelogStatuses();
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.getRefunds(this.page, this.limit);
      },
      deep: true
    },

    search: {
      handler() {
        this.page = 1;
        this.limit = 10;
        this.sort = [];
      },
      deep: true
    }
  },
  methods: {
    refresh() {
      this.updateChangelogOk = false;
      this.updateChangelogOkMsg = '';

      this.getRefunds(this.page, this.limit, null, this.sort);
    },

    async getRefunds(page, limit, query, sort) {
      this.loadingData = true;
      this.loadingOverlay = true;
      const res = await RefundServices.getAllRefunds(
        page,
        limit,
        query,
        sort,
        this.userId,
        this.stringToSearch && this.stringToSearch.length >= 3 ? this.stringToSearch : null,
        this.serviceId,
        this.petId
      );
      if (res.status === 'ok') {
        console.log(res);
        this.refundsRequests = res.data.result;
        this.totalItems = res.data.totalItems;
        console.log('this.refunds', this.refundsRequests);
      }
      this.loadingData = false;
      this.loadingOverlay = false;
    },
    async getChangelogStatuses() {
      this.loadingData = true;
      const res = await RefundServices.getChangelogStatuses();
      if (res.status === 'ok') {
        console.log('db changelog statuses: ', res);
      }
      this.loadingData = false;
    }
  }
};
</script>
