<template>
  <v-card class="pa-6">
    <v-card-title class="text-uppercase">
      <div>Mascotas de {{ user != null ? `${user.name} ${user.surname}` : '' }}</div>
    </v-card-title>
    <hr class="my-3" />
    <!-- <v-card-text class="my-0 py-0"> -->
    <!-- <p>¿Está seguro de que desea eliminar al usuario {{ userData }}?</p> -->
    <v-data-table
      :headers="headers"
      :items="pets"
      :loading="loading"
      class="elevation-0"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:item.species="{ item }">
        {{ item.species == 'cat' ? 'Gato' : 'Perro' }}
      </template>
      <template v-slot:item.age="{ item }">
        {{ getAge(item.birthdate) }}
      </template>
      <template v-slot:item.sex="{ item }">
        {{ item.sex == 'female' ? 'Hembra' : 'Macho' }}
      </template>
      <template v-slot:item.visible="{ item }">
        <v-checkbox v-model="item.visible"></v-checkbox></template
    ></v-data-table>
    <!-- </v-card-text> -->
    <hr class="my-3" />
    <v-card-actions class="justify-end">
      <v-btn color="primary" rounded @click="updatePets"> Guardar </v-btn>
      <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import PetsService from '../services/pets.service';
import DateUtils from '../utils/date.utils';

export default {
  name: 'PetsList',
  props: ['user'],
  data() {
    return {
      pets: [],
      loading: true,
      headers: [
        {
          text: 'Nombre',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Especie',
          sortable: false,
          value: 'species'
        },
        {
          text: 'Edad',
          sortable: false,
          value: 'age'
        },
        {
          text: 'Raza',
          sortable: false,
          value: 'breed'
        },
        {
          text: 'Sexo',
          sortable: false,
          value: 'sex'
        },
        {
          text: 'Visible',
          value: 'visible',
          sortable: false
        }
      ]
    };
  },
  mounted() {
    if (this.user) {
      this.getPets(this.user._id);
    }
  },
  computed: {},
  watch: {
    user: function () {
      if (this.user) {
        this.getPets(this.user._id);
      }
    }
  },
  methods: {
    closeDialog() {
      this.pets = [];
      this.$emit('close-dialog');
    },
    async updatePets() {
      this.loading = true;
      await PetsService.updateMany(this.pets);
      this.loading = false;
    },
    async getPets(userId) {
      this.loading = true;
      const pets = await PetsService.getPetsByUser(userId);
      this.pets = pets.data;
      this.loading = false;
    },
    getAge(birthdate) {
      return DateUtils.getAge(birthdate);
    }
  }
};
</script>