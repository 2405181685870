<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Resumen MRR</h1>
      <v-col v-if="loadingError" class="mr-3 ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end">
        <v-alert dense outlined type="error">Ocurrió un error</v-alert>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="mrr"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [15, 30, 45, 60]
      }"
    >
      <template v-slot:top>
        <v-row class="px-5 py-5" style="justify-content: space-between; align-items: center">
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-select
              v-model="countrySelected"
              label="Seleccione país"
              :items="countries"
              :disabled="loadingData"
              @change="changeCountry"
              item-value="id"
              item-text="flagName"
              placeholder=""
              dense
              hide-details
              outlined
              append-icon="mdi-magnify"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.total_memberships="{ item }">
        <v-chip small color="green" class="white--text font-weight-bold">
          {{ item.total_memberships }}
        </v-chip>
      </template>
    </v-data-table>
    <overlay :value="loadingOverlay"></overlay>
  </div>
</template>

<script>
import MRRService from '../services/mrr.service';
import Overlay from '../components/Overlay.vue';
import CountryService from '../services/countries.service';

export default {
  components: {
    Overlay
  },
  data() {
    return {
      page: 1,
      limit: 15,
      loadingOverlay: false,
      loadingData: false,
      loadingCountries: true,
      loadingError: false,
      mrr: [],
      iso: 'CL',
      totalItems: 0,
      headers: [
        {
          text: 'MRR',
          sortable: false,
          value: 'type'
        },
        {
          text: 'Membresías',
          sortable: false,
          value: 'total_memberships'
        },
        {
          text: 'MRR sin IVA',
          sortable: false,
          value: 'mrr_without_iva'
        },
        {
          text: 'MRR Neto',
          sortable: false,
          value: 'mrr_neto'
        }
      ],
      countries: [{ id: null, name: '', iso: '', currency: '', flagUrlImage: '', flagName: '' }],
      countrySelected: { id: null, name: '', iso: '', currency: '', flagUrlImage: '', flagName: '' }
    };
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    async changeCountry(value) {
      this.countrySelected = this.countries.filter(c => c.id == value)[0];
      this.mrr = [];
      this.totalItems = 0;
      this.iso = this.countrySelected.iso;
      this.getMrrData(this.countrySelected['iso']);
    },
    async getMrrData(iso) {
      try {
        if (this.countrySelected['id'] != null) {
          this.loadingData = true;
          this.mrr = [];
          const res = await MRRService.getMRRResumeByCountry(iso);
          if (res.status === 'ok') {
            this.mrr = [res.data];
            this.totalItems = 1;
          }
          this.loadingData = false;
        }
      } catch (err) {
        this.mrr = [];
        this.totalItems = 0;
        this.loadingData = false;
        this.loadingError = true;
      }
    },
    async getCountries() {
      this.loadingCountries = true;
      const res = await CountryService.getAllCountries();
      if (res.status === 'ok') {
        this.countries = [];
        res.data.map(c => {
          c.flagName = `${c.name}-${c.flagUrlImage}`;
          this.countries.push(c);
        });
        if (this.countries.length > 0) {
          this.iso = this.countries[0].iso;
          this.countrySelected = this.countries[0];
          await this.getMrrData(this.countries[0].iso);
        }
      }
      this.loadingCountries = false;
    }
  }
};
</script>
