var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"900","min-height":"600","max-height":"900"},model:{value:(_vm.showActivateDialog),callback:function ($$v) {_vm.showActivateDialog=$$v},expression:"showActivateDialog"}},[_c('reward-activator',{attrs:{"redemption":_vm.selectedRedemption},on:{"close-dialog":_vm.closeActivateDialog}})],1),_c('v-row',{staticClass:"ma-0 my-4 align-center justify-space-between"},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Canjes y referidos")])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.redemptions,"options":_vm.options,"loading":_vm.loadingData,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"px-5 pt-5",staticStyle:{"justify-content":"space-between","align-items":"center"}},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}}),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}},[_c('v-container',{staticClass:"ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":_vm.querySearch == null || _vm.querySearch == []},on:{"click":_vm.downloadExcel}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-google-spreadsheet ")]),_c('span',[_vm._v("Descargar Excel")])],1)],1)],1)],1),_c('v-row',{staticClass:"mx-2 pt-6"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6","lg":"5"}},[_c('v-autocomplete',{attrs:{"chips":"","clearable":"","deletable-chips":"","multiple":"","small-chips":"","label":"Filtrar por código","outlined":"","item-value":"name","item-text":"name","dense":"","loading":_vm.loadingCodes,"items":_vm.codes,"search-input":_vm.codesSearch,"cache-items":""},on:{"update:searchInput":function($event){_vm.codesSearch=$event},"update:search-input":function($event){_vm.codesSearch=$event},"change":_vm.onCodesChange},model:{value:(_vm.querySearch),callback:function ($$v) {_vm.querySearch=$$v},expression:"querySearch"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6","lg":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Rango de fecha","outlined":"","dense":"","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"12","lg":"4"}},[_c('v-combobox',{attrs:{"items":_vm.types,"label":"Filtro","multiple":"","outlined":"","item-value":"value","item-text":"text","dense":"","small-chips":""},model:{value:(_vm.selectedTypes),callback:function ($$v) {_vm.selectedTypes=$$v},expression:"selectedTypes"}})],1)],1)]},proxy:true},{key:"item.code",fn:function(ref){
    var item = ref.item;
return [(item.code != null)?_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(item.code.name))]):_vm._e()]}},{key:"item.referrer",fn:function(ref){
    var item = ref.item;
return [(item.referrer != null)?_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"30"}},[(item.referrer.imageUrl == null)?_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(item.referrer.name[0] || '')+_vm._s(item.referrer.surname[0] || ''))]):_vm._e(),(item.referrer.imageUrl)?_c('img',{attrs:{"src":item.referrer.imageUrl}}):_vm._e()]),_c('div',[_c('p',{staticClass:"ma-0 pa-0"},[_c('span',[_vm._v(_vm._s(item.referrer.name || '')+" "+_vm._s(item.referrer.surname || ''))])]),(item.referrer.rut)?_c('p',{staticClass:"ma-0 pa-0"},[_c('span',[_vm._v(_vm._s(_vm._f("rut")(item.referrer.rut)))])]):_vm._e()])],1):_vm._e()]}},{key:"item.referred",fn:function(ref){
    var item = ref.item;
return [(item.user != null)?_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"30","color":"primary"}},[(item.user.imageUrl == null)?_c('span',{staticClass:"white--text text-subtitle1"},[_vm._v(_vm._s(item.user.name[0] || '')+_vm._s(item.user.surname[0] || ''))]):_vm._e(),(item.user.imageUrl)?_c('img',{attrs:{"src":item.user.imageUrl}}):_vm._e()]),_c('div',[_c('p',{staticClass:"ma-0 pa-0"},[_c('span',[_vm._v(_vm._s(item.user.name || '')+" "+_vm._s(item.user.surname || ''))])]),(item.user.rut)?_c('p',{staticClass:"ma-0 pa-0"},[_c('span',[_vm._v(_vm._s(_vm._f("rut")(item.user.rut)))])]):_vm._e()])],1):_vm._e()]}},{key:"item.membership",fn:function(ref){
    var item = ref.item;
return [(item.membership != null)?_c('div',{staticClass:"d-flex flex-column py-2"},[(item.membership.pet != null)?_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"20","color":"primary"}},[(!item.membership.pet.imageUrl)?_c('v-icon',{attrs:{"dark":"","x-small":""}},[_vm._v(" mdi-paw ")]):_vm._e(),(item.membership.pet.imageUrl)?_c('img',{attrs:{"src":item.membership.pet.imageUrl}}):_vm._e()],1),_c('span',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(item.membership.pet.name || '')+" ")])],1):_vm._e(),_c('div',[_c('v-chip-group',{staticClass:"ma-0 pa-0"},[(item.membership.status === 0)?_c('v-chip',{staticClass:"white--text font-weight-bold ma-0",attrs:{"x-small":"","color":"orange"}},[_vm._v("Pendiente de pago")]):_vm._e(),(item.membership.status === 1)?_c('v-chip',{staticClass:"white--text font-weight-bold ma-0",attrs:{"color":"blue","x-small":""}},[_vm._v("Pendiente de activación")]):_vm._e(),(item.membership.status === 2)?_c('v-chip',{staticClass:"white--text font-weight-bold ma-0",attrs:{"color":"green","x-small":""}},[_vm._v("Activo")]):_vm._e(),(item.membership.status === 3)?_c('v-chip',{staticClass:"white--text font-weight-bold ma-0",attrs:{"color":"red","x-small":""}},[_vm._v("Pago fallido")]):_vm._e(),(item.membership.status === 4)?_c('v-chip',{staticClass:"font-weight-bold ma-0",attrs:{"color":"grey","outlined":"","x-small":""}},[_vm._v("Desactivado")]):_vm._e(),(item.membership.status === 5)?_c('v-chip',{staticClass:"white--text font-weight-bold ma-0",attrs:{"color":"primary","outlined":"","x-small":""}},[_vm._v("Pendiente de enrolamiento")]):_vm._e()],1)],1),(item.membership.plan)?_c('div',[_c('span',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(item.membership.plan.name)+" ")])]):_vm._e()]):_vm._e()]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.date,'DD/MM/YYYY HH:mm')))])]}},{key:"item.rewarded",fn:function(ref){
    var item = ref.item;
return [(item.code.rewardsToId != null && _vm.hasValidPayment(item))?_c('v-chip-group',[(item.rewarded === true)?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"green"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-check")])],1),_vm._v("Recompensado")],1):_vm._e(),(item.rewarded === false)?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"color":"red","small":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v("No recompensado")],1):_vm._e()],1):_vm._e(),(item.code.rewardsToId != null && !_vm.hasValidPayment(item))?_c('div',[_vm._v(" Aún no realiza su primer pago ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(item.code.rewardsToId != null)?_c('div',[(!item.rewarded)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","fab":"","depressed":"","color":"green"},on:{"click":function($event){return _vm.activateReward(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Marcar recompensa")])]):_vm._e()],1):_vm._e()]}}])}),_c('overlay',{attrs:{"value":_vm.loadingOverlay}}),_c('v-snackbar',{attrs:{"timeout":5000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.error = false}}},'v-btn',attrs,false),[_vm._v(" Entendido ")])]}}]),model:{value:(_vm.emptyReport),callback:function ($$v) {_vm.emptyReport=$$v},expression:"emptyReport"}},[_vm._v(" No hay registros que coincidan con los filtros aplicados. Recuerda que solo aparecen en el reporte aquellos registros con pagos exitosos. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }