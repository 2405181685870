<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="suppliersPlans"
      :options.sync="options"
      :loading="loadingSuppliersPlans"
      :items-per-page="limit"
      :server-items-length="totalItems"
      :disable-sort="true"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:item.graceDays="{ item }">
        <span>{{ item.graceDays }} día{{ item.graceDays == 1 ? '' : 's' }}</span>
      </template>
      <template v-slot:item.price="{ item }">
        <span>{{ item.price }} {{ item.currency || '' }}</span>
      </template>
      <template v-slot:item.available="{ item }">
        <v-icon v-if="item.available" color="green">mdi-check-circle</v-icon>
        <v-icon v-else color="red">mdi-close-circle</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PlansService from '../services/plans.service';

export default {
  name: 'Suppliers',
  components: {},
  data() {
    return {
      suppliersPlans: [],
      loadingSuppliersPlans: false,
      totalItems: 0,
      options: {},
      limit: 10,
      page: 1,
      headers: [
        {
          text: 'Nombre',
          value: 'name'
        },

        {
          text: 'Proveedor',
          value: 'supplier.name'
        },
        {
          text: 'Carencia',
          value: 'graceDays'
        },
        {
          text: 'Precio mensual',
          value: 'price'
        },
        {
          text: 'Disponible para venta',
          value: 'available'
        },
        {
          text: 'Empresa',
          value: 'company'
        }
      ]
    };
  },
  computed: {},
  mounted() {
    this.loadSuppliersPlans();
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.loadSuppliersPlans();
      },
      deep: true
    }
  },
  methods: {
    async loadSuppliersPlans() {
      this.loadingSuppliersPlans = true;
      const res = await PlansService.getSuppliersPlans(this.page, this.limit);
      this.suppliersPlans = res.data.suppliersPlans;
      this.totalItems = res.data.totalItems;
      this.loadingSuppliersPlans = false;
    }
  }
};
</script>
