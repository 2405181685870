import moment from 'moment';
import 'moment/locale/es';
import Vue from 'vue';
import VueCurrencyFilter from 'vue-currency-filter';
import vueDebounce from 'vue-debounce';
import { longClickDirective } from 'vue-long-click';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import DateUtils from './utils/date.utils';
import RutUtils from './utils/rut.utils';

Vue.config.productionTip = false;

Vue.filter('rut', function(text) {
  return RutUtils.formatRut(text);
});

Vue.filter('date', function(text) {
  return DateUtils.format(text);
});

Vue.filter('clp', function(value) {
  if (typeof value !== 'number') {
    return value;
  }
  var formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP'
  });
  return formatter.format(value);
});

Vue.use(vueDebounce, {
  defaultTime: '1000ms'
});
Vue.use(require('vue-moment'), {
  moment
});

Vue.use(VueCurrencyFilter, [
  {
    name: 'clpCurrency',
    symbol: '$',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: ''
  },
  {
    name: 'ufCurrency',
    symbol: 'UF',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'end',
    symbolSpacing: true,
    avoidEmptyDecimals: ''
  }
]);

const longClickInstance = longClickDirective({ delay: 400, interval: 0 });
Vue.directive('longclick', longClickInstance);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');
