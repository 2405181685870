import axios from 'axios';
const url = process.env.VUE_APP_BACKEND_URL;

export default class CountryService {
  static async getAllCountries(visible) {
    let _url = `${url}/countries?page=${1}&limit=${400}&visible=${visible}`;
    try {
      const response = await axios.get(_url);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }
}
