<template>
  <v-chip-group>
    <v-chip v-if="planName === 'Básico'" x-small color="orange" class="white--text font-weight-bold"
      >Plan {{planName}}</v-chip
    >
    <v-chip v-if="planName === 'Recomendado'" color="blue" x-small class="white--text font-weight-bold"
      >Plan {{planName}}</v-chip
    >
    <v-chip v-if="planName === 'Premium'" color="purple" x-small class="white--text font-weight-bold"
      >Plan {{planName}}</v-chip
    >        
  </v-chip-group>
</template>

<script>
export default {
  name: 'PlanStatusChip',
  props: ['planName']
};
</script>
