import axios from 'axios';

const adminUrl = process.env.VUE_APP_ADMIN_URL;
const backendUrl = process.env.VUE_APP_BACKEND_URL;
const backendUrlv2 = process.env.VUE_APP_BACKEND_URL_V2;
const backendUrlv3 = process.env.VUE_APP_BACKEND_URL_V3;

export default class UsersService {
  static async createUser(user, commune, region) {
    const data = {
      role: user.role,
      email: user.email,
      name: user.name,
      surname: user.surname,
      address: {}
    };
    if (user.street != null) {
      data.address.street = user.street;
    }
    if (user.number != null) {
      data.address.number = user.number;
    }
    if (user.extra != null) {
      data.address.extra = user.extra;
    }
    if (commune != null) {
      data.address.commune = commune;
    }
    if (region != null) {
      data.address.region = region;
    }
    if (user.birthday != null) {
      data.birthday = new Date(user.birthday).toISOString();
    }
    if (user.rut != null) {
      data.rut = user.rut;
    }
    if (user.serial != null) {
      data.serial = user.serial;
    }
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.post(`${adminUrl}/users`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        console.log(response.data.data);
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      console.log(`POST ${adminUrl}/users`);
      return { status: 'error' };
    }
  }

  static async getUsers(page, limit, search) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrl}/users?page=${page}&limit=${limit}`;
      if (search != null && search.length != 0) {
        url = url + `&search=${search}`;
      }
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getUserById(userId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${adminUrl}/users/${userId}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updateUser(user) {
    const data = {
      role: user.role,
      email: user.email,
      name: user.name,
      surname: user.surname,
      commune: user.commune,
      region: user.region,
      address: {
        region: user.region,
        commune: user.commune,
        street: user.street,
        number: user.number,
        extra: user.extra
      },
      birthday: new Date(user.birthday).toISOString(),
      rut: user.rut,
      serial: user.serial,
      phone: user.phone
    };
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.put(`${adminUrl}/users/${user.id}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async deleteUser(userId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.delete(`${adminUrl}/users/${userId}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  //! V2 - PostgreSQL users
  static async getUsersBySearch(search) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    const response = await axios.get(`${backendUrl}/users/details?search=${search}`, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (response.data.status === 'ok') {
      return { status: 'ok', data: response.data.data };
    } else {
      throw new Error(response.data.message);
    }
  }

  static async getUsersBySearchToRefund(params) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    const response = await axios.get(`${backendUrl}/users/params?params=${params}`, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (response.data.status === 'ok') {
      return { status: 'ok', data: response.data.data };
    } else {
      throw new Error(response.data.message);
    }
  }

  static async createB2CUser(data) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.post(`${backendUrlv3}/onboarding/checkout-brokers`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });

      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error', message: response.data.message };
      }
    } catch (error) {
      console.error(`POST ${backendUrlv3}/onboarding/checkout-brokers`, error);
      return { status: 'error', message: error.message };
    }
  }

  static async createB2BUser(newUser, planId, companyId) {
    try {
      const data = {
        planId: planId,
        companyId: companyId,
        countryIso: newUser.country,
        email: newUser.email,
        name: newUser.name,
        surname: newUser.surname,
        rut: newUser.rut,
        phone: newUser.phone,
        address: {
          street: newUser.street,
          number: newUser.number,
          extra: newUser.extra,
          commune: newUser.commune,
          region: newUser.region
        },
        birthday: newUser.birthday
      };
      console.log('Datos que se enviaran:', data);
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.post(`${backendUrlv2}/userscompaniesaccess/user`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });

      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.user };
      } else {
        return { status: 'error', message: response.data.message };
      }
    } catch (error) {
      console.error(`POST ${backendUrlv2}/userscompaniesaccess/user`, error);
      return { status: 'error', message: error.message };
    }
  }
}
