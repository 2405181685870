<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Homologación y proveedores</h1>
    </v-row>

    <v-expansion-panels :value="panel">
      <v-expansion-panel>
        <v-expansion-panel-header> Homologación </v-expansion-panel-header>
        <v-expansion-panel-content>
          <membership-homologation></membership-homologation>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Proveedores </v-expansion-panel-header>
        <v-expansion-panel-content>
          <suppliers></suppliers>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import MembershipHomologation from '../components/MembershipHomologation.vue';
import Suppliers from '../components/Suppliers.vue';

export default {
  name: 'Homologation',
  components: { MembershipHomologation, Suppliers },
  data() {
    return {
      panel: 0
    };
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {}
};
</script>
