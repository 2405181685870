<template>
  <v-card class="pa-6">
    <v-card-title class="text-uppercase">
      <v-row class="my-1 justify-space-between align-center">
        <div>Nuevo registro de servicio</div>
        <v-switch
          v-model="isPawerUser"
          label="Usuario Pawer"
          dense
          hide-details="auto"
          @change="clearAll"
        ></v-switch>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </v-row>
    </v-card-title>

    <hr class="my-3" />
    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>

    <v-form ref="newServiceRec" @submit.prevent>
      <v-container>
        <!-- ! CAMPO DE USUARIOS DE PAWER -->
        <v-col v-if="isPawerUser">
          <v-row class="my-1">
            <v-col cols="12">
              <v-text-field
                label="Buscar usuario"
                v-model="search"
                :required="isPawerUser"
                outlined
                dense
                hide-details="auto"
                append-icon="mdi-magnify"
                placeholder="Nombre, apellido o email"
                @click:append="searchReq"
                @keyup.enter="searchReq"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="6" class="my-0" v-if="usersFinded.length > 0">
              <!-- ! USUARIOS ENCONTRADOS-->
              <v-select
                v-model="selectedUser"
                :disabled="usersFinded.length === 0"
                :items="usersFinded"
                label="Elige un usuario"
                dense
                outlined
                :required="isPawerUser"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.name }} {{ item.surname }} - {{ item.email }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:selection="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.name }} {{ item.surname }} - {{ item.email }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col v-else>
              <span v-if="noDataMsg">{{ noDataMsg }}</span>
            </v-col>

            <v-col cols="6" class="my-0" v-if="selectedUser">
              <!-- ! MEMBRESIAS DEL USUARIO ENCONTRADO-->
              <v-select
                v-model="selectedMembership"
                :disabled="selectedUser.user === 0"
                :items="selectedUser.memberships"
                label="Elige una membresia"
                dense
                outlined
                :required="isPawerUser"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.pet.name }}
                      <v-icon v-if="item.pet.species === 'dog'" right> mdi-dog </v-icon>
                      <v-icon v-else right> mdi-cat </v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-slot:selection="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.pet.name }}
                      <v-icon v-if="item.pet.species === 'dog'" right> mdi-dog </v-icon>
                      <v-icon v-else right> mdi-cat </v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <!-- !Elegir servicio utilizado -->
            <!-- Servicios de Plan -->
            <v-col cols="4" v-if="selectedMembership">
              <v-select
                v-model="selectedServicePlan"
                :disabled="!selectedMembership"
                :items="selectedMembership.plan.servicePlanDetails"
                label="Elige el servicio utilizado"
                dense
                outlined
                :required="isPawerUser && !selectedIsolatedService"
                @change="servicePlanPicked"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.service.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-slot:selection="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.service.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <!-- Servicios isolados (de pawer a domicilio x ej) -->
            <v-col cols="4" v-if="selectedMembership">
              <v-select
                v-model="selectedIsolatedService"
                :items="isolatedServices"
                label="Otros servicios"
                dense
                outlined
                :required="isPawerUser && !selectedServicePlan"
                @change="isolatedPicked"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-slot:selection="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>

            <!-- ! DATE PICKER -->
            <v-col cols="4" v-if="selectedMembership">
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="usedAt"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-model="dateFormated"
                    label="Cuando fue usado"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="usedAt" no-title scrollable :max="today">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateMenu = false">
                    Cerrar
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dateMenu.save(usedAt)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="my-0">
            <v-col cols="4" v-if="selectedMembership">
              <v-text-field
                label="Gasto del usuario:"
                v-model="userSpends"
                outlined
                dense
                hide-details="auto"
                type="number"
                prefix="$"
                hide-spin-buttons
              ></v-text-field>
            </v-col>

            <v-col cols="4" v-if="selectedMembership">
              <v-text-field
                :disabled="!refundable"
                label="Monto del egreso"
                v-model="refundedAmount"
                outlined
                dense
                hide-details="auto"
                type="number"
                prefix="$"
                hide-spin-buttons
              ></v-text-field>
            </v-col>

            <v-col cols="4" v-if="selectedMembership">
              <v-checkbox dense v-model="refundable" label="¿Genera egreso?"></v-checkbox>
            </v-col>
          </v-row>

          <v-row class="my-0">
            <v-col cols="8" v-if="selectedMembership">
              <v-textarea
                label="Descripción (opcional)"
                v-model="description"
                outlined
                dense
                hide-details="auto"
                counter
                maxlength="100"
                rows="3"
              ></v-textarea>
            </v-col>
            <v-col cols="4" v-if="selectedMembership">
              <v-radio-group label="Tipo de operación" v-model="operationType" class="ma-0" dense>
                <v-radio label="Reembolso" value="refund" dense></v-radio>
                <v-radio label="Operativo" value="operational" dense></v-radio>
              </v-radio-group>
              <p v-if="selectedMembership && !operationType" style="color: red; font-size: 12px;">
                Debes seleccionar un tipo de operación.
              </p>
            </v-col>
          </v-row>
        </v-col>

        <!-- ! CAMPOS DE USUARIO EXTERNO-->

        <v-col v-else>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Nombre y apellido"
                :required="!isPawerUser"
                v-model="fullName"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <!-- ! Servicios isolados (de pawer a domicilio x ej) -->
            <v-col cols="4">
              <v-select
                v-model="selectedIsolatedService"
                :items="isolatedServices"
                label="Elige el servicio utilizado"
                dense
                outlined
                :required="!isPawerUser"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-slot:selection="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <!-- !date -->
            <v-col cols="4">
              <v-menu
                ref="dateMenu"
                dense
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="usedAt"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormated"
                    label="Cuando fue usado"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="usedAt" no-title scrollable :max="today">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateMenu = false">
                    Cerrar
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dateMenu.save(usedAt)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Gasto del usuario:"
                v-model="userSpends"
                outlined
                dense
                hide-details="auto"
                type="number"
                prefix="$"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :disabled="!refundable"
                label="Monto del egreso"
                v-model="refundedAmount"
                outlined
                dense
                hide-details="auto"
                type="number"
                prefix="$"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-checkbox dense v-model="refundable" label="¿Genera egreso?"></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="8">
              <v-textarea
                label="Descripción (opcional)"
                v-model="description"
                outlined
                dense
                hide-details="auto"
                counter
                maxlength="100"
                rows="3"
              ></v-textarea>
            </v-col>

            <v-col cols="4">
              <v-radio-group
                label="Tipo de operación"
                v-model="operationType"
                class="ma-0"
                dense
                :error="!operationType"
                :error-messages="!operationType ? 'Debes seleccionar un tipo de operación.' : ''"
              >
                <v-radio label="Reembolso" value="refund" dense></v-radio>
                <v-radio label="Operativo" value="operational" dense></v-radio>
              </v-radio-group>
              <p v-if="!selectedMembership && !operationType" style="color: red; font-size: 12px;">
                Debes seleccionar un tipo de operación.
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </v-form>

    <v-card-actions class="justify-end">
      <v-btn :disabled="!isValid" color="primary" rounded @click="create">
        Crear registro
      </v-btn>
      <v-btn text color="primary" rounded @click="closeDialog">Cancelar</v-btn>
    </v-card-actions>

    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ creationMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ServicesRecords from '../services/services_records.service';
import UsersService from '../services/users.service';
import moment from 'moment';
import '../main';

export default {
  name: 'ServiceRecordMaintainer',
  // ! Si hay serviceRecord, llenar los campos con los datos recibidos
  props: ['activeServices', 'isolatedServices', 'refreshParent'],
  data() {
    return {
      loading: false,
      noDataMsg: null,
      snackbar: false,
      creationMsg: null,
      usedAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateMenu: false,
      today: moment(new Date(Date.now())).format('YYYY-MM-DD'),
      search: null,
      isPawerUser: true,
      description: null,
      refundable: false,
      userSpends: null,
      refundedAmount: null,
      //!
      usersFinded: [],
      selectedUser: null,
      userMemberships: [],
      selectedMembership: null,
      selectedServicePlan: null,
      selectedIsolatedService: null,
      // ! If particular user:
      fullName: null,
      operationType: null
    };
  },
  mounted() {
    this.$refs.newServiceRec.resetValidation();
  },
  watch: {},
  computed: {
    isValid() {
      if (this.isPawerUser) {
        return (
          this.selectedMembership !== null &&
          (this.selectedServicePlan !== null || this.selectedIsolatedService !== null) &&
          this.operationType !== null
        );
      } else {
        return (
          this.fullName !== null &&
          this.fullName !== '' &&
          this.fullName.length > 2 &&
          this.selectedIsolatedService !== null &&
          this.operationType !== null
        );
      }
    },
    dateFormated() {
      if (this.usedAt == null) {
        return null;
      }
      return moment(this.usedAt).format('DD/MM/YYYY');
    }
  },
  methods: {
    clearAll() {
      this.noDataMsg = null;
      this.usersFinded = [];
      this.selectedUser = null;
      this.userMemberships = [];
      this.selectedMembership = null;
      this.selectedServicePlan = null;
      this.selectedIsolatedService = null;
      this.fullName = null;
      this.description = null;
      this.operationType = null;
      this.refundable = false;
      this.userSpends = null;
      this.refundedAmount = null;
      this.usedAt = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.$refs.newServiceRec.resetValidation();
    },
    closeDialog() {
      this.clearAll();
      this.$emit('close-dialog');
    },
    async create() {
      try {
        this.loading = true;
        let query = {};
        if (this.isPawerUser) {
          query = {
            membershipId: this.selectedMembership.id,
            servicePlanId: this.selectedServicePlan !== null ? this.selectedServicePlan.id : null,
            isolatedServiceId:
              this.selectedIsolatedService !== null ? this.selectedIsolatedService.id : null,
            userSpends: this.userSpends ? parseFloat(this.userSpends) : null,
            refundedAmount: this.refundedAmount ? parseFloat(this.refundedAmount) : null,
            description: this.description,
            refundable: this.refundable,
            operationType: this.operationType,
            usedAt: this.usedAt
          };
        } else {
          let realDescription = this.description ? ` | ${this.description}` : '';
          let fullDescription = `${this.fullName}${realDescription}`;
          query = {
            isolatedServiceId: this.selectedIsolatedService.id,
            userSpends: this.userSpends ? parseFloat(this.userSpends) : null,
            refundedAmount: this.refundedAmount ? parseFloat(this.refundedAmount) : null,
            description: fullDescription,
            refundable: this.refundable,
            operationType: this.operationType,
            usedAt: this.usedAt
          };
        }
        await ServicesRecords.create(query);
        this.loading = false;
        this.creationMsg = 'Nuevo registro creado';
        this.snackbar = true;
        this.closeDialog();
        this.refreshParent();
      } catch (error) {
        this.loading = false;
        this.snackbar = true;
        this.creationMsg = 'Algo salio mal, intentalo mas tarde';
        this.refreshParent();
        console.log(error);
      }
    },
    async searchReq() {
      try {
        this.clearAll();
        this.loading = true;
        this.noDataMsg = null;
        const res = await UsersService.getUsersBySearch(this.search);
        this.usersFinded = res.data.users;
        // Solo se muestran usuarios con al menos 1 membresia
        this.usersFinded = res.data.users.filter(user => user.user.length > 0);
        console.log('SEARCH: ', this.usersFinded);
        this.loading = false;
        if (this.usersFinded.length === 0) {
          this.noDataMsg = 'No se encontraron usuarios';
        }
      } catch (error) {
        this.loading = false;
        this.noDataMsg = 'Algo salió mal, intentalo más tarde';
        console.log(error);
      }
    },
    servicePlanPicked() {
      this.selectedIsolatedService = null;
    },
    isolatedPicked() {
      this.selectedServicePlan = null;
    }
  }
};
</script>
