import axios from 'axios';
import qs from 'qs';

const backendUrl = process.env.VUE_APP_ADMIN_URL;

export default class RedemptionsService {
  static async getRedemptions(page, limit, query) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));

      let params = {
        page,
        limit
      };

      if (query) {
        if (query.types) {
          params = { ...params, types: query.types };
        }
        if (query.codes) {
          params = { ...params, codes: query.codes };
        }
        if (query.from) {
          params = { ...params, from: `${query.from}T00:00:00Z` };
          if (query.to) {
            params = { ...params, to: `${query.to}T23:59:59Z` };
          } else {
            params = { ...params, to: `${query.from}T23:59:59Z` };
          }
        }
      }

      const response = await axios.get(
        `${backendUrl}/redemptions`,

        {
          params,
          paramsSerializer: params =>
            qs.stringify(params, { encode: false, arrayFormat: 'repeat' }),
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getRedemptionReport(query) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));

      let params = {};

      if (query) {
        if (query.codes) {
          params = { ...params, codes: query.codes };
        }
        if (query.from) {
          params = { ...params, from: `${query.from}T00:00:00Z` };
          if (query.to) {
            params = { ...params, to: `${query.to}T23:59:59Z` };
          } else {
            params = { ...params, to: `${query.from}T23:59:59Z` };
          }
        }
      }

      const response = await axios.get(`${backendUrl}/redemptions/report`, {
        params,
        paramsSerializer: params => qs.stringify(params, { encode: false, arrayFormat: 'repeat' }),
        headers: { Authorization: `Bearer ${accessToken}` },
        responseType: 'blob'
      });

      if (response.status === 200) {
        return { status: 'ok', data: response.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updateRedemption(redemptionId, data) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.put(`${backendUrl}/redemptions/${redemptionId}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }
}
