<template>
  <div>
    <v-dialog
      persistent
      v-model="membershipIsOpen"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <membership-maintainer
        @close-dialog="closeMembershipDialog"
        :membership="selectedMembership"
      ></membership-maintainer>
    </v-dialog>

    <v-dialog
      persistent
      v-model="planIsOpen"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <plan-details-maintainer
        :content="selectedMembership"
        @close-dialog="closePlanDialog"
      ></plan-details-maintainer>
    </v-dialog>

    <v-dialog
      persistent
      v-model="createIsOpen"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <service-record-maintainer
        :activeServices="activeServices"
        :isolatedServices="isolatedServices"
        :refreshParent="refresh"
        @close-dialog="closeCreateServiceRecord"
      ></service-record-maintainer>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="300">
      <v-card>
        <v-card-title class="text-subtitle-2">
          ¿Estás seguro de que quieres eliminar?
        </v-card-title>
        <v-card-actions class="flex flex-row justify-space-between">
          <v-btn class="text-subtitle-2" @click="closeDeleteDialog">Cancelar</v-btn>
          <v-btn
            class="text-subtitle-2"
            @click="deleteServiceRecord"
            color="red darken-1 white--text"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Registro de uso de Servicios</h1>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="servicesRecords"
      :options.sync="options"
      :loading="loadingOverlay"
      :items-per-page="limit"
      :server-items-length="totalItems"
      :disable-sort="loadingOverlay"
      multi-sort
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50, 200, 500]
      }"
    >
      <template v-slot:top>
        <v-row class="px-5 py-5" style="justify-content: space-between; align-items: center">
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-text-field
              v-model="search"
              label="Buscar"
              :disabled="loadingOverlay"
              placeholder="Usuario o mascota"
              dense
              hide-details
              outlined
              append-icon="mdi-magnify"
              @click:append="searchRequest"
              @keyup.enter="searchRequest"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn color="primary" depressed @click="openCreateServiceRecord()">
              Nuevo registro
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-5 pt-0 pb-5">
          <!-- <span>Aca podrian agregarse filtros de busqueda (o no)</span> -->
        </v-row>
      </template>

      <template v-slot:item.usedAt="{ item }">
        <span v-if="item.usedAt" class="text-caption">{{ item.usedAt | date }}</span>
      </template>


      <template v-slot:item.service="{ item }">
        <div v-if="item.servicePlan" style="color: black; max-width: 100px; word-wrap: break-word;">
          {{ item.servicePlan.service.title || '' }}
          <!-- <div class="text-caption">(De Plan)</div> -->
        </div>
        <div
          v-if="item.isolatedService"
          style="color: black; max-width: 100px; word-wrap: break-word;"
        >
          * {{ item.isolatedService.name || '' }}
        </div>
      </template>

      <template v-slot:item.membership="{ item }">
        <div v-if="item.membership" @click="openMembershipDialog(item.membership)">
          <v-chip
            v-if="item.membership.pet.species === 'cat'"
            small
            color="green"
            class="white--text font-weight-bold"
            style="font-weight: bold; cursor: pointer; max-width: 200px; word-wrap: break-word;"
            ><v-icon left> mdi-cat </v-icon> {{ item.membership.pet.name || '-' }}</v-chip
          >

          <v-chip
            v-if="item.membership.pet.species === 'dog'"
            small
            color="orange"
            class="white--text font-weight-bold"
            style="font-weight: bold; cursor: pointer; max-width: 200px; word-wrap: break-word;"
            ><v-icon left> mdi-dog </v-icon> {{ item.membership.pet.name || '-' }}</v-chip
          >
        </div>
        <span v-else class="grey--text text--lighten-1">No info</span>
      </template>
      <template v-slot:item.plan="{ item }">
        <v-col
          class="ma-0 pa-0 mt-1"
          style="cursor: pointer;"
          v-if="item.membership"
          @click="openPlanDialog(item.membership)"
        >
          <template>
            <span>
              {{ item.membership.plan.name }}
            </span>
          </template>

          <v-chip-group>
            <v-chip outlined color="primary" x-small class="white--text font-weight-bold">{{
              item.membership.plan.paymentInterval === 1 ? 'Anual' : 'Mensual'
            }}</v-chip>
          </v-chip-group>
        </v-col>

        <span v-else class="grey--text text--lighten-1">No info</span>
      </template>
      <template v-slot:item.user="{ item }">
        <span v-if="item.membership">
          {{ item.membership.user.name }} {{ item.membership.user.surname }}
        </span>

        <span v-else class="grey--text text--lighten-1">No info</span>
      </template>

      <template v-slot:item.userSpends="{ item }">
        <span v-if="!item.userSpends" style="color: black"> - </span>
        <span v-else style="color: black">{{ item.userSpends | clp }} </span>
      </template>

      <template v-slot:item.refundedAmount="{ item }">
        <span v-if="!item.refundedAmount" style="color: black"> - </span>
        <span v-else style="color: black">
          {{ item.refundedAmount | clp }}
          <v-chip-group v-if="item.operationType">
            <v-chip
              :outlined="true"
              :color="item.operationType === 'refund' ? '#1CA762' : '#5348CC'"
              x-small
              class="font-weight-bold"
            >
              {{ item.operationType === 'refund' ? 'Reembolso' : 'Operativo' }}
            </v-chip>
          </v-chip-group>
        </span>
      </template>

      <template v-slot:item.details="{ item }">
        <div
          v-if="item.description"
          style="color: black; font-size:11px; line-height: normal; max-width: 120px; word-wrap: break-word;"
        >
          {{ item.description || '-' }}
        </div>
        <span v-else>-</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              depressed
              x-small
              fab
              color="green"
              v-bind="attrs"
              v-on="on"
              @click="openCreateServiceRecord(item)"
            >
              <v-icon small color="white">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              depressed
              x-small
              fab
              color="red"
              v-bind="attrs"
              v-on="on"
              @click="openDeleteDialog(item)"
            >
              <v-icon small color="white">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <overlay :value="loadingOverlay"></overlay>
  </div>
</template>

<script>
import Overlay from '../components/Overlay.vue';
import ServicesRecordsService from '../services/services_records.service';
import DateUtils from '../utils/date.utils';
import MembershipMaintainer from '../components/MembershipMaintainer';
import PlanDetailsMaintainer from '../components/PlanDetailsMaintainer';
import ServiceRecordMaintainer from '../components/ServiceRecordMaintainer';
import ServicesService from '../services/services.service';

export default {
  components: {
    Overlay,
    MembershipMaintainer,
    PlanDetailsMaintainer,
    ServiceRecordMaintainer
  },
  data() {
    return {
      headers: [
        {
          text: 'Fecha de uso',
          value: 'usedAt',
          sortable: true,
        },
        {
          text: 'Servicio',
          value: 'service',
          sortable: false
        },
        {
          text: 'Mascota',
          value: 'membership',
          sortable: false
        },
        {
          text: 'Plan',
          value: 'plan',
          sortable: false
        },
        {
          text: 'Usuario',
          value: 'user',
          sortable: false
        },
        {
          text: 'Gasto del usuario',
          value: 'userSpends',
          sortable: false
        },
        {
          text: 'Egreso',
          value: 'refundedAmount',
          sortable: false
        },
        {
          text: 'Descripción',
          value: 'details',
          sortable: false
        },
        {
          text: 'Acciones',
          value: 'actions',
          sortable: false
        }
      ],

      // ! queries o "filtros"
      page: 1,
      limit: 10,
      userId: null, // postgres id
      membershipId: null,
      servicePlanId: null,
      isolatedServiceId: null,
      refundable: null, // bool
      userSpends: null, // bool
      spendsFrom: 0,
      spendsTo: 9999999,
      search: null,
      // fin queries
      options: {
        sortBy: ['usedAt'],
        sortDesc: [true]
      },
      servicesRecords: [],
      totalItems: 0,
      serviceRecordSelected: null,
      loadingOverlay: false,
      errorMessage: null,
      selectedMembership: null,
      membershipIsOpen: false,
      selectedUser: null,
      planIsOpen: false,
      createIsOpen: false,
      // !
      activeServices: [],
      isolatedServices: [],
      deleteDialog: false,
      serviceRecordToDelete: null
    };
  },
  beforeMount() {},
  mounted() {
    this.getAllServices();
    this.getAllIsolatedServices();
    this.getAllServicesRecords();
  },
  methods: {
    planColor(type) {
      if (type === 'b2b') {
        return 'green';
      }
      return 'primary';
    },
    formatDate(stringDate) {
      return DateUtils.format(stringDate);
    },
    refresh() {
      this.getAllServicesRecords();
    },
    async getAllServicesRecords(query) {
      this.loadingOverlay = true;
      let finalQuery = { ...query, page: this.page, limit: this.limit };
      try {
        const res = await ServicesRecordsService.getAll(finalQuery);
        this.servicesRecords = res.data.servicesRecords;
        console.log(res.data.servicesRecords);
        this.totalItems = res.data.count;
        this.servicesRecords.sort((a, b) => {
          const dateA = new Date(a.usedAt);
          const dateB = new Date(b.usedAt);
          return this.options.sortDesc[0] ? dateB - dateA : dateA - dateB;
        });
        this.loadingOverlay = false;
        this.errorMessage = null;
      } catch (error) {
        this.loadingOverlay = false;
        this.errorMessage = error.message;
        console.error(error);
      }
      this.loadingOverlay = false;
    },
    async searchRequest() {
      if (this.search !== '' || this.search !== null || this.stringToSearch.length >= 2) {
        let query = {
          page: this.page,
          limit: this.limit,
          // userId: this.userId,
          // membershipId: this.membershipId,
          servicePlanId: this.servicePlanId,
          isolatedServiceId: this.isolatedServiceId,
          refundable: this.refundable,
          userSpends: this.userSpends,
          spendsFrom: this.spendsFrom,
          spendsTo: this.spendsTo,
          search: this.search
        };
        await this.getAllServicesRecords(query);
      }
    },
    openMembershipDialog(membership) {
      this.membershipIsOpen = true;
      this.selectedMembership = membership;
    },
    closeMembershipDialog() {
      this.membershipIsOpen = false;
      this.selectedMembership = null;
    },
    openPlanDialog(membership) {
      if (membership) {
        this.planIsOpen = true;
        this.selectedMembership = membership;
      }
    },
    closePlanDialog() {
      this.planIsOpen = false;
    },
    openCreateServiceRecord(serviceRecord) {
      if (serviceRecord) {
        this.serviceRecordSelected = serviceRecord;
      } else {
        this.serviceRecordSelected = null;
      }
      this.createIsOpen = true;
    },
    closeCreateServiceRecord() {
      this.serviceRecordSelected = null;
      this.createIsOpen = false;
    },

    // !
    async getAllServices() {
      try {
        this.loadingOverlay = true;

        const res = await ServicesService.getAllServices();
        this.activeServices = res.data;
        console.log(res.data);
        this.loadingOverlay = false;
      } catch (error) {
        this.loadingOverlay = false;
        console.log(error);
      }
    },

    async getAllIsolatedServices() {
      try {
        this.loadingOverlay = true;
        const res = await ServicesService.getAllIsolatedServices();
        this.isolatedServices = res.data.isolatedServices;
        console.log(res.data);
        this.loadingOverlay = false;
      } catch (error) {
        this.loadingOverlay = false;
        console.log(error);
      }
    },
    async deleteServiceRecord() {
      try {
        this.loadingOverlay = true;
        if (this.serviceRecordToDelete) {
          await ServicesRecordsService.delete(this.serviceRecordToDelete.id);
          // Loading overlay turn to false in the next fn, so:
          this.servicesRecords = this.servicesRecords.filter(
            serviceRecord => serviceRecord.id !== this.serviceRecordToDelete.id
          );
        }
        this.deleteDialog = false;
        this.loadingOverlay = false;
      } catch (error) {
        this.deleteDialog = false;
        this.loadingOverlay = false;
        console.log(error);
      }
    },
    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.serviceRecordToDelete = item;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.serviceRecordToDelete = null;
    }
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        let query = {
          page: this.page,
          limit: this.limit,
          userId: this.userId,
          membershipId: this.membershipId,
          servicePlanId: this.servicePlanId,
          isolatedServiceId: this.isolatedServiceId,
          refundable: this.refundable,
          userSpends: this.userSpends,
          spendsFrom: this.spendsFrom,
          spendsTo: this.spendsTo
        };
        this.getAllServicesRecords(query);
      },
      deep: true
    }
  }
};
</script>
