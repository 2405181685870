<template>
  <v-card class="pa-6">
    <v-card-title class="text-uppercase">
        <div>Eliminar usuario {{user != null ? user.name : ''}}</div>
    </v-card-title>
    <hr class="my-3" />
    <v-card-text class="my-0 py-0">
      <p>¿Está seguro de que desea eliminar al usuario {{userData}}?</p>
    </v-card-text>
    <hr class="my-3" />
    <v-card-actions class="justify-end">
      <v-btn
        color="primary"
        rounded
        @click="deleteUser"
      >
        Sí, eliminar
      </v-btn>
      <v-btn text color="primary" rounded @click="closeDialog">Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import RutUtils from "../utils/rut.utils";

export default {
  name: "ConfirmationModal",
  props: ["user"],
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
    userData: function() {
      var text = "";
      if (this.user.name) {
      text = this.user.name;
      }
      if (this.user.surname) {
        text = text + " " + this.user.surname;
      }
      if (this.user.rut) {
        var rut = RutUtils.clean(this.user.rut);
        rut = RutUtils.formatRut(this.user.rut);
        text = text + ", RUT " + rut;
      }
      return text;
    }
  },
  watch: {
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    deleteUser() {
        this.$emit("delete-user");
    },
  },
};
</script>