var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"900","min-height":"600","max-height":"900"},model:{value:(_vm.showActivateDialog),callback:function ($$v) {_vm.showActivateDialog=$$v},expression:"showActivateDialog"}},[_c('reward-activator',{attrs:{"redemption":_vm.selectedRedemption},on:{"close-dialog":_vm.closeActivateDialog}})],1),_c('v-row',{staticClass:"ma-0 my-4 align-center justify-space-between"},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Donaciones")])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.donations,"options":_vm.options,"loading":_vm.loadingData,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"px-5 py-5"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Rango de fecha","outlined":"","dense":"","hide-details":"","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"range":""},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}},[_c('v-container',{staticClass:"ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","id":"donation-csv"},on:{"click":_vm.downloadCsv}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-google-spreadsheet ")]),_c('span',[_vm._v("Descargar CSV")])],1)],1)],1)],1)]},proxy:true},{key:"item.user",fn:function(ref){
    var item = ref.item;
return [(item.user != null)?_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"30"}},[(item.user.imageUrl == null)?_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(item.user.name[0] || '')+_vm._s(item.user.surname[0] || ''))]):_vm._e(),(item.user.imageUrl)?_c('img',{attrs:{"src":item.user.imageUrl}}):_vm._e()]),_c('div',[_c('p',{staticClass:"ma-0 pa-0"},[_c('span',[_vm._v(_vm._s(item.user.name || '')+" "+_vm._s(item.user.surname || ''))])]),(item.user.rut)?_c('p',{staticClass:"ma-0 pa-0"},[_c('span',[_vm._v(_vm._s(_vm._f("rut")(item.user.rut)))])]):_vm._e()])],1):_vm._e()]}},{key:"item.cause",fn:function(ref){
    var item = ref.item;
return [(item.user != null)?_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2 pa-1",attrs:{"size":"40","color":"primary"}},[(item.user.imageUrl == null)?_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(item.user.name[0] || '')+_vm._s(item.user.surname[0] || ''))]):_vm._e(),(item.cause.organization.logoUrl)?_c('v-img',{attrs:{"src":item.cause.organization.logoUrl,"contain":""}}):_vm._e()],1),_c('div',[_c('p',{staticClass:"ma-0 pa-0"},[_c('span',[_vm._v(_vm._s(item.cause.name || ''))])]),(item.cause.organization)?_c('p',{staticClass:"ma-0 pa-0 font-weight-bold"},[_c('span',[_vm._v(_vm._s(item.cause.organization.name))])]):_vm._e()])],1):_vm._e()]}},{key:"item.quantity",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.quantity))]),_c('span',[_vm._v(" x "+_vm._s(item.cause.pawPrice))])]}},{key:"item.totalPaws",fn:function(ref){
    var item = ref.item;
return [(item.cause != null)?_c('div',{staticClass:"d-flex align-center flex-row"},[_c('v-chip',{staticClass:"white--text font-weight-bold ma-0 pa-2",attrs:{"color":"primary","outlined":""}},[_c('v-icon',{staticClass:"ma-0 pa-0 pr-1",attrs:{"left":"","small":""}},[_vm._v(" mdi-paw ")]),_c('span',[_vm._v(_vm._s(item.cause.pawPrice * item.quantity))])],1)],1):_vm._e()]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,'DD/MM/YYYY HH:mm')))])]}}])}),_c('overlay',{attrs:{"value":_vm.loadingOverlay}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }