import axios from 'axios';
import FileUtils from '../utils/file.utils';

const adminUrl = process.env.VUE_APP_ADMIN_URL;

export default class MembershipsService {
  static async getMemberships(page, limit, query, sort, userId, search, supplierId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrl}/memberships?page=${page}&limit=${limit}`;
      // console.log('QUERY:', query.length);
      if (userId != null && userId.length != 0) {
        url = url + `&userId=${userId}`;
      }
      if (query != null && query.length != 0) {
        url = url + `&status=${query}`;
      }
      if (sort != null && sort.length != 0) {
        url = url + `&sort=${sort.join(',')}`;
      }
      if (search != null && search.length != 0) {
        url = url + `&search=${search}`;
      }
      if (supplierId != null && supplierId.length != 0) {
        url = url + `&supplierId=${supplierId}`;
      }
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        const memberships = response.data.data.memberships.map(
          ({ PlanModel: plan, PetModel: pet, ...rest }) => ({
            plan,
            pet,
            ...rest
          })
        );
        response.data.data.memberships = memberships;
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updateMembership(membershipId, membership) {
    try {
      if (membership.contractFile) {
        membership.contractBase64 = await FileUtils.getBase64(membership.contractFile);
      }
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const data = membership;
      const response = await axios.put(`${adminUrl}/memberships/${membershipId}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async activateMembership(
    membership,
    policyNumber,
    policyFile,
    contractFile,
    notificationEmail
  ) {
    try {
      let policyBase64;
      let contractBase64;
      if (policyFile) {
        policyBase64 = await FileUtils.getBase64(policyFile);
      }
      if (contractFile) {
        contractBase64 = await FileUtils.getBase64(contractFile);
      }
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const data = {
        policyNumber,
        policyBase64,
        contractBase64,
        notificationEmail
      };
      const response = await axios.put(`${adminUrl}/memberships/activate/${membership.id}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async deactivateMembership(membershipId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.put(
        `${adminUrl}/memberships/deactivate/${membershipId}`,
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getExportedCsv(page, limit, companyId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrl}/memberships/export?page=${page}&limit=${limit}`;
      // console.log('QUERY:', query.length);
      if (companyId != null && companyId.length != 0) {
        url = url + `&companyId=${companyId}`;
      }
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
        responseType: 'blob'
      });
      console.log(response);

      if (response.status == 200) {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getSupplierExcel(supplierId, date) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(
        `${adminUrl}/memberships/suppliers/${supplierId}${date != null ? `?date=${date}` : ''}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: 'blob'
        }
      );
      if (response.status == 200) {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }
  static async getWeeklyExcel(supplierId, since, today) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(
        `${adminUrl}/memberships/suppliers/weekly/${supplierId}?since=${since}&today=${today}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: 'blob'
        }
      );
      if (response.status == 200) {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }
  // Services redemptions
  static async createServiceRedemption(serviceRedemptionData) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.post(`${adminUrl}/services/redeem`, serviceRedemptionData, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.status == 200) {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updateServiceRedemption(id, serviceRedemptionData) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.put(`${adminUrl}/services/redeem/${id}`, serviceRedemptionData, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.status == 200) {
        return { status: 'ok', data: response };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }
}
