import axios from 'axios';

// const url = "https://apis.digital.gob.cl/dpa";
// const url = "https://chilean-regions-app-yqxys.ondigitalocean.app/api/v1/chile"
const url = process.env.VUE_APP_BACKEND_URL;

export default class AddressService {
  static async getAllRegions() {
    try {
      const response = await axios.get(`${url}/chilean-regions/regions`);
      if (response.status === 200) {
        return { status: 'ok', data: response.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getAllCommunes() {
    try {
      const response = await axios.get(`${url}/chilean-regions/communes`);
      if (response.status === 200) {
        return { status: 'ok', data: response.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getCommunesByRegion(region) {
    try {
      const response = await axios.get(`${url}/chilean-regions/regions/${region}/communes`);
      if (response.status === 200) {
        return { status: 'ok', data: response.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

}
