<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Pagos recibidos</h1>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="payments"
      :options.sync="options"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:top>
        <v-col cols="12" md="6">
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Rango de fecha"
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" :active-picker.sync="activePicker" range></v-date-picker>
          </v-menu>
        </v-col>
      </template>

      <template v-slot:item.user="{ item }">
        <div class="d-flex align-center" v-if="item.user != null">
          <v-avatar size="30" class="mr-2" color="primary">
            <span v-if="item.user.imageUrl == null" class="white--text text-subtitle1"
              >{{ item.user.name[0] || '' }}{{ item.user.surname[0] || '' }}</span
            >

            <img v-if="item.user.imageUrl" :src="item.user.imageUrl" />
          </v-avatar>
          <div>
            <p class="ma-0 pa-0">
              <span>{{ item.user.name || '' }} {{ item.user.surname || '' }}</span>
            </p>
            <p class="ma-0 pa-0" v-if="item.user.rut">
              <span>{{ item.user.rut | rut }}</span>
            </p>
          </div>
        </div>
      </template>

      <template v-slot:item.membership="{ item }">
        <div class="d-flex flex-column py-2" v-if="item.membership != null">
          <div class="d-flex align-center" v-if="item.membership.pet != null">
            <v-avatar size="20" class="mr-1" color="primary">
              <v-icon dark x-small v-if="!item.membership.pet.imageUrl">
                mdi-paw
              </v-icon>

              <img v-if="item.membership.pet.imageUrl" :src="item.membership.pet.imageUrl" />
            </v-avatar>

            <span class="ma-0 pa-0">
              {{ item.membership.pet.name || '' }}
            </span>
          </div>
          <div>
            <v-chip-group class="ma-0 pa-0">
              <v-chip
                v-if="item.membership.status === 0"
                x-small
                color="orange"
                class="white--text font-weight-bold ma-0"
                >Pendiente de pago</v-chip
              >
              <v-chip
                v-if="item.membership.status === 1"
                color="blue"
                x-small
                class="white--text font-weight-bold ma-0"
                >Pendiente de activación</v-chip
              >
              <v-chip
                v-if="item.membership.status === 2"
                color="green"
                x-small
                class="white--text font-weight-bold ma-0"
                >Activo</v-chip
              >
              <v-chip
                v-if="item.membership.status === 3"
                color="red"
                x-small
                class="white--text font-weight-bold ma-0"
                >Pago fallido</v-chip
              >
              <v-chip
                v-if="item.membership.status === 4"
                color="grey"
                outlined
                x-small
                class="font-weight-bold ma-0"
                >Desactivado</v-chip
              >
              <v-chip
                v-if="item.membership.status === 5"
                color="primary"
                outlined
                x-small
                class="white--text font-weight-bold ma-0"
                >Pendiente de enrolamiento</v-chip
              >
            </v-chip-group>
          </div>
          <div v-if="item.membership.plan">
            <span class="ma-0 pa-0">
              {{ item.membership.plan.name }}
            </span>
          </div>
        </div>
      </template>

      <template v-slot:item.date="{ item }">
        <span>{{ item.date | moment('DD/MM/YYYY HH:mm') }}</span>
      </template>

      <template v-slot:item.amount="{ item }">
        <span>{{ item.amount | clpCurrency }}</span>
      </template>

      <template v-slot:item.status="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="item.status == 2" color="green" v-bind="attrs" v-on="on">
              mdi-check-circle
            </v-icon>
          </template>
          <span>Aprobado</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="item.status != 2" color="red" v-bind="attrs" v-on="on">
              mdi-close-circle
            </v-icon>
          </template>
          <span>Rechazado</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import PaymentsService from '../services/payments.service';

export default {
  data() {
    return {
      loadingData: true,
      options: {},
      users: [],
      dates: [],
      dateMenu: false,
      payments: [],
      selectedPayment: null,
      limit: 10,
      page: 1,
      totalItems: 0,
      headers: [
        {
          text: 'Usuario',
          value: 'user',
          sortable: false
        },
        {
          text: 'Membresía',
          value: 'membership',
          sortable: false
        },
        {
          text: 'Fecha',
          value: 'date',
          sortable: false
        },
        {
          text: 'Monto',
          value: 'amount',
          sortable: false
        },
        {
          text: 'Estado',
          value: 'status',
          sortable: false
        }
      ]
    };
  },
  mounted() {
    // this.getMemberships(this.page, this.limit);
    // this.dates is an array with two dates, start at fisrt day of actual months and end at today
    this.dates = [
      moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    ];
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.getPayments();
      },
      deep: true
    },
    dates: {
      handler() {
        this.getPayments();
      },
      deep: true
    }
  },
  computed: {
    dateRangeText() {
      return this.sortedDates.map(date => moment(date).format('DD/MM/YYYY')).join(' ~ ');
    },
    sortedDates() {
      return this.dates.slice().sort((a, b) => {
        return moment(a).isBefore(b) ? -1 : 1;
      });
    }
  },
  methods: {
    openDialog(user) {
      this.showDialog = true;
      this.selectedUser = user;
    },
    closeDialog() {
      this.showDialog = false;
      this.selectedUser = null;
      this.getPayments();
    },
    closeActivateDialog() {
      this.showActivateDialog = false;
      this.activatedUser = null;
      this.getPayments();
    },
    async getPayments() {
      const res = await PaymentsService.getPayments(this.page, this.limit, {
        from: this.sortedDates[0],
        to: this.sortedDates[1]
      });
      if (res.status === 'ok') {
        this.payments = res.data.payments;
        this.totalItems = res.data.totalItems;
      }
      this.loadingData = false;
    },
    activateReward(payment) {
      this.showActivateDialog = true;
      this.selectedPayment = payment;
    }
  }
};
</script>
