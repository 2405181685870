var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0 my-4 align-center justify-space-between"},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Usuarios B2C")])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.options,"loading":_vm.loadingData,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"footer-props":{
      'items-per-page-options': [15, 30, 45, 60]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"px-5 py-5",staticStyle:{"justify-content":"space-between","align-items":"center"}},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"label":"Seleccione país","items":_vm.countries,"disabled":_vm.loadingData,"item-value":"id","item-text":"flagName","placeholder":"","dense":"","hide-details":"","outlined":"","append-icon":"mdi-magnify"},on:{"change":_vm.changeCountry},model:{value:(_vm.countrySelected),callback:function ($$v) {_vm.countrySelected=$$v},expression:"countrySelected"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}},[_c('v-container',{staticClass:"ma-0 pa-0 mt-3 mt-md-0 d-flex align-center justify-end"},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary","depressed":""},on:{"click":_vm.downloadCsv}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-file-download-outline ")]),_c('span',[_vm._v("Descargar CSV")])],1)],1),(_vm.errorCsv)?_c('v-col',{staticClass:"mr-3 ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end"},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v("Ocurrió un error")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" "+_vm._s(item.surname))])]}},{key:"item.pet",fn:function(ref){
    var item = ref.item;
return [(item.specie === 'cat')?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"green"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cat ")]),_vm._v(" "+_vm._s(item.pet)+" ")],1):_vm._e(),(item.specie === 'dog')?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"orange"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-dog ")]),_vm._v(_vm._s(item.pet))],1):_vm._e()]}},{key:"item.startDate",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.startDate)))])]}},{key:"item.plan",fn:function(ref){
    var item = ref.item;
return [_c('v-col',{staticClass:"ma-0 pa-0 mt-1"},[[_c('span',[_vm._v(" "+_vm._s(item.plan.split(' ')[1])+" ")])],_c('v-chip-group',[_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"outlined":"","color":"primary","x-small":""}},[_vm._v(_vm._s(item.paymentInterval))])],1)],2)]}},{key:"item.landing",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.landing))])]}},{key:"item.codeName",fn:function(ref){
    var item = ref.item;
return [_c('v-col',{staticClass:"ma-0 pa-0 mt-1"},[(item.codeName != '')?_c('div',[_c('span',[_vm._v(" "+_vm._s(item.codeName)+" ")]),_c('v-chip-group',[_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"outlined":"","color":"purple","x-small":""}},[_vm._v(_vm._s(item.discountValue)+" OFF")])],1)],1):_c('div',[_c('span',[_vm._v("-")])])])]}},{key:"item.newTotal",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.newTotal))])]}}])}),_c('overlay',{attrs:{"value":_vm.loadingOverlay}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }