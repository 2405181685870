var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0 my-4 align-center justify-space-between"},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Mascotas")]),_c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"900","min-height":"600","max-height":"900"},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},[[_c('pet-maintainer',{attrs:{"pet":_vm.selectedPet},on:{"update-list":function($event){return this.getPets(this.page, this.limit, this.search)},"close-dialog":_vm.closeEditDialog}})]],2),_c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"900","min-height":"600","max-height":"900"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.showRecordsDialog = false},"click:outside":function($event){_vm.showEditDialog = false}},model:{value:(_vm.showRecordsDialog),callback:function ($$v) {_vm.showRecordsDialog=$$v},expression:"showRecordsDialog"}},[[_c('records-list',{attrs:{"pet":_vm.selectedPet},on:{"close-dialog":_vm.closeRecordsDialog}})]],2)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pets,"loading":_vm.loadingData,"options":_vm.options,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"ma-0",staticStyle:{"justify-content":"space-between","align-items":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","placeholder":"Nombre, ID...","dense":"","hide-details":"","outlined":"","append-icon":"mdi-magnify"},on:{"click:append":_vm.searchString,"keyup":[_vm.cleanSearch,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchString.apply(null, arguments)}]},model:{value:(_vm.stringToSearch),callback:function ($$v) {_vm.stringToSearch=$$v},expression:"stringToSearch"}})],1),(_vm.uniqueUser)?_c('v-btn',{staticClass:"ma-4",attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.seeAllPets()}}},[_vm._v("Ver todas")]):_vm._e()],1)]},proxy:true},{key:"item.species",fn:function(ref){
    var item = ref.item;
return [(item.species === 'cat')?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"green"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cat ")]),_vm._v(" Gato")],1):_vm._e(),(item.species === 'dog')?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"orange"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-dog ")]),_vm._v(" Perro")],1):_vm._e()]}},{key:"item.sex",fn:function(ref){
    var item = ref.item;
return [(item.sex === 'female')?_c('v-chip',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-gender-female ")]),_vm._v(" Hembra")],1):_vm._e(),(item.sex === 'male')?_c('v-chip',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-gender-male ")]),_vm._v(" Macho")],1):_vm._e()]}},{key:"item.age",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAge(item.birthdate))+" ")]}},{key:"item.breed.name",fn:function(ref){
    var item = ref.item;
return [(item.breed === null)?_c('span',[_vm._v("Mestizo")]):_c('span',[_vm._v(_vm._s(item.breed.name))])]}},{key:"item.owner",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.user.name) + " " + (item.user.surname)))+" ("+_vm._s(_vm._f("rut")(item.user.rut))+")")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","depressed":"","x-small":"","color":"blue"},on:{"click":function($event){return _vm.openEditDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Revisar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","depressed":"","x-small":"","color":"orange"},on:{"click":function($event){return _vm.openRecordsDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-format-list-text")])],1)]}}],null,true)},[_c('span',[_vm._v("Registros")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }