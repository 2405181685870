<template>
  <v-card class="pa-6">
    <overlay :value="loading"></overlay>
    <v-card-title class="text-uppercase">
      <v-row class="my-1 justify-space-between align-center">
        <div>
          Membresía
          {{
            membership && membership.user && membership.pet
              ? `de ${membership.user.name} y ${membership.pet.name}`
              : ''
          }}
        </div>
      </v-row>
    </v-card-title>
    <v-card-text class="my-0 py-0" v-if="membership && membership.user && membership.pet">
      <v-col class="ma-0 pa-0">
        <div style="font-weight: bold; font-size: 1.05rem">Ref. {{ membership.ref }}</div>
        <div style="font-weight: bold; text-transform: uppercase">
          {{ membership.plan.name }}
        </div>
        <div>
          Cliente: {{ membership.user.name }} {{ membership.user.surname }}, RUT
          {{ membership.user.rut | rut }}
        </div>
        <div>
          Mascota: {{ membership.pet.name }}, {{ membership.pet.sex === 'female' ? 'hembra' : '' }}
          {{ membership.pet.sex === 'male' ? 'macho' : '' }}
        </div>
      </v-col>
    </v-card-text>
    <hr class="my-3" />
    <v-card-text class="my-0 py-0" v-if="membership">
      <v-form ref="membershipDataForm" v-model="formValid">
        <v-container class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <v-row class="my-1">
              <v-col cols="5">
                <v-select
                  label="Proveedor"
                  v-model="membershipData.supplierPlan"
                  :loading="loadingSuppliersPlans"
                  :items="suppliersPlans"
                  :disabled="loadingSuppliersPlans"
                  item-text="name"
                  required
                  outlined
                  dense
                  return-object
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="7">
                <v-file-input
                  v-model="membershipData.contract"
                  color="primary"
                  accept="application/pdf"
                  counter
                  label="Contrato Pawer"
                  :messages="contractMessages"
                  placeholder="Selecciona un archivo"
                  prepend-icon=""
                  append-outer-icon="mdi-paperclip"
                  outlined
                  :rules="contractRules"
                  :show-size="1000"
                  dense
                  hide-details="auto"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="primary" dark label small>
                      {{ text }}
                    </v-chip>
                  </template>
                  <template v-slot:message="{ index, message }">
                    <v-chip color="primary" dark label small>
                      {{ message }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row class="my-1">
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="showHomologationDate"
                  :close-on-content-click="false"
                  :return-value.sync="membershipData.homologatedAt"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="homologatedAtText"
                      label="Fecha de homologación"
                      outlined
                      dense
                      readonly
                      hide-details="auto"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="membershipData.homologatedAt"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="2021-01-01"
                    @change="saveHomologationDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="my-1">
              <v-col cols="12">
                <v-checkbox
                  label="Su estado actual está homologado con su proveedor"
                  v-model="membershipData.homologated"
                  dense
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-container>
      </v-form>
      <div v-if="membership.status === 0">
        <v-alert dense text type="error">
          <strong>¡Advertencia!</strong> esta membresía se encuentra
          <strong>pendiente de pago</strong>.
        </v-alert>
      </div>
      <div v-if="membership.status === 2">
        <v-alert
          dense
          text
          type="info"
          v-if="membership.contractUrl != '' && membership.contractUrl != null"
        >
          Esta membresía ya cuenta con un contrato. Si se sube un nuevo documento se
          <strong>sobreescribirá</strong> el ya existente.
        </v-alert>
        <v-alert v-else dense text type="warning">
          <strong>¡Atención!</strong> Esta membresía <strong>no cuenta</strong> con un contrato.
        </v-alert>
      </div>
    </v-card-text>
    <hr class="my-3" />
    <v-card-actions class="justify-end">
      <div>
        <v-btn :disabled="!formValid || loading" color="primary" rounded @click="validate">
          <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
          <span v-else>Aceptar</span>
        </v-btn>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import MembershipsService from '../services/memberships.service';
import PlansService from '../services/plans.service';
import Overlay from './Overlay';
import moment from 'moment';

export default {
  name: 'MembershipMaintainer',
  components: { Overlay },
  props: ['membership'],
  data() {
    return {
      loading: false,
      membershipData: {},
      formValid: false,
      // contractRules: [(v) => !!v || 'El contrato es requerido'],
      contractRules: [],
      contractMessages: [],
      // numberRules: [(v) => !!v || 'El número de póliza es requerido']
      numberRules: [],
      policyMessages: [],
      suppliersPlans: [],
      loadingSuppliersPlans: false,
      activePicker: null,
      showHomologationDate: false,
      initialHomologated: null
    };
  },
  mounted() {
    this.membershipData = {};
    // this.$refs['membershipDataForm'].resetValidation();
    this.parse(this.membership);
  },
  computed: {
    homologatedAtText: {
      get() {
        return this.membershipData.homologatedAt
          ? moment(this.membershipData.homologatedAt).format('DD/MM/YYYY')
          : null;
      },
      set(newValue) {
        this.membershipData.homologatedAt = newValue;
      }
    },
    homologated() {
      return this.membershipData.homologated;
    }
  },
  watch: {
    membership(val) {
      this.membershipData = {};
      this.policyMessages = [];
      this.contractMessages = [];
      this.parse(val);
    },
    showHomologationDate(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    }
  },
  methods: {
    closeDialog() {
      this.policyMessages = [];
      this.contractMessages = [];
      this.$emit('close-dialog');
    },
    parse(membership) {
      if (membership) {
        this.loadSuppliersPlans();
        this.membershipData = { ...membership };
        this.initialHomologated = this.membershipData.homologated;
        this.membershipData.homologatedAt = this.membershipData.homologatedAt
          ? moment.utc(this.membershipData.homologatedAt).format('YYYY-MM-DD')
          : null;
        if (membership.contractUrl != null && membership.contractUrl != '') {
          this.contractMessages = ['Ya cuenta con un contrato Pawer'];
        }
      } else {
        this.membershipData = null;
      }
    },
    validate() {
      if (this.$refs.membershipDataForm.validate()) {
        this.updateMembership();
      }
    },
    async updateMembership() {
      this.loading = true;
      this.membershipData.contractFile = this.membershipData.contract;
      this.membershipData.supplierPlanId = this.membershipData.supplierPlan.id;
      const res = await MembershipsService.updateMembership(
        this.membership.id,
        this.membershipData
      );
      if (res.status == 'ok') {
        this.closeDialog();
        this.membershipData = {};
        this.$refs.membershipDataForm.resetValidation();
      }
      this.loading = false;
    },
    async loadSuppliersPlans() {
      this.loadingSuppliersPlans = true;
      const res = await PlansService.getSuppliersPlans(1, 100);
      this.suppliersPlans = res.data.suppliersPlans;
      this.loadingSuppliersPlans = false;
    },
    saveHomologationDate(date) {
      this.$refs.menu.save(date);
    }
  }
};
</script>