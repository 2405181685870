<template>
  <v-card class="pa-6">
    <overlay :value="loading"></overlay>
    <v-card-title class="text-uppercase">
      <v-row class="my-1 justify-space-between align-center">
        <div>
          Desactivar la membresía
          {{
            membership && membership.user && membership.pet
              ? `de ${membership.user.name} y ${membership.pet.name}`
              : ''
          }}
        </div>
      </v-row>
    </v-card-title>
    <v-card-text class="my-0 py-0" v-if="membership && membership.user && membership.pet">
      <v-col class="ma-0 pa-0">
        <div style="font-weight: bold; font-size: 1.05rem">Ref. {{ membership.ref }}</div>
        <div style="font-weight: bold; text-transform: uppercase">
          {{ membership.plan.name }}
        </div>
        <div>
          Cliente: {{ membership.user.name }} {{ membership.user.surname }}, RUT
          {{ membership.user.rut | rut }}
        </div>
        <div>
          Mascota: {{ membership.pet.name }}, {{ membership.pet.sex === 'female' ? 'hembra' : '' }}
          {{ membership.pet.sex === 'male' ? 'macho' : '' }}
        </div>
      </v-col>
    </v-card-text>
    <hr class="my-3" />
    <v-card-text class="my-0 py-0">
      <p>
        ¿Estás seguro de desactivar esta membresía? Se dejarán de emitir cobros al cliente. Esto es
        un proceso irreversible.
      </p>
    </v-card-text>
    <hr class="my-3" />
    <v-card-actions class="justify-end">
      <div>
        <v-btn color="primary" rounded @click="deactivateMembership">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <span v-else>Aceptar</span>
        </v-btn>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import MembershipsService from '../services/memberships.service';
import Overlay from './Overlay';

export default {
  name: 'DeactivateMembership',
  components: { Overlay },
  props: ['membership'],
  data() {
    return {
      loading: false
    };
  },
  mounted() {
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    async deactivateMembership() {
      this.loading = true;
      const res = await MembershipsService.deactivateMembership(this.membership.id);
      if (res.status == 'ok') {
        this.closeDialog();
      }
      this.loading = false;
    }
  }
};
</script>