<template>
  <v-card class="pa-6">
    <v-card-title class="text-uppercase">
      <v-row class="my-1 justify-space-between align-center">
        <div>{{ this.user === null ? 'Crear nuevo' : 'Detalle' }} usuario</div>
        <v-switch
          v-if="user !== null"
          v-model="editing"
          label="Editar"
          dense
          hide-details="auto"
        ></v-switch>
      </v-row>
    </v-card-title>
    <hr class="my-3" />
    <v-card-text class="my-0 py-0">
      <v-form ref="newUserForm" v-model="formValid">
        <v-container class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <v-row class="my-1">
              <v-col cols="6">
                <v-text-field
                  label="Nombres"
                  v-model="newUser.name"
                  :rules="nameRules"
                  :disabled="disabled()"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  label="Apellidos"
                  v-model="newUser.surname"
                  :rules="nameRules"
                  :disabled="disabled()"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="my-1">
              <v-col cols="6">
                <v-text-field
                  label="Correo electronico"
                  v-model="newUser.email"
                  :rules="emailRules"
                  :disabled="disabled()"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="my-1">
              <v-col cols="6">
                <v-select
                  label="Región"
                  :items="regions"
                  item-text="name"
                  v-model="newUser.region"
                  :disabled="disabled() || !regions.length"
                  :loading="!regions.length"
                  outlined
                  dense
                  hide-details="auto"
                  @change="getCommunesByRegion($event, null)"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                  label="Comuna"
                  :items="communes"
                  item-text="name"
                  v-model="newUser.commune"
                  :disabled="disabled() || !communes.length"
                  outlined
                  dense
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="my-1">
              <v-col cols="6">
                <v-text-field
                  label="Dirección"
                  v-model="newUser.street"
                  :disabled="disabled()"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Número"
                  v-model="newUser.number"
                  :disabled="disabled()"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Extra"
                  v-model="newUser.extra"
                  :disabled="disabled()"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="my-1">
              <v-col cols="4">
                <v-menu
                  ref="menu"
                  v-model="showBirthdayMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newUser.birthday"
                      label="Fecha de nacimiento"
                      prepend-icon="mdi-calendar"
                      :disabled="disabled()"
                      outlined
                      dense
                      readonly
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newUser.birthday"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1950-01-01"
                    @change="saveBirthday"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="RUT"
                  v-model.lazy="newRut"
                  :rules="rutRules"
                  :disabled="disabled()"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  @input="changeRut"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="Teléfono"
                  v-model="newUser.phone"
                  :disabled="disabled()"
                  outlined
                  dense
                  hide-details="auto"
                  @input="changePhone"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-container>
      </v-form>
    </v-card-text>
    <hr class="my-3" />
    <v-card-actions class="justify-end">
      <v-btn v-if="user === null || editing" color="primary" rounded @click="validate">
        Guardar
      </v-btn>
      <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UsersService from '../services/users.service';
import AddressService from '../services/address.service';
import RutUtils from '../utils/rut.utils';

export default {
  name: 'UserMaintainer',
  props: ['user'],
  data() {
    return {
      showBirthdayMenu: false,
      editing: false,
      activePicker: null,
      newRut: '',
      newUser: {},
      regions: [],
      communes: [],
      newPhone: '',
      formValid: false,
      nameRules: [v => !!v || 'El nombre es requerido'],
      emailRules: [
        v => !!v || 'Un correo electronico es requerido',
        v => /.+@.+/.test(v) || 'Debe ser un correo electrónico válido'
      ],
      rutRules: [v => RutUtils.validateRut(v) || 'Rut no válido']
    };
  },
  mounted() {
    this.getRegions();
    this.newUser = {};
    this.$refs.newUserForm.resetValidation();
    if (this.user) {
      if (this.user.address) {
        if (this.user.address.region) {
          this.getCommunesByRegion(
            typeof this.user.address.region === 'string'
              ? this.user.address.region
              : this.user.address.region.name
          );
        }
      }
      this.parseUser();
    }
  },
  watch: {
    showBirthdayMenu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
    user(val) {
      this.newUser = {};
      this.$refs.newUserForm.resetValidation();
      if (val) {
        if (val.address) {
          if (val.address.region) {
            this.getCommunesByRegion(
              typeof val.address.region === 'string' ? val.address.region : val.address.region.name
            );
          }
          this.parseUser();
        }
      }
    }
  },
  methods: {
    disabled() {
      if (this.user === null) {
        return false;
      } else {
        if (this.editing) {
          return false;
        }
        return true;
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
      this.editing = false;
      this.newRut = '';
    },
    parseUser() {
      this.newUser.id = this.user._id;
      this.newUser.name = this.user.name;
      this.newUser.surname = this.user.surname;
      this.newUser.email = this.user.email;
      if (this.user.address) {
        this.newUser.street = this.user.address.street ?? '';
        this.newUser.commune =
          this.user.address.commune === 'object'
            ? this.user.address.commune.name
            : this.user.address.commune ?? null;
        this.newUser.region =
          this.user.address.region === 'object'
            ? this.user.address.region.name
            : this.user.address.region ?? null;
        this.newUser.extra = this.user.address.extra ?? null;
        this.newUser.number = this.user.address.number ?? null;
      } else {
        this.newUser.street = null;
        this.newUser.commune = null;
        this.newUser.region = null;
        this.newUser.extra = null;
        this.newUser.number = null;
      }
      this.newUser.birthday = this.user.birthday
        ? this.user.birthday.split('T')[0]
        : this.user.birthday;
      this.newUser.phone = this.user.phone ?? null;
      this.changeRut(this.user.rut);
    },
    validate() {
      if (this.$refs.newUserForm.validate()) {
        if (this.user === null) {
          this.createUser();
        } else if (this.editing) {
          this.updateUser();
        }
      }
    },
    saveBirthday(date) {
      this.$refs.menu.save(date);
    },
    async createUser() {
      const regionSelected = this.regions.find(r => r.name === this.newUser.region);
      const communeSelected = this.communes.find(c => c.name === this.newUser.commune);
      const res = await UsersService.createUser(this.newUser, regionSelected, communeSelected);
      if (res.status === 'ok') {
        this.$refs.newUserForm.resetValidation();
        this.closeDialog();
      }
    },
    async updateUser() {
      const res = await UsersService.updateUser(this.newUser);
      if (res.status === 'ok') {
        this.$refs.newUserForm.resetValidation();
        this.closeDialog();
      }
    },
    async getRegions() {
      const res = await AddressService.getAllRegions();
      if (res.status === 'ok') {
        this.regions = res.data.map(r => {
          return {
            code: r.codigo,
            name: r.nombre
          };
        });
      }
    },
    async getCommunesByRegion(region) {
      //const regionSelected = this.regions.find(r => r.name === region);
      const res = await AddressService.getCommunesByRegion(
        typeof region === 'object' ? region.name : region
      );
      if (res.status === 'ok') {
        this.communes = res.data.map(d => {
          return {
            name: d.nombre,
            code: d.codigo
          };
        });
      }
    },
    changeRut(rut) {
      this.newUser.rut = RutUtils.clean(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
    changePhone(phone) {
      this.newUser.phone = phone;
      this.newPhone = phone;
    }
  }
};
</script>
