<template>
  <v-overlay :value="value" :z-index="999999">
    <v-col class="align-center justify-center text-center">
      <v-progress-circular
        :size="120"
        :width="8"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <h3 class="my-3">Cargando</h3>
    </v-col>
  </v-overlay>
</template>

<script>
export default {
  name: 'Overlay',
  props: ['value'],
  data: () => ({})
};
</script>
