<template>
    <v-card class="pa-6">
      <v-card-title class="text-uppercase">
        <v-row class="my-1 justify-space-between align-center">
          <div>{{ this.company === null ? "Crear nuevo" : "Detalle" }} Empresa</div>
          <v-switch
            v-if="company !== null"
            v-model="editing"
            label="Editar"
            dense
            hide-details="auto"
          ></v-switch>
        </v-row>
      </v-card-title>
      <hr class="my-3" />
      <v-card-text class="my-0 py-0">
        <v-form ref="newCompanyForm" v-model="formValid">
          <v-container class="ma-0 pa-0">
            <v-col class="ma-0 pa-0">
              <v-row class="my-1">
                <v-col cols="4">
                  <v-text-field
                    label="Nombre"
                    v-model="newCompany.name"
                    :rules="nameRules"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
  
                <v-col cols="4">
                  <v-text-field
                    label="Nombre empresa"
                    v-model="newCompany.businessName"
                    :rules="nameRules"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="RUT"
                    v-model.lazy="newRut"
                    :rules="rutRules"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    hide-details="auto"
                    @input="changeRut"
                  ></v-text-field>
                </v-col> 

              </v-row>
  
              <v-row class="my-1">
                <v-col cols="4">
                  <v-text-field
                    label="Correo electronico"
                    v-model="newCompany.email"
                    :rules="emailRules"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Teléfono"
                    v-model="newCompany.email"
                    :rules="emailRules"
                    :disabled="disabled()"
                    required
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>                                
                
              </v-row>             
  
              <v-row class="my-1">
                <v-col cols="3">
                    <v-text-field
                        label="Límite de membresía"
                        v-model="newCompany.usersLimit"
                        :rules="limitRules"
                        :disabled="disabled()"
                        required
                        outlined
                        dense
                        hide-details="auto"
                  ></v-text-field>
                </v-col>     
              </v-row>                
            </v-col>
          </v-container>
        </v-form>
      </v-card-text>
      <hr class="my-3" />
      <v-card-actions class="justify-end">
        <v-btn
          v-if="company === null || editing"
          :disabled="!formValid"
          color="primary"
          rounded
          @click="validate"
        >
          Guardar
        </v-btn>
        <v-btn text color="primary" rounded @click="closeDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
    
  import RutUtils from "../utils/rut.utils";
  
  export default {
    name: "CompanyMaintainer",
    props: ["company"],
    data() {
      return {        
        editing: false,
        activePicker: null,
        newRut: "",        
        newCompany: {},                        
        formValid: false,
        nameRules: [(v) => !!v || "El nombre es requerido"],
        emailRules: [
          (v) => !!v || "Un correo electronico es requerido",
          (v) => /.+@.+/.test(v) || "Debe ser un correo electronico valido",
        ],        
        rutRules: [(v) => RutUtils.validateRut(v) || "Rut no valido"],
        limitRules: [
            (v) => /.[0-9]/.test(v) || "Solo números"
        ]   
      };
    },
    mounted() {      
      this.newCompany = {};
      this.$refs.newCompanyForm.resetValidation();
      if (this.company !== null) {        
        this.parseCompany();
      }
    },
    watch: {      
      company(val) {
        this.newCompany = {};
        this.$refs.newCompanyForm.resetValidation();
        if (val !== null) {          
          this.parseCompany();
        }
      },
    },
    methods: {
      disabled() {
        if (this.company === null) {
          return false;
        } else {
          if (this.editing) {
            return false;
          }
          return true;
        }
      },
      closeDialog() {
        this.$emit("close-dialog");
        this.editing = false;
      },
      parseCompany() {
        /*this.newUser.id = this.user._id;
        this.newUser.name = this.user.name;
        this.newUser.surname = this.user.surname;
        this.newUser.email = this.user.email;
        this.newUser.role = this.user.role;
        this.newUser.street = this.user.address.street;
        this.newUser.commune = this.user.address.commune;
        this.newUser.region = this.user.address.region;
        this.newUser.extra = this.user.address.extra;
        this.newUser.number = this.user.address.number;
        this.newUser.birthday = this.user.birthday.split("T")[0];*/
        // this.changeSerial(this.user.serial);
        this.changeRut('');
      },
      validate() {
        if (this.$refs.newCompanyForm.validate()) {
          if (this.company === null) {
            this.createCompany();
          } else if (this.editing) {
            this.updateCompany();
          }
        }
      },      
      async createCompany() {
        
        
        const res = {};
        if (res.status === "ok") {
          this.$refs.newCompanyForm.resetValidation();
          this.closeDialog();
        }
      },
      async updateCompany() {
        const res = {};
        if (res.status === "ok") {
          this.$refs.newCompanyForm.resetValidation();
          this.closeDialog();
        }
      },          
      changeRut(rut) {
        console.log(rut);
        //this.newUser.rut = RutUtils.clean(rut);
        //this.newRut = RutUtils.formatRut(rut);
      }      
    },
  };
  </script>