var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0 my-4 align-center justify-space-between"},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Pagos recibidos")])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.payments,"options":_vm.options,"loading":_vm.loadingData,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Rango de fecha","outlined":"","dense":"","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"range":""},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1)]},proxy:true},{key:"item.user",fn:function(ref){
    var item = ref.item;
return [(item.user != null)?_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"30","color":"primary"}},[(item.user.imageUrl == null)?_c('span',{staticClass:"white--text text-subtitle1"},[_vm._v(_vm._s(item.user.name[0] || '')+_vm._s(item.user.surname[0] || ''))]):_vm._e(),(item.user.imageUrl)?_c('img',{attrs:{"src":item.user.imageUrl}}):_vm._e()]),_c('div',[_c('p',{staticClass:"ma-0 pa-0"},[_c('span',[_vm._v(_vm._s(item.user.name || '')+" "+_vm._s(item.user.surname || ''))])]),(item.user.rut)?_c('p',{staticClass:"ma-0 pa-0"},[_c('span',[_vm._v(_vm._s(_vm._f("rut")(item.user.rut)))])]):_vm._e()])],1):_vm._e()]}},{key:"item.membership",fn:function(ref){
    var item = ref.item;
return [(item.membership != null)?_c('div',{staticClass:"d-flex flex-column py-2"},[(item.membership.pet != null)?_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"20","color":"primary"}},[(!item.membership.pet.imageUrl)?_c('v-icon',{attrs:{"dark":"","x-small":""}},[_vm._v(" mdi-paw ")]):_vm._e(),(item.membership.pet.imageUrl)?_c('img',{attrs:{"src":item.membership.pet.imageUrl}}):_vm._e()],1),_c('span',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(item.membership.pet.name || '')+" ")])],1):_vm._e(),_c('div',[_c('v-chip-group',{staticClass:"ma-0 pa-0"},[(item.membership.status === 0)?_c('v-chip',{staticClass:"white--text font-weight-bold ma-0",attrs:{"x-small":"","color":"orange"}},[_vm._v("Pendiente de pago")]):_vm._e(),(item.membership.status === 1)?_c('v-chip',{staticClass:"white--text font-weight-bold ma-0",attrs:{"color":"blue","x-small":""}},[_vm._v("Pendiente de activación")]):_vm._e(),(item.membership.status === 2)?_c('v-chip',{staticClass:"white--text font-weight-bold ma-0",attrs:{"color":"green","x-small":""}},[_vm._v("Activo")]):_vm._e(),(item.membership.status === 3)?_c('v-chip',{staticClass:"white--text font-weight-bold ma-0",attrs:{"color":"red","x-small":""}},[_vm._v("Pago fallido")]):_vm._e(),(item.membership.status === 4)?_c('v-chip',{staticClass:"font-weight-bold ma-0",attrs:{"color":"grey","outlined":"","x-small":""}},[_vm._v("Desactivado")]):_vm._e(),(item.membership.status === 5)?_c('v-chip',{staticClass:"white--text font-weight-bold ma-0",attrs:{"color":"primary","outlined":"","x-small":""}},[_vm._v("Pendiente de enrolamiento")]):_vm._e()],1)],1),(item.membership.plan)?_c('div',[_c('span',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(item.membership.plan.name)+" ")])]):_vm._e()]):_vm._e()]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.date,'DD/MM/YYYY HH:mm')))])]}},{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("clpCurrency")(item.amount)))])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(item.status == 2)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Aprobado")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(item.status != 2)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Rechazado")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }