<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Usuarios B2C</h1>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="users"
      :options.sync="options"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [15, 30, 45, 60]
      }"
    >
      <template v-slot:top>
        <v-row class="px-5 py-5" style="justify-content: space-between; align-items: center">
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-select
              v-model="countrySelected"
              label="Seleccione país"
              :items="countries"
              :disabled="loadingData"
              @change="changeCountry"
              item-value="id"
              item-text="flagName"
              placeholder=""
              dense
              hide-details
              outlined
              append-icon="mdi-magnify"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-container class="ma-0 pa-0 mt-3 mt-md-0 d-flex align-center justify-end">
              <v-btn class="mb-3" color="primary" depressed @click="downloadCsv">
                <v-icon dark> mdi-file-download-outline </v-icon>
                <span>Descargar CSV</span>
              </v-btn>
            </v-container>
            <v-col v-if="errorCsv" class="mr-3 ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end">
              <v-alert dense outlined type="error">Ocurrió un error</v-alert>
            </v-col>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.name="{ item }">
        <span>{{ item.name }} {{ item.surname }}</span>
      </template>
      <template v-slot:item.pet="{ item }">
        <v-chip
          v-if="item.specie === 'cat'"
          small
          color="green"
          class="white--text font-weight-bold"
          ><v-icon left> mdi-cat </v-icon> {{ item.pet }}
        </v-chip>
        <v-chip
          v-if="item.specie === 'dog'"
          small
          color="orange"
          class="white--text font-weight-bold"
          ><v-icon left> mdi-dog </v-icon>{{ item.pet }}</v-chip
        >
      </template>
      <template v-slot:item.startDate="{ item }">
        <span>{{ formatDate(item.startDate) }}</span>
      </template>
      <template v-slot:item.plan="{ item }">
        <v-col class="ma-0 pa-0 mt-1">
          <template>
            <span>
              {{ item.plan.split(' ')[1] }}
            </span>
          </template>
          <v-chip-group>
            <v-chip outlined color="primary" x-small class="white--text font-weight-bold">{{
              item.paymentInterval
            }}</v-chip>
          </v-chip-group>
        </v-col>
      </template>
      <template v-slot:item.landing="{ item }">
        <span>{{ item.landing }}</span>
      </template>
      <template v-slot:item.codeName="{ item }">
        <v-col class="ma-0 pa-0 mt-1">
          <div v-if="item.codeName != ''">
            <span>
              {{ item.codeName }}
            </span>
            <v-chip-group>
              <v-chip outlined color="purple" x-small class="white--text font-weight-bold"
                >{{ item.discountValue }} OFF</v-chip
              >
            </v-chip-group>
          </div>
          <div v-else>
            <span>-</span>
          </div>
        </v-col>
      </template>
      <template v-slot:item.newTotal="{ item }">
        <span>{{ item.newTotal }}</span>
      </template>
    </v-data-table>
    <overlay :value="loadingOverlay"></overlay>
  </div>
</template>

<script>
import FileService from '../services/file.service';
import MRRService from '../services/mrr.service';
import Overlay from '../components/Overlay.vue';
import CountryService from '../services/countries.service';

export default {
  components: {
    Overlay
  },
  data() {
    return {
      errorCsv: false,
      loadingOverlay: false,
      stringToSearch: null,
      loadingData: false,
      loadingCountries: true,
      users: [],
      limit: 15,
      page: 1,
      options: {},
      iso: 'CL',
      totalItems: 0,
      headers: [
        {
          text: 'Nombre',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Mascota',
          sortable: false,
          value: 'pet'
        },
        {
          text: 'Fecha de Inicio',
          sortable: false,
          value: 'startDate'
        },
        {
          text: 'Plan',
          sortable: false,
          value: 'plan'
        },
        {
          text: 'Landing',
          value: 'landing',
          sortable: false
        },
        {
          text: 'Código',
          value: 'codeName',
          sortable: false
        },
        {
          text: 'A pagar',
          value: 'newTotal',
          sortable: false
        }
      ],
      countries: [{ id: null, name: '', iso: '', currency: '', flagUrlImage: '', flagName: '' }],
      countrySelected: { id: null, name: '', iso: '', currency: '', flagUrlImage: '', flagName: '' }
    };
  },
  mounted() {
    this.getCountries();
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.getMrrData(this.iso, this.limit, this.page);
      },
      deep: true
    }
  },
  methods: {
    async changeCountry(value) {
      console.log(value);
      this.countrySelected = this.countries.filter(c => c.id == value)[0];
      this.page = 1;
      this.limit = 15;
      this.users = [];
      this.totalItems = 0;
      this.iso = this.countrySelected.iso;
      this.getMrrData(this.countrySelected['iso'], 15, 1);
    },
    async getMrrData(iso, limit, page) {
      if (this.countrySelected['id'] != null) {
        this.loadingData = true;
        this.users = [];
        const res = await MRRService.getMRRDataService(iso, limit, page);
        if (res.status === 'ok') {
          this.users = res.data.data;
          this.totalItems = res.data.total_memberships;
        }
        this.loadingData = false;
      }
    },
    formatDate(date) {
      const d = new Date(date);
      const day = `${d.getDate()}`.padStart(2, '0');
      const month = `${d.getMonth() + 1}`.padStart(2, '0');
      return `${day}-${month}-${d.getFullYear()}`;
    },
    formatPrice(value) {
      return value;
    },
    async downloadCsv() {
      try {
        if (this.users.length > 0) {
          this.loadingOverlay = true;
          const res = await FileService.downloadCSVMRRB2C(this.iso);
          if (res.status === 'ok') {
            const blob = res.data.data;
            console.log(res.data);
            const fileName = `mrr_b2c_${this.iso}_${Date.now().toString()}.xlsx`;
            const url = URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.download = fileName;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            a = null;
            URL.revokeObjectURL(url);
          } else {
            throw Error();
          }
          this.loadingOverlay = false;
          this.errorCsv = false;
        }
      } catch (err) {
        console.error(err);
        this.errorCsv = true;
        this.loadingOverlay = false;
      }
    },
    async getCountries() {
      this.loadingCountries = true;
      const res = await CountryService.getAllCountries();
      if (res.status === 'ok') {
        this.countries = [];
        res.data.map(c => {
          c.flagName = `${c.name}-${c.flagUrlImage}`;
          this.countries.push(c);
        });
        if (this.countries.length > 0) {
          this.iso = this.countries[0].iso;
          this.countrySelected = this.countries[0];
          await this.getMrrData(this.countries[0].iso, this.limit, this.page);
        }
      }
      this.loadingCountries = false;
    }
  }
};
</script>
