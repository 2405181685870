import axios from 'axios';

const adminUrl = process.env.VUE_APP_ADMIN_URL;
const backendUrl = process.env.VUE_APP_BACKEND_URL;

export default class PetsService {
  static async getPets(page, limit, userId, search) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${adminUrl}/pets?page=${page}&limit=${limit}`;
      if (userId != null && userId.length != 0) {
        url = url + `&userId=${userId}`;
      }
      if (search != null && search.length != 0) {
        url = url + `&search=${search}`;
      }
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getPetsByUser(userId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${adminUrl}/pets/user/${userId}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updatePet(id, data) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.put(`${adminUrl}/pets/${id}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updateMany(pets) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      for (let i = 0; i < pets.length; i++) {
        let pet = pets[i];
        await axios.put(`${adminUrl}/pets/${pet.id}`, pet, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
      }
      return { status: 'ok' };
    } catch (error) {
      return { status: 'error' };
    }
  }

  // Breeds
  static async getBreeds(species) {
    try {
      const response = await axios.get(`${backendUrl}/breeds?species=${species}&limit=300`);
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data.breeds };
      } else {
        return { status: 'error', data: [] };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  // Records
  static async getRecordCategories() {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${adminUrl}/records/categories`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error', data: [] };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getRecordsByPet(petId, page, limit) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.get(`${adminUrl}/records/${petId}?page=${page}&limit=${limit}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error', data: [] };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async createRecord(recordData) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.post(`${adminUrl}/records`, recordData, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error', data: null };
      }
    } catch (error) {
      return { status: 'error', message: error.response.data.message };
    }
  }

  static async updateRecord(recordId, recordData) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.put(`${adminUrl}/records/${recordId}`, recordData, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok', data: response.data.data };
      } else {
        return { status: 'error', data: null };
      }
    } catch (error) {
      return { status: 'error', message: error.response.data.message };
    }
  }

  static async deleteRecord(recordId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      const response = await axios.delete(`${adminUrl}/records/${recordId}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        return { status: 'ok' };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error', message: error.response.data.message };
    }
  }
}
