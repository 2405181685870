<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="800px"
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          outlined
          color="green"
          class="white--text font-weight-bold"
          v-bind="attrs"
          v-on="on"
          >Ver</v-btn
        >
      </template>
      <v-card>
        <div
          class="d-flex flex-row justify-space-between pa-4 bg-grey"
          color="primary"
          name="refund-mainteiner"
        >
          <v-btn icon dark @click="dialog = false"> </v-btn>
          <span class="text-h6 font-weight-bold">Solicitud de reembolso </span>
          <v-btn icon dark @click="dialog = false">
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-divider></v-divider>
        <!-- ! -->
        <v-list lines="two" subheader class="pa-4">
          <!-- Container -->
          <div class="d-flex flex-row justify-space-between flex-wrap">
            <div class="d-flex flex-column align-start">
              <!-- USUARIO -->
              <span class="font-weight-bold">Usuario</span>
              <div style="font-size: 14px" class="d-flex flex-column align-start">
                <span>{{ data.user.name || '' }} {{ data.user.surname || '' }}</span>
                <span>RUT: {{ data.user.rut || '' }}</span>
                <span>{{ data.user.email || '' }}</span>
                <span v-if="data.user.phone != null">Tel: +56{{ data.user.phone || '' }}</span>
              </div>
            </div>
            <!-- MASCOTA -->
            <div v-if="data.pet != null" class="d-flex flex-column align-start">
              <span class="font-weight-bold">Mascota</span>
              <div style="font-size: 14px" class="d-flex flex-column align-start">
                <span>{{ data.pet.name || '' }}</span>
                <span
                  >{{
                    data.pet.species == 'dog'
                      ? data.pet.sex == 'male'
                        ? 'Perro'
                        : 'Perra'
                      : data.pet.sex == 'male'
                      ? 'Gato'
                      : 'Gata'
                  }}
                  <v-icon small color="primary">{{
                    data.pet.species == 'dog' ? 'mdi-dog' : 'mdi-cat'
                  }}</v-icon>
                </span>
              </div>
            </div>
            <div v-else class="d-flex flex-column align-start">
              <span class="font-weight-bold">Mascota</span>
              <div style="font-size: 14px" class="d-flex flex-column align-start">
                <span class="red--text">Sin información</span>
              </div>
            </div>

            <!-- BANCO -->
            <div v-if="data.userBankDetails != null" class="d-flex flex-column align-start">
              <span class="font-weight-bold">Banco</span>
              <div style="font-size: 14px" class="d-flex flex-column align-start">
                <span>{{ data.userBankDetails.bank ? data.userBankDetails.bank.name : '' }}</span>
                <span>Tipo de cuenta: {{ data.userBankDetails.accountType || '-' }}</span>
                <span>Nro cuenta: {{ data.userBankDetails.accountNumber || '-' }}</span>
              </div>
            </div>

            <div v-else class="d-flex flex-column align-start">
              <span class="font-weight-bold">Banco</span>
              <div style="font-size: 14px" class="d-flex flex-column align-start">
                <span class="red--text">Sin información</span>
              </div>
            </div>
            <!-- SERVICIO -->
            <div v-if="data.service != null" class="d-flex flex-column align-start">
              <span class="font-weight-bold align-start">Servicio</span>
              <div style="font-size: 14px" class="d-flex flex-column align-start">
                <span>{{ data.service.title || '' }}</span>
                <v-img
                  v-if="data.service.iconUrl != null"
                  :src="data.service.iconUrl"
                  width="40"
                  contain
                  :aspect-ratio="1"
                  class="align-self-center"
                ></v-img>
                <span v-else></span>
              </div>
            </div>
            <div v-else class="d-flex flex-column align-start">
              <span class="font-weight-bold">Servicio</span>
              <div style="font-size: 14px" class="d-flex flex-column align-start">
                <span class="red--text">Sin información</span>
              </div>
            </div>
            <!-- ATTACHMENTS -->
            <div
              v-if="data.attachments != null && data.attachments.length > 0"
              class="d-flex flex-column align-center"
            >
              <span class="font-weight-bold align-start">Adjuntos</span>
              <div class="d-flex flex-wrap justify-center">
                <v-tooltip v-for="attachment in data.attachments" :key="attachment.id" top>
                  <template v-if="attachment.fileUrl != null" v-slot:activator="{ on, attrs }">
                    <div v-if="attachment.fileUrl != null">
                      <v-btn
                        class="mx-1"
                        depressed
                        x-small
                        fab
                        color="#81C784"
                        v-bind="attrs"
                        v-on="on"
                        :href="attachment.fileUrl"
                        target="_blank"
                      >
                        <v-icon small color="white">mdi-eye-outline</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-icon small color="red">mdi-file-document-alert-outline</v-icon>
                    </div>
                  </template>

                  <span>{{ attachment.attachmentType ? attachment.attachmentType.name : '' }}</span>
                </v-tooltip>
              </div>
            </div>

            <div v-else class="d-flex flex-column align-start">
              <span class="font-weight-bold">Adjuntos</span>
              <div style="font-size: 14px" class="d-flex flex-column align-start">
                <span class="red--text">Sin información</span>
              </div>
            </div>
          </div>
        </v-list>
        <!-- ! -->
        <v-divider></v-divider>

        <v-list lines="two" subheader class="pa-4">
          <div
            v-if="data.changelogs.length > 0 && data.changelogs[0].status != null"
            class="d-flex flex-row justify-center"
          >
            <!-- ACCIONES -->
            <div class="d-flex flex-column align-center">
              <span class="font-weight-bold">Cambiar a:</span>
              <div class="d-flex flex-row">
                <v-form @submit.prevent @submit="watchData" class="d-flex flex-column">
                  <v-radio-group v-model="selectedOption" row>
                    <v-radio
                      v-for="(status, index) in statuses"
                      :key="index"
                      :label="status.text"
                      :value="status"
                      :color="status.color"
                      dense
                    ></v-radio>
                  </v-radio-group>
                  <p
                    v-if="selectedOption.value != null"
                    style="font-size: 12px;"
                    class="text-center"
                  >
                    {{ selectedOption.adviceMsg }}
                  </p>
                  <v-text-field
                    v-if="selectedOption.value == 400"
                    v-model="refundAmount"
                    label="Valor de reembolso"
                    maxlength="10"
                    prefix="$"
                    @input="parseAmount"
                  ></v-text-field>
                  <v-text-field
                    v-model="inputObservations"
                    label="Observaciones"
                    maxlength="300"
                  ></v-text-field>
                  <v-btn
                    :color="selectedOption != null ? selectedOption.color : 'primary'"
                    class="mr-4 white--text"
                    type="submit"
                    :disabled="
                      selectedOption == null ||
                        selectedOption.value == data.changelogs[0].status.sequenceOrder
                    "
                  >
                    Actualizar estado
                  </v-btn>
                </v-form>
              </div>
            </div>
          </div>
          <div v-else>
            <span class="font-weight-bold">Solicitud de reembolso</span>
            <span>Sin estado (error)</span>
          </div>
        </v-list>
        <!-- ! -->
        <v-divider></v-divider>
        <v-list lines="two" subheader class="pa-4">
          <template
            v-if="data.changelogs.length > 0"
            class="d-flex flex-column justify-center align-center"
          >
            <div class="d-flex flex-column align-center">
              <p class="font-weight-bold text-center">Más reciente</p>
            </div>
            <v-timeline>
              <!-- * -->
              <v-timeline-item
                v-for="changelog in data.changelogs"
                :key="changelog.id"
                :color="changelog.status.highlightColor"
              >
                <div class="d-flex flex-column align-center">
                  <div class="d-flex flex-column align-center pr-2">
                    <div class="font-weight-medium">{{ changelog.status.name }}&nbsp;</div>

                    <span style="font-size: 12px"
                      >({{ new Date(changelog.createdAt).toLocaleDateString() }} -
                      {{ new Date(changelog.createdAt).getHours() }}:{{
                        new Date(changelog.createdAt).getMinutes()
                      }})</span
                    >
                    <!-- <span
                      >{{ new Date(changelog.createdAt).getHours() }}h
                      {{ new Date(changelog.createdAt).getMinutes() }}min
                    </span> -->
                  </div>

                  <div>
                    <div
                      v-if="changelog.observations != null"
                      class="d-flex flex-column align-center"
                    >
                      <span style="font-size: 14px"><u>Observaciones:</u></span>
                      <p style="font-size: 13px; text-align:center">
                        "{{ changelog.observations || 'Sin observaciones' }}"
                      </p>
                    </div>

                    <div v-else>
                      <p style="font-size: 12px">
                        Sin observaciones
                      </p>
                    </div>
                  </div>
                </div>
              </v-timeline-item>
            </v-timeline>
            <p class="font-weight-bold text-center">Más antigua</p>
          </template>
          <template v-else>
            <span>Sin changelogs</span>
          </template>
        </v-list>
        <!-- ! -->
      </v-card>
    </v-dialog>
    <v-snackbar v-model="updateChangelogError" :timeout="5000">
      {{ updateChangelogErrorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="updateChangelogError = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="updateChangelogOk" :timeout="5000">
      {{ updateChangelogOkMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="updateChangelogOk = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>
    <overlay :value="loadingOverlay"></overlay>
  </v-row>
</template>

<script>
import RefundServices from '../services/refunds.service';
import Overlay from '../components/Overlay.vue';

export default {
  components: {
    Overlay
  },
  data() {
    return {
      changelogFormActivated: false,
      inputObservations: '',
      refundAmount: '',
      selectedOption: {
        text: '',
        value: 0,
        color: '',
        icon: '',
        adviceMsg: ''
      },
      // *
      loadingOverlay: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      updateChangelogError: false,
      updateChangelogErrorMsg: '',
      updateChangelogOk: false,
      updateChangelogOkMsg: '',
      statuses: [
        {
          text: 'Registrado',
          value: 0,
          color: '#104B94',
          icon: 'mdi-clock-time-nine-outline',
          adviceMsg: 'Solicitud enviada por el usuario, aún no revisada por un admin'
        },
        {
          text: 'En revisión',
          value: 100,
          color: '#CC7A00',
          icon: 'mdi-clock-time-nine-outline',
          adviceMsg: 'Un administrador revisará la solicitud actual'
        },
        {
          text: 'Corregir',
          value: 200,
          color: '#5348CC',
          icon: 'mdi-account-edit',
          adviceMsg: 'El usuario deberá actualizar la información de su solicitud'
        },
        {
          text: 'Rechazar',
          value: 300,
          color: '#A91A23',
          icon: 'mdi-close-thick',
          adviceMsg: 'Se rechaza la solicitud de reembolso'
        },
        {
          text: 'Aprobar',
          value: 400,
          color: '#1CA762',
          icon: 'mdi-check-bold',
          adviceMsg: 'El reembolso ya fue realizado en la cuenta del usuario'
        }
      ]
    };
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    refreshParent: {
      type: Function,
      required: true
    }
  },
  methods: {
    async watchData() {
      console.log(this.selectedOption);
      console.log(this.inputObservations);
      console.log(this.data);
      let refund = this.data;
      let lastChangelogStatus = this.data.changelogs[0].status;
      this.loadingOverlay = true;
      if (lastChangelogStatus.sequenceOrder == this.selectedOption.value) {
        console.log('Se esta intentando actualizar al mismo estado');
        this.loadingOverlay = false;
        return;
      }
      try {
        const res = await RefundServices.updateChangelogStatus({
          refundId: refund.id,
          changelogStatus: this.selectedOption.value,
          observations: this.inputObservations,
          approvedAmount: parseInt(this.refundAmount.replaceAll('.', ''))
        });
        console.log('db changelog statuses: ', res);
        this.updateChangelogOk = true;
        this.updateChangelogOkMsg = `Actualizado de "${lastChangelogStatus.name}" a "${this.selectedOption.text}"`;
        this.loadingOverlay = false;
        this.dialog = false;
        this.inputObservations = '';
        this.refreshParent();
      } catch (error) {
        this.updateChangelogOk = true;
        this.updateChangelogOkMsg = `Ocurrio un error al intentar actualizar el estado`;
        this.loadingOverlay = false;
      }
    },
    parseAmount(v) {
      let f1 = v.replaceAll('.', '');
      let f = f1.split('').reverse();
      let value = [];
      let tmp = 1;
      let aux = 0;
      while (aux < f.length) {
        value.splice(0, 0, f[aux]);
        if (tmp % 3 == 0 && aux < f1.length - 1) {
          value.splice(0, 0, '.');
          tmp = 1;
        } else tmp++;
        aux++;
      }
      this.refundAmount = value.join('');
    }
  }
};
</script>
