<template>
  <div>
    <v-dialog
      persistent
      v-model="showActivateDialog"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <reward-activator
        @close-dialog="closeActivateDialog"
        :redemption="selectedRedemption"
      ></reward-activator>
    </v-dialog>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Donaciones</h1>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="donations"
      :options.sync="options"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50]
      }"
    >
      <template v-slot:top>
        <v-row class="px-5 py-5">
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Rango de fecha"
                  outlined
                  dense
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                :active-picker.sync="activePicker"
                range
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-container class="ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end">
              <v-btn color="primary" depressed id="donation-csv" @click="downloadCsv">
                <v-icon dark> mdi-google-spreadsheet </v-icon>
                <span>Descargar CSV</span>
              </v-btn>
            </v-container>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.user="{ item }">
        <div class="d-flex align-center" v-if="item.user != null">
          <v-avatar size="30" class="mr-2">
            <span v-if="item.user.imageUrl == null" class="white--text text-h5"
              >{{ item.user.name[0] || '' }}{{ item.user.surname[0] || '' }}</span
            >

            <img v-if="item.user.imageUrl" :src="item.user.imageUrl" />
          </v-avatar>
          <div>
            <p class="ma-0 pa-0">
              <span>{{ item.user.name || '' }} {{ item.user.surname || '' }}</span>
            </p>
            <p class="ma-0 pa-0" v-if="item.user.rut">
              <span>{{ item.user.rut | rut }}</span>
            </p>
          </div>
        </div>
      </template>

      <template v-slot:item.cause="{ item }">
        <div class="d-flex align-center" v-if="item.user != null">
          <v-avatar size="40" class="mr-2 pa-1" color="primary">
            <span v-if="item.user.imageUrl == null" class="white--text text-h5"
              >{{ item.user.name[0] || '' }}{{ item.user.surname[0] || '' }}</span
            >

            <v-img
              v-if="item.cause.organization.logoUrl"
              :src="item.cause.organization.logoUrl"
              contain
            />
          </v-avatar>
          <div>
            <p class="ma-0 pa-0">
              <span>{{ item.cause.name || '' }}</span>
            </p>
            <p class="ma-0 pa-0 font-weight-bold" v-if="item.cause.organization">
              <span>{{ item.cause.organization.name }}</span>
            </p>
          </div>
        </div>
      </template>

      <template v-slot:item.quantity="{ item }">
        <span class="font-weight-bold">{{ item.quantity }}</span>
        <span> x {{ item.cause.pawPrice }}</span>
      </template>

      <template v-slot:item.totalPaws="{ item }">
        <div class="d-flex align-center flex-row" v-if="item.cause != null">
          <v-chip color="primary" outlined class="white--text font-weight-bold ma-0 pa-2">
            <v-icon left small class="ma-0 pa-0 pr-1"> mdi-paw </v-icon>
            <span>{{ item.cause.pawPrice * item.quantity }}</span>
          </v-chip>
        </div>
      </template>

      <template v-slot:item.date="{ item }">
        <span>{{ item.createdAt | moment('DD/MM/YYYY HH:mm') }}</span>
      </template>
    </v-data-table>
    <overlay :value="loadingOverlay"></overlay>
  </div>
</template>

<script>
import moment from 'moment';
import Overlay from '../components/Overlay.vue';
import DonationsService from '../services/donations.service';

export default {
  components: { Overlay },
  data() {
    return {
      showDialog: false,
      showActivateDialog: false,
      loadingData: true,
      loadingOverlay: false,
      options: {},
      users: [],
      dates: [],
      dateMenu: false,
      donations: [],
      selectedRedemption: null,
      limit: 10,
      page: 1,
      totalItems: 0,
      headers: [
        {
          text: 'Causa',
          value: 'cause',
          sortable: false
        },
        {
          text: 'Usuario',
          value: 'user',
          sortable: false
        },
        {
          text: 'Cantidad',
          value: 'quantity',
          sortable: false
        },
        {
          text: 'Patitas totales',
          value: 'totalPaws',
          sortable: false
        },
        {
          text: 'Fecha',
          value: 'date',
          sortable: false
        }
      ]
    };
  },
  mounted() {
    // this.getMemberships(this.page, this.limit);
    // this.dates is an array with two dates, start at fisrt day of actual months and end at today
    this.dates = [
      moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    ];
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.getDonations();
      },
      deep: true
    },
    dates: {
      handler() {
        this.getDonations();
      },
      deep: true
    }
  },
  computed: {
    dateRangeText() {
      return this.sortedDates.map(date => moment(date).format('DD/MM/YYYY')).join(' ~ ');
    },
    sortedDates() {
      return this.dates.slice().sort((a, b) => {
        return moment(a).isBefore(b) ? -1 : 1;
      });
    }
  },
  methods: {
    openDialog(user) {
      this.showDialog = true;
      this.selectedUser = user;
    },
    closeDialog() {
      this.showDialog = false;
      this.selectedUser = null;
      this.getDonations();
    },
    closeActivateDialog() {
      this.showActivateDialog = false;
      this.activatedUser = null;
      this.getDonations();
    },
    async getDonations() {
      const res = await DonationsService.getDonations(this.page, this.limit, {
        from: this.sortedDates[0],
        to: this.sortedDates[1]
      });
      if (res.status === 'ok') {
        this.donations = res.data.donations;
        this.totalItems = res.data.totalItems;
      }
      this.loadingData = false;
    },
    async downloadCsv() {
      this.loadingOverlay = true;
      const res = await DonationsService.getExportedCsv({
        from: this.sortedDates[0],
        to: this.sortedDates[1]
      });
      if (res.status === 'ok') {
        const anchor = document.createElement('a');
        const blob = new Blob([res.data.csv], { type: 'csv/plain' });
        anchor.href = window.URL.createObjectURL(blob);
        anchor.target = '_blank';
        anchor.download = `donaciones_${moment(this.sortedDates[0]).format('DD-MM-YYYY')}_${moment(
          this.sortedDates[1]
        ).format('DD-MM-YYYY')}.csv`;
        anchor.click();
      }
      this.loadingOverlay = false;
    },
    activateReward(redemption) {
      this.showActivateDialog = true;
      this.selectedRedemption = redemption;
    }
  }
};
</script>
